import ctaDefaults, { CtaDefaults } from '../fragments/ctaDefaults'
import socialOrEmailLinkDefaults, { SocialOrEmailLink } from '../fragments/socialOrEmailLinkDefaults'

interface AboutDefaults {
  about: Array<{
    title: string,
    uri: string,
    textBlock: string,
    people: Array<{
      id: number,
      fullName: string,
      jobTitle: string,
      photo: {
        url: string,
      },
      social: Array<SocialOrEmailLink>,
    }>,
  }>,
  cta: Array<CtaDefaults>,
}

const aboutDefaults: AboutDefaults = {
  about: [{
    title: '',
    uri: '',
    textBlock: '',
    people: [{
      id: 0,
      fullName: '',
      jobTitle: '',
      photo: {
        url: '',
      },
      social: [socialOrEmailLinkDefaults],
    }],
  }],
  cta: [ctaDefaults],
}

export default aboutDefaults
