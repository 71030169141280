/* global module, process */
import React from 'react'
import { hydrate, render } from 'react-dom'
import AppWithRouter from 'fieldwork/components/AppWithRouter'

const theWindow = window as any
const renderer = ( theWindow.APP_SSR ) ? hydrate : render

renderer(
  <AppWithRouter />,
  document.getElementById( 'root' ),
)
