import React, { useEffect } from 'react'
import { ApolloError } from 'apollo-client'
import PageLoader from './PageLoader'
import NotFound from './NotFound'

type UseQuery = ( query: any, variables?: { [key: string]: any } ) => {
  loading: boolean,
  error?: ApolloError,
  data?: any,
}

interface QueryVariables {
  [key: string]: any,
}

interface ComponentProps {
  data: any,
  [key: string]: any,
}

const withQuery = (
  useQuery: UseQuery,
  queryVariables?: QueryVariables,
  FallbackComponent?: React.ElementType<{ [key: string]: any }>,
  isFound?: ( data: { [key: string]: any } ) => boolean
) => (
  Component: React.ElementType<ComponentProps>
) => (
  props: { [key: string]: any }
): React.ReactElement => {
  const { loading, error, data } = useQuery( queryVariables )
  if ( loading ) return <PageLoader />
  if ( error || !data ) return <p>Oh no!</p>

  if (
    isFound &&
    !isFound( data ) &&
    FallbackComponent
  ) {
    return <FallbackComponent { ...props } />
  }

  return <Component { ...props } data={ data } />
}

export default withQuery
