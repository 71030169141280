import React from 'react'
import styled, { css } from 'styled-components'

interface LayoutSection {
  centered?: boolean,
  fixedHeaderPush?: boolean,
  narrow?: boolean,
}

const LayoutSection = styled.div`
  box-sizing: content-box;
  padding-left: 5vw;
  padding-right: 5vw;

  ${ ( p: LayoutSection ) => p.centered && css`
    max-width: 1500px;
    margin: 0 auto;
  ` }

  ${ ( p: LayoutSection ) => p.fixedHeaderPush && css`
    padding-top: 120px;
  ` }

  ${ ( p: LayoutSection ) => p.narrow && css`
    max-width: 900px;
  ` }
`

export default LayoutSection
