import React from 'react'
import withQuery from 'fieldwork/components/withQuery'
import { useGetContactQuery } from 'fieldwork/graphql/generated/graphql'
import contactDefaults from 'fieldwork/graphql/queries/contactDefaults'
import { nl2br } from 'fieldwork/helpers/react-strings'
import { setSocialForContact } from 'fieldwork/helpers/setSocial'
import Head from './Head'
import LayoutSection from './LayoutSection'
import VerticalStack from './VerticalStack'
import SimpleGrid from './SimpleGrid'
import LargeCopy from './LargeCopy'
import Map from './Map'
import ContactLinks from './ContactLinks'
import ContactItems from './ContactItems'

export const Contact = ( { data = contactDefaults } ) => {
  const contact = data.contact[ 0 ]
  const fieldwork = data.fieldwork[ 0 ]

  const {
    socialTitle,
    socialDescription,
    socialImage,
    socialUrl,
  } = setSocialForContact( contact )

  return (
    <>
      <Head
        title={ socialTitle }
        description={ socialDescription }
        img={ socialImage }
        url={ socialUrl }
      />

      <VerticalStack fixedHeaderPush hero>
        <Map
          lat={ contact.mapLatitude }
          lng={ contact.mapLongitude }
          zoom={ contact.mapZoom }
          style="mapbox://styles/edfieldwork/cja3ttv9120942rnudk5pehbh"
        />
      </VerticalStack>
      <LayoutSection>
        <VerticalStack hero>
          <SimpleGrid
            minColumnWidth={ 300 }
            maxColumns={ 3 }
            gridGap={ 18 }
            verticalGap={ 40 }
          >
            <div>
              <VerticalStack>
                <LargeCopy>{ nl2br( fieldwork.address ) }</LargeCopy>
              </VerticalStack>
              <LargeCopy>
                <ContactLinks links={ contact.links } />
              </LargeCopy>
            </div>
            <ContactItems contactItems={ contact.contacts } />
          </SimpleGrid>
        </VerticalStack>
      </LayoutSection>
    </>
  )
}

export default withQuery( useGetContactQuery )( Contact )