import React, { useState } from 'react'
import styled, { css } from 'styled-components'
import { Link, withRouter } from 'react-router-dom'
import history from 'fieldwork/history'
import Icon from './Icon/Icon'

interface Nav {
  location: {
    pathname: string,
  },
}

const Nav = ( { location: { pathname } }: Nav ) => {
  const [isMobileOpen, setMobileOpen] = useState( false );

  const onBurgerClick = () => {
    setMobileOpen( !isMobileOpen )
  }

  history.listen( location =>  {
    setMobileOpen( false )
  } )

  return (
    <>
      <StyledNavUnderlay
        isOpen={ isMobileOpen }
        onClick={ () => setMobileOpen( false ) }
      />
      <Burger onClick={ onBurgerClick } isOpen={ isMobileOpen } />
      <StyledNavLinksWrapper isOpen={ isMobileOpen }>
        <NavLink to="/" active={ pathname === '/' }>Fieldwork</NavLink>
        <NavLink to="/about" active={ pathname.startsWith( '/about' ) }>About</NavLink>
        <NavLink to="/services" active={ pathname.startsWith( '/services' ) }>Services</NavLink>
        <NavLink to="/projects" active={ pathname.startsWith( '/projects' ) }>Work</NavLink>
        <NavLink
          to="https://medium.com/fieldwork-design-technology"
          active={ pathname === '/blog' }
        >Blog</NavLink>
        <NavLink to="/contact" active={ pathname === '/contact' }>Contact</NavLink>
      </StyledNavLinksWrapper>
    </>
  )
}

interface HasIsOpen {
  isOpen: boolean,
}

const StyledNavLinksWrapper = styled.div`
  @media all and ( max-width: 650px ) {
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    min-width: 10rem;
    padding: 7rem 10vw 3rem 5vw;
    transform: translateX( -100% );
    transition:
      transform .3s ease-out,
      background-color 2s ease-in;
    background-color: var( --highlightColor );

    ${ ( p: HasIsOpen ) => p.isOpen && css`
      transform: translateX( 0 );
    ` }
  }
`

const StyledNavUnderlay = styled.div`
  position: fixed;
  top: 0;
  bottom: 0;
  left: 100%;
  right: 0;

  ${ ( p: HasIsOpen ) => p.isOpen && css`
    left: 0;
  ` }
`

interface Buger {
  onClick: () => void,
  isOpen: boolean,
}

const Burger = ( { onClick, isOpen }: Buger ) => {
  return (
    <StyledBurger onClick={ onClick } isOpen={ isOpen }>
      <Icon iconName="burger" />
    </StyledBurger>
  )
}

const StyledBurger = styled.div`
  z-index: 2;
  position: fixed;
  width: 23px;
  margin-top: 18px;
  cursor: pointer;

  @media all and ( min-width: 651px ) {
    display: none;
  }

  svg rect {
    fill: var( --black );
    transition:
      transform .3s ease-in-out,
      fill .3s ease-out;
  }

  ${ ( p: HasIsOpen ) => p.isOpen && css`
    svg rect {
      fill: white;

      &:nth-child(2) {
        transform: translateX( -20% );
      }

      &:nth-child(3) {
        transform: translateX( -60% );
      }

      &:nth-child(4) {
        transform: translateX( -40% );
      }
    }
  ` }

  &:hover {
    svg rect {
      &:nth-child(2) {
        transform: translateX( -60% );
      }

      &:nth-child(3) {
        transform: translateX( -40% );
      }

      &:nth-child(4) {
        transform: translateX( -20% );
      }
    }
  }
`

interface NavLink {
  to: string,
  active: boolean,
  children: React.ReactNode,
}

const NavLink = ( { to, active, children }: NavLink ) => {
  if ( /^https?:\/\/|^\/\//i.test( to ) ) {
    return <StyledNavA href={ to } active={ active }>{ children }</StyledNavA>
  }

  return <StyledNavLink to={ to } active={ active }>{ children }</StyledNavLink>
}

const NavLinkStyles = css`
  display: inline-block;
  margin-bottom: 14px;
  font-size: 18px;
  font-weight: 600;
  text-decoration: none;
  color: var( --black );
  border-bottom: 1px solid transparent;
  transition:
    color .3s ease-out,
    border-color .5s ease-out;

  @media all and ( min-width: 651px ) {
    margin-right: 0.6em;
    padding: 18px 0 4px 0;

    &:hover {
      color: var( --defaultHighlight );
      color: var( --highlightColor );
    }
  }

  @media all and ( max-width: 650px ) {
    display: block;
    padding-bottom: 4px;
    color: white;
    border-color: transparent;
    transition: border-color .3s ease-out;

    &:hover {
      border-color: white;
    }
  }
`

const StyledNavLink = styled( ( { active, ...rest } ) => <Link { ...rest } /> )`
  ${ NavLinkStyles }

  ${ p => p.active && css`
    color: var( --highlightColor );
    border-color: var( --highlightColor );
  ` }
`

const StyledNavA = styled( ( { active, ...rest } ) => <a { ...rest } /> )`
  ${ NavLinkStyles }

  ${ p => p.active && css`
    color: var( --highlightColor );
    border-color: var( --highlightColor );
  ` }
`

export default withRouter( Nav )