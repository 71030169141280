import React from 'react'
import { Link } from 'react-router-dom'
import styled, { css } from 'styled-components'
import { typo } from 'fieldwork/styles/fonts'
import toWebclientUrl from 'fieldwork/helpers/toWebclientUrl'
import LayoutSection from './LayoutSection'
import VerticalStack from './VerticalStack'

interface CTABanner {
  text?: string | null,
  link?: string | null,
  cta?: string | null,
  borderTop?: boolean,
}

const CTABanner = ( { text, link, cta, borderTop = false }: CTABanner ) => {
  if ( !link || !cta ) {
    return null
  }

  return (
    <StyledCTABanner borderTop={ borderTop }>
      <VerticalStack hero top bottom>
        <LayoutSection>
          { text && <p>{ text }</p> }
          <Link to={ toWebclientUrl( link, true ) }>{ cta }</Link>
        </LayoutSection>
      </VerticalStack>
    </StyledCTABanner>
  )
}

interface StyledCTABanner {
  borderTop: boolean,
}

const StyledCTABanner = styled.div`
  text-align: center;
  color: var( --headingGrey );

  ${ ( p: StyledCTABanner ) => p.borderTop && css`
    border-top: 1px solid var( --iconGrey );
  ` }

  p {
    padding-bottom: 1em;
    ${ typo.large }
    font-weight: 300;
  }

  a {
    position: relative;
    overflow: hidden;
    display: inline-block;
    padding: 1rem 2rem;
    border: 1px solid var( --highlightColor );
    color: var( --highlightColor );
    text-decoration: none;
    ${ typo.mono }
    background: transparent;
    transition:
      color .3s ease-out,
      background-color .3s ease-out;

    &:hover {
      background: var( --highlightColor );
      color: white;
    }
  }
`

export default CTABanner