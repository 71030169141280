// @flow
import { css } from 'styled-components'

const colors = css`
  :root {
    --bgGreyRgb: 239, 236, 231;
    --footerBgRgb: 225, 222, 218;
    --blackRgb: 29, 29, 27;
    --headlineGreyRgb: 60, 60, 59;
    --lightTextGreyRgb: 112, 111, 111;
    --iconGreyRgb: 175, 175, 175;
    --purpleRgb: 97, 95, 155;

    --defaultHighlightRgb: var( --purpleRgb );

    --bgGrey: rgb( var( --bgGreyRgb ) );
    --footerBg: rgb( var( --footerBgRgb ) );
    --black: rgb( var( --blackRgb ) );
    --headlineGrey: rgb( var( --headlineGreyRgb ) );
    --lightTextGrey: rgb( var( --lightTextGreyRgb ) );
    --iconGrey: rgb( var( --iconGreyRgb ) );
    --purple: rgb( var( --purpleRgb ) );

    --defaultHighlight: rgb( var( --defaultHighlightRgb ) );

    --highlightColor: var( --defaultHighlight );
  }
`

export default colors
