import React from 'react'
import { Link } from 'react-router-dom'
import styled from 'styled-components'
import projectDefaults from 'fieldwork/graphql/fragments/projectDefaults'
import projectCardImages from 'fieldwork/imageSizes/projectCardImages'
import FadingImage from './FadingImage'

const ProjectCard = ( { projectPreview = projectDefaults } ) => {
  const img = (
    projectPreview.tileImage
    && projectPreview.tileImage.length
  )
    ? projectPreview.tileImage[0]
    : projectPreview.mainImage[0]

  return (
    <StyledProjectCard>
      <Link to={ `/${ projectPreview.uri }` }>
        <StyledProjectCardImage
          src={ projectCardImages.src( img ) }
          focalPoint={ img.focalPoint }
        >
          <FadingImage
            srcSet={ projectCardImages.srcSet( img ) }
            sizes="
              (max-width: 580px) 90vw,
              (max-width: 880px) 45vw,
              30vw,
            "
            src={ projectCardImages.src( img ) }
            alt={ img.altText || projectPreview.title }
          />
        </StyledProjectCardImage>
        <StyledProjectCardTitle>
          { projectPreview.title }
        </StyledProjectCardTitle>
        <StyledProjectCardRunner>
          { projectPreview.runner }
        </StyledProjectCardRunner>
      </Link>
    </StyledProjectCard>
  )
}

const StyledProjectCard = styled.div`
  a {
    display: block;
    text-decoration: none;
  }
`

interface StyledProjectCardImage {
  src: string,
  focalPoint: {
    x: number,
    y: number,
  },
}

const StyledProjectCardImage = styled.div`
  position: relative;
  overflow: hidden;
  padding-bottom: 60%;
  background-color: var( --highlightColor );

  :before {
    content: '';
    position: absolute;
    top: -0.5%;
    left: -0.5%;
    right: -0.5%;
    bottom: -0.5%;
    background-image: url( ${ p => p.src } );
    background-size: cover;
    background-position:
      ${ ( p: StyledProjectCardImage ) => p.focalPoint.x * 100 }%
      ${ ( p: StyledProjectCardImage ) => p.focalPoint.y * 100 }%;
    filter: grayscale( 100% );
    mix-blend-mode: multiply;
  }

  img {
    position: absolute;
    top: -0.5%;
    left: -0.5%;
    right: -0.5%;
    bottom: -0.5%;
    width: 101%;
    height: 101%;
    -webkit-backface-visibility: hidden;
    object-fit: cover;
    object-position:
      ${ ( p: StyledProjectCardImage ) => p.focalPoint.x * 100 }%
      ${ ( p: StyledProjectCardImage ) => p.focalPoint.y * 100 }%;
    transition: opacity .3s ease-out;

    ${ StyledProjectCard } a:hover & {
      opacity: 0;
    }
  }
`

const StyledProjectCardTitle = styled.h2`
  padding-top: 12px;
  font-size: 18px;
  font-weight: 600;
  color: var( --black );
  transition: color .3s ease-out;
`

const StyledProjectCardRunner = styled.p`
  padding-bottom: 12px;
  font-size: 18px;
  font-weight: 300;
  color: var( --black );
`

export default ProjectCard