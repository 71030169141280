import React from 'react'
import withQuery from 'fieldwork/components/withQuery'
import { useGetServicesQuery } from 'fieldwork/graphql/generated/graphql'
import servicesDefaults from 'fieldwork/graphql/queries/servicesDefaults'
import { setSocialForServicesPage } from 'fieldwork/helpers/setSocial'
import Head from './Head'
import LayoutSection from './LayoutSection'
import VerticalStack from './VerticalStack'
import ServiceCard from './ServiceCard'
import BoxedLink from './BoxedLink'
import LargeCopy from './LargeCopy'
import CTABanner from './CTABanner'

export const Services = ( { data = servicesDefaults } ) => {
  const servicesPage = data.servicesPage[ 0 ]
  const services = data.services
  const cta = data.cta[ 0 ]

  const {
    socialTitle,
    socialDescription,
    socialImage,
    socialUrl,
  } = setSocialForServicesPage( servicesPage )

  return (
    <>
      <Head
        title={ socialTitle }
        description={ socialDescription }
        img={ socialImage }
        url={ socialUrl }
      />

      <LayoutSection fixedHeaderPush narrow>
        <VerticalStack hero>
          <LargeCopy>{ servicesPage.runner }</LargeCopy>
        </VerticalStack>
      </LayoutSection>

      { services.map( ( service, i ) => {
        const img = (
          service.tileImage
          && service.tileImage.length
        )
          ? service.tileImage[ 0 ]
          : service.mainImage && service.mainImage[ 0 ]

        if (
          !img ||
          !img.focalPoint ||
          !service.title ||
          !service.introText
        ) return null

        return (
          <ServiceCard
            key={ service.id }
            url={ `/${ service.uri }` }
            title={ service.title }
            runner={ service.introText }
            img={ img }
            color={ service.color }
            direction={ i % 2 ? 'reverse' : 'forward' }
          >
            { (
                service.linkDestination[ 0 ] &&
                service.linkDescription
              ) && (
              <BoxedLink
                url={ service.linkDestination[ 0 ].uri }
                title={ service.linkTitle || 'Case Study' }
                runner={ service.linkDescription }
                color={ service.color || undefined }
              />
            ) }
          </ServiceCard>
        )
      } ) }

      { cta && cta.ctaPage && cta.ctaPage[ 0 ] && <CTABanner
        text={ cta.ctaIntroText }
        link={ cta && cta.ctaPage && cta.ctaPage[ 0 ] && cta.ctaPage[ 0 ].uri }
        cta={ cta.ctaButtonText }
      /> }
    </>
  )
}

export default withQuery( useGetServicesQuery )( Services )