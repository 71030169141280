import React from 'react'
import { Link } from 'react-router-dom'
import styled from 'styled-components'
import projectDefaults from 'fieldwork/graphql/fragments/projectDefaults'
import { typo } from 'fieldwork/styles/fonts'
import HeroCard from './HeroCard'

const ProjectHeroCard = ( { projectPreview = projectDefaults } ) => {
  const getImg = () => {
    if (
      projectPreview.heroCardImage
      && projectPreview.heroCardImage.length
    ) {
      return projectPreview.heroCardImage[0]
    }

    if (
      projectPreview.tileImage
      && projectPreview.tileImage.length
    ) {
      return projectPreview.tileImage[0]
    }

    return projectPreview.mainImage[0]
  }

  const img = getImg()

  img.altText = img.altText || projectPreview.title

  return (
    <HeroCard
      linkTo={ `/${ projectPreview.uri }` }
      title={ projectPreview.title }
      runner={ projectPreview.runner }
      ctaText="View the project"
      img={ img }
      tint={ projectPreview.tint }
    />
  )
}

export default ProjectHeroCard