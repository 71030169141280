import imageAttrsDefaults from './imageAttrsDefaults'

const serviceDefaults = {
  id: 0,
  title: '',
  uri: '',
  runner: '',
  introText: '',
  color: 'var( --highlightColor )',
  linkTitle: '',
  linkDescription: '',
  linkDestination: [{
    uri: '',
  }],
  mainImage: [imageAttrsDefaults],
  tileImage: [imageAttrsDefaults],
}

export default serviceDefaults
