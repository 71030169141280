import React from 'react'
import { Link } from 'react-router-dom'
import styled from 'styled-components'
import { typo } from 'fieldwork/styles/fonts'
import toWebclientUrl from 'fieldwork/helpers/toWebclientUrl'

interface BoxedLink {
  url: string,
  title: string,
  runner: string,
  color?: string,
}

const BoxedLink = ( {
  url,
  title,
  runner,
  color = 'var( --highlightColor )',
}: BoxedLink ) => (
  <StyledBoxedLink color={ color }>
    <Link to={ toWebclientUrl( url, true ) }>
      <h6>{ title }</h6>
      <p>{ runner }</p>
    </Link>
  </StyledBoxedLink>
)

const StyledBoxedLink = styled.div`
  > a {
    display: inline-block;
    padding: 0.6rem 0.8rem;
    border: 1px solid white;
    text-decoration: none;
    color: white;
    ${ typo.sansSerif }
    transition:
      background-color .3s ease-out,
      color .3s ease-out;

    h6 {
      font-weight: 600;
    }

    &:hover {
      background-color: white;
      color: ${ p => p.color };
    }
  }
`

export default BoxedLink