import React, { ReactNode } from 'react'
import styled, { css } from 'styled-components'

interface VerticalStack {
  top?: boolean,
  bottom?: boolean,
  notLastChild?: boolean,
  none?: boolean,
  small?: boolean,
  large?: boolean,
  xlarge?: boolean,
  hero?: boolean,
  fixedHeaderPush?: boolean,
}

const VerticalStack = ( props: VerticalStack & { children: ReactNode } ) => {
  const bottom = typeof props.bottom === 'undefined'
    ? true
    : props.bottom

  return <StyledVerticalStack { ...props } bottom={ bottom } />
}

const StyledVerticalStack = styled.div`
  ${ ( p: VerticalStack ) => p.top && css`
    padding-top: 2rem;
  ` }

  ${ ( p: VerticalStack ) => p.bottom && css`
    padding-bottom: 2rem;
  ` }

  ${ ( p: VerticalStack ) => p.notLastChild && css`
    &:last-child {
      padding-top: 0;
      padding-bottom: 0;
    }
  ` }

  ${ ( p: VerticalStack ) => p.none && css`
    padding-top: 0;
    padding-bottom: 0;
  ` }

  ${ ( p: VerticalStack ) => p.small && css`
    ${ p.top && css`
      padding-top: 1rem;
    ` }

    ${ ( p: VerticalStack ) => p.bottom && css`
      padding-bottom: 1rem;
    ` }
  ` }

  ${ ( p: VerticalStack ) => p.large && css`
    ${ p.top && css`
      padding-top: 4rem;
    ` }

    ${ ( p: VerticalStack ) => p.bottom && css`
      padding-bottom: 4rem;
    ` }
  ` }

  ${ ( p: VerticalStack ) => p.xlarge && css`
    ${ p.top && css`
      padding-top: 6rem;
    ` }

    ${ ( p: VerticalStack ) => p.bottom && css`
      padding-bottom: 6rem;
    ` }
  ` }

  ${ ( p: VerticalStack ) => p.hero && css`
    ${ p.top && css`
      padding-top: 3rem;
    ` }

    ${ ( p: VerticalStack ) => p.bottom && css`
      padding-bottom: 3rem;
    ` }

    @media all and ( min-width: 600px ) {
      ${ ( p: VerticalStack ) => p.top && css`
        padding-top: 5rem;
      ` }

      ${ ( p: VerticalStack ) => p.bottom && css`
        padding-bottom: 5rem;
      ` }
    }
  ` }

  ${ ( p: VerticalStack ) => p.fixedHeaderPush && css`
    padding-top: 120px;
  ` }
`

export default VerticalStack