import socialOrEmailLinkDefaults, { SocialOrEmailLink } from '../fragments/socialOrEmailLinkDefaults'

interface AppDefaults {
  fieldwork: Array<{
    address: string,
    email: string,
    phone: string,
    social: Array<SocialOrEmailLink>,
  }>,
  highlightColors: Array<{
    colors: string,
  }>,
}

const appDefaults: AppDefaults = {
  fieldwork: [{
    address: '',
    email: '',
    phone: '',
    social: [socialOrEmailLinkDefaults],
  }],
  highlightColors: [{
    colors: '',
  }],
}

export default appDefaults
