import projectDefaults from '../fragments/projectDefaults'
import ctaDefaults from '../fragments/ctaDefaults'

const homeDefaults = {
  home: [{
    title: '',
    uri: '',
    runner: '',
    projects: [projectDefaults],
  }],
  cta: [ctaDefaults],
}

export default homeDefaults
