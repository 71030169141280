interface SocialLink {
  id: number,
  email?: never,
  socialAccount: string,
  socialUrl: string,
  enabled?: boolean,
}

interface EmailLink {
  id: number,
  email: string,
  socialAccount?: never,
  socialUrl?: never,
  enabled?: boolean,
}

export type SocialOrEmailLink = SocialLink | EmailLink

const socialOrEmailLinkDefaults: SocialOrEmailLink = {
  id: 0,
  email: '',
}

export default socialOrEmailLinkDefaults