interface ImageMachineProps {
  vw?: number,
  h?: number | null,
  mode?: 'crop' | 'fit' | 'stretch',
  quality?: number,
  defaultWidth?: number,
}

interface AvailableSources {
  [key: string]: any,
}

const imageMachine = ( widths: Array<number> ) => ( {
  vw = 100,
  h = null,
  mode = 'crop',
  quality = 65,
  defaultWidth = widths[ 0 ],
}: ImageMachineProps ): {
  query: () => string,
  srcSet: ( availableSources: AvailableSources ) => string,
  src: ( availableSources: AvailableSources ) => string,
} => {
  if ( !widths.includes( defaultWidth ) ) {
    throw `defaultWidth: ${ defaultWidth } is not in the widths array.`
  }

  const getImageAttrs = ( w: number ) => {
    const width = Math.ceil( w * vw / 100 )
    const height = h ? Math.ceil( width * h ) : null
    const handle = `${ mode }_${ width }${ height ? `x${ height }` : `` }`

    return {
      width,
      height,
      handle,
    }
  }

  const getQuery = () => {
    const queries = widths.map( w => {
      const { width, height, handle } = getImageAttrs( w )

      return `
        ${ handle }: url @transform(
          mode: "${ mode }",
          width: ${ width },
          height: ${ height },
          quality: ${ quality }
        )
      `
    } )

    return queries.join( `` )
  }

  const getSrcSet = ( availableSources: AvailableSources ) => {
    const sources = widths.map( w => {
      const { width, height, handle } = getImageAttrs( w )

      if ( availableSources && typeof availableSources[ handle ] !== 'undefined' ) {
        return `${ availableSources[ handle ] } ${ width }w,`
      }

      return ''
    } )

    return sources.join( `` )
  }

  const getSrc = ( availableSources: AvailableSources ) => {
    const { handle } = getImageAttrs( defaultWidth )

    if ( availableSources && typeof availableSources[ handle ] !== 'undefined' ) {
      return availableSources[ handle ]
    }

    return ''
  }

  return {
    query: getQuery,
    srcSet: getSrcSet,
    src: getSrc,
  }
}

export default imageMachine