import React from 'react'
import withQuery from 'fieldwork/components/withQuery'
import { useGetAboutQuery } from 'fieldwork/graphql/generated/graphql'
import aboutDefaults from 'fieldwork/graphql/queries/aboutDefaults'
import { setSocialForAbout } from 'fieldwork/helpers/setSocial'
import Head from './Head'
import LayoutSection from './LayoutSection'
import VerticalStack from './VerticalStack'
import PersonCardGrid from './PersonCardGrid'
import LargeCopy from './LargeCopy'
import Values from './Values'
import CTABanner from './CTABanner'

export const About = ( { data = aboutDefaults } ) => {
  const about = data.about[ 0 ]
  const cta = data.cta[ 0 ]

  const {
    socialTitle,
    socialDescription,
    socialImage,
    socialUrl,
  } = setSocialForAbout( about )

  return (
    <>
      <Head
        title={ socialTitle }
        description={ socialDescription }
        img={ socialImage }
        url={ socialUrl }
      />

      <LayoutSection fixedHeaderPush narrow>
        <VerticalStack hero>
          <LargeCopy html={ about.textBlock } />
        </VerticalStack>
        <VerticalStack hero>
          <Values />
        </VerticalStack>
        <VerticalStack hero>
          <PersonCardGrid personPreviews={ about.people } />
        </VerticalStack>
      </LayoutSection>

      <CTABanner
        borderTop
        text={ cta.ctaIntroText }
        link={ cta.ctaPage[ 0 ].uri }
        cta={ cta.ctaButtonText }
      />
    </>
  )
}

export default withQuery( useGetAboutQuery )( About )