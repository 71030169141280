import React, { useEffect, useRef } from 'react'
import styled from 'styled-components'
// import 'mapbox-gl/dist/mapbox-gl.css'
import Icon from './Icon/Icon'

interface Map {
  lat: string,
  lng: string,
  zoom: string,
  scrollZoom?: boolean,
  style: string,
}

const Map = ( {
  lat,
  lng,
  zoom,
  scrollZoom,
  style,
}: Map ) => {
  let map
  const mapContainerRef = useRef( null )
  const mapMarkerRef = useRef( null )

  if ( typeof window !== 'undefined' ) {
    const mapboxgl = require( 'mapbox-gl' )
    mapboxgl.accessToken = 'pk.eyJ1IjoiZWRmaWVsZHdvcmsiLCJhIjoicTMwZTkydyJ9.PReYJSCAJ4cFTBOsINez8A'

    useEffect( () => {
      map = new mapboxgl.Map( {
        container: mapContainerRef.current,
        style: style || null,
        center: [lng, lat],
        zoom: zoom,
        scrollZoom: scrollZoom || false,
      } )

      new mapboxgl.Marker( mapMarkerRef.current )
        .setLngLat( [lng, lat] )
        .addTo( map )
    } )
  }

  return (
    <StyledMapContainer ref={ mapContainerRef }>
      <StyledMapMarker ref={ mapMarkerRef }>
        <Icon iconName="mapMarker" iconTitle="Fieldwork" />
      </StyledMapMarker>
    </StyledMapContainer>
  )
}

const StyledMapContainer = styled.div`
  position: relative;
  width: 100%;
  padding-bottom: 60%;
  height: 0;
  background: var( --highlightColor );

  @media all and ( min-width: 600px ) {
    padding-bottom: 46%;
  }

  @media all and ( min-width: 900px ) {
    padding-bottom: 36%;
  }

  .mapboxgl-canvas {
    position: absolute;
    top: 0;
    bottom: 0;
    width: 100%;
    height: 100%;
  }
`

const StyledMapMarker = styled.div`
  width: 30px;
  height: 60px;

  svg {
    width: 100%;

    path,
    polygon {
      fill: var( --highlightColor );
    }
  }
`

export default Map