import React from 'react'
import styled, { css } from 'styled-components'
import aboutDefaults from 'fieldwork/graphql/queries/aboutDefaults'
import Icon from 'fieldwork/components/Icon/Icon'

interface Social {
  socialLinks: Array<{ [key: string]: any }>,
  align?: 'left' | 'right',
  color?: string,
}

const Social = ( {
  socialLinks = aboutDefaults.about[ 0 ].people[ 0 ].social,
  align = 'left',
  color = '',
}: Social ) => {
  return (
    <StyledSocial align={ align }>
      { socialLinks.map( socialLink => (
        <SocialLink
          socialLink={ socialLink }
          key={ socialLink.id }
          align={ align }
          color={ color }
        />
      ) ) }
    </StyledSocial>
  )
}

interface StyledSocial {
  align?: 'left' | 'right',
}

const StyledSocial = styled.div`
  ${ ( p: StyledSocial ) => p.align === 'right' && css`
    text-align: right;
  ` }
`

interface SocialLink {
  socialLink: { [key: string]: any },
  align?: 'left' | 'right',
  color?: string,
}

const SocialLink = ( {
  socialLink = aboutDefaults.about[ 0 ].people[ 0 ].social[ 0 ],
  align = 'left',
  color = '',
}: SocialLink ) => {
  const linkProps = {
    socialAccount: socialLink.email ? 'email' : socialLink.socialAccount,
    socialUrl: socialLink.email ? `mailto:${ socialLink.email }` : socialLink.socialUrl,
  }

  if ( !linkProps.socialAccount ) {
    console.warn( `The socialAccount prop is not valid: ${ linkProps.socialAccount }. Setting to 'email' to avoid errors.` )
    linkProps.socialAccount = 'email'
  }

  return (
    <StyledSocialLink
      href={ linkProps.socialUrl }
      iconName={ linkProps.socialAccount }
      align={ align }
      color={ color }
    >
      <Icon iconName={ linkProps.socialAccount } />
    </StyledSocialLink>
  )
}

interface StyledSocialLink {
  align?: 'left' | 'right',
  color?: string,
  iconName: string,
}

const StyledSocialLink = styled.a`
  position: relative;
  display: inline-block;
  text-indent: -999em;
  width: 18px;
  height: 18px;

  ${ ( p: StyledSocialLink ) => p.align === 'left' && css`
    margin-right: 9px;
  ` }

  ${ ( p: StyledSocialLink ) => p.align === 'right' && css`
    margin-left: 9px;
  ` }

  svg {
    position: absolute;
    top: 0;
    left: 0;
    max-height: 100%;
    max-width: 100%;

    path {
      fill: var( --lightTextGrey );
      transition: fill .3s ease-out;

      ${ ( p: StyledSocialLink ) => p.color && css`
        fill: var( --${ p.color } );
      ` }
    }
  }

  &:hover svg path {
    fill: var( --highlightColor );
  }

  ${ ( p: StyledSocialLink ) => p.iconName === 'github' && css`
    svg {
      height: 90%;
      top: 5%;
      left: 5%;
    }
  ` }

  ${ ( p: StyledSocialLink ) => p.iconName === 'facebook' && css`
    width: 12px;
  ` }

  ${ ( p: StyledSocialLink ) => p.iconName === 'twitter' && css`
    svg {
      height: 90%;
      top: 5%;
      left: 5%;
    }
  ` }

  ${ ( p: StyledSocialLink ) => p.iconName === 'instagram' && css`
    svg {
      height: 90%;
      top: 5%;
      left: 5%;
    }
  ` }

  ${ ( p: StyledSocialLink ) => p.iconName === 'email' && css`
    svg {
      height: 90%;
      top: 5%;
      left: 5%;
    }
  ` }
`

export default Social