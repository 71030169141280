import React from 'react'
import { Router } from 'react-router-dom'
import history from 'fieldwork/history'
import apolloClient from 'fieldwork/apolloClient'
import AppContainer from 'fieldwork/components/AppContainer'

const AppWithRouter = () => (
  <Router history={ history }>
    <AppContainer apolloClient={ apolloClient } />
  </Router>
)

export default AppWithRouter
