import React from 'react'
import projectDefaults from 'fieldwork/graphql/fragments/projectDefaults'
import SimpleGrid from './SimpleGrid'
import ProjectCard from './ProjectCard'

const ProjectCardGrid = ( { projectPreviews = [projectDefaults] } ) => {
  return (
    <SimpleGrid
      minColumnWidth={ 250 }
      gridGap={ 18 }
      maxColumns={ 3 }
    >
      { projectPreviews.map( projectPreview => (
        <ProjectCard key={ projectPreview.id } projectPreview={ projectPreview } />
      ) ) }
    </SimpleGrid>
  )
}

export default ProjectCardGrid