import React from 'react'
import styled from 'styled-components'
import aboutDefaults from 'fieldwork/graphql/queries/aboutDefaults'
import Social from './Social'

const personCardDefaults = aboutDefaults.about[ 0 ].people[ 0 ]

const PersonCard = ( { personPreview = personCardDefaults } ) => {
  return (
    <StyledPersonCard>
      <StyledPersonCardImage>
        <img src={ personPreview.photo.url } alt={ personPreview.fullName } />
      </StyledPersonCardImage>
      <StyledPersonCardName>
        { personPreview.fullName }
      </StyledPersonCardName>
      <StyledPersonCardJobTitle>
        { personPreview.jobTitle }
      </StyledPersonCardJobTitle>
      <Social
        socialLinks={ personPreview.social }
        color="iconGrey"
      />
    </StyledPersonCard>
  )
}

const StyledPersonCard = styled.div`
  font-family: 'Maison Neue';
  font-size: 14px;
  line-height: 1.42;
`

const StyledPersonCardImage = styled.div`
  position: relative;
  overflow: hidden;
  padding-bottom: 100%;
  background: var( --highlightColor );

  img {
    position: absolute;
    top: -0.5%;
    left: -0.5%;
    right: -0.5%;
    width: 101%;
    transition: opacity .3s ease-out;
    -webkit-backface-visibility: hidden;
  }
`

const StyledPersonCardName = styled.h3`
  padding-top: 1em;
  font-size: 14px;
  font-weight: 400;
  line-height: 1.42;
  color: $color-black;
`

const StyledPersonCardJobTitle = styled.p`
  padding-bottom: 1em;
  color: var( --lightTextGrey );
`

export default PersonCard