import projectDefaultsFragment from '../fragments/projectDefaults'
import imageAttrsDefaults from '../fragments/imageAttrsDefaults'
import ctaDefaults from '../fragments/ctaDefaults'

interface Project {
  [key: string]: any,
  projectContent: Array<{
    __typename: string,
    id: number,
    text?: string,
    sectionTitle?: string,
    portrait?: boolean,
    fullWidth?: boolean,
    image?: {
      [key: string]: any,
    },
  }>,
}

const project: Project = {
  ...projectDefaultsFragment,
  socialTitle: '',
  socialDescription: '',
  socialImage: [imageAttrsDefaults],
  mainImage: [imageAttrsDefaults],
  projectContent: [{
    __typename: '',
    id: 0,
    text: '',
    sectionTitle: '',
  }],
}

const projectDefaults = {
  project: [project],
  cta: [ctaDefaults],
}

export default projectDefaults
