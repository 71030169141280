import React from 'react'
import styled from 'styled-components'
import withQuery from 'fieldwork/components/withQuery'
import { useGetProjectQuery } from 'fieldwork/graphql/generated/graphql'
import projectDefaults from 'fieldwork/graphql/queries/projectDefaults'
import { setSocialForProject } from 'fieldwork/helpers/setSocial'
import Head from './Head'
import LayoutSection from './LayoutSection'
import VerticalStack from './VerticalStack'
import HeroImage from './HeroImage'
import ProjectTextSection from './ProjectTextSection'
import ImageGroup from './ImageGroup'
import CTABanner from './CTABanner'
import NotFound from './NotFound'

export const Project = ( { data = projectDefaults } ) => {
  const project = data.project[ 0 ]
  const cta = data.cta[ 0 ]

  const {
    socialTitle,
    socialDescription,
    socialImage,
    socialUrl,
  } = setSocialForProject( project )

  const mainImage = project.mainImage[0]
  const projectContent = groupImages( groupTextSections( project.projectContent ) )

  let introText = ''

  if (
    projectContent
    && projectContent[0]
    && projectContent[0].__typename === 'projectContent_text_BlockType'
    && typeof projectContent[0].text !== 'undefined'
  ) {
    introText = projectContent[0].text
    projectContent.shift()
  }

  return (
    <>
      <Head
        title={ socialTitle }
        description={ socialDescription || introText }
        img={ socialImage }
        url={ socialUrl }
      />

      <LayoutSection fixedHeaderPush narrow>
        <VerticalStack hero>
          <ProjectTextSection
            title={ project.title }
            html={ introText }
            large
          />
        </VerticalStack>
      </LayoutSection>

      <VerticalStack hero>
        <HeroImage img={ mainImage } />
      </VerticalStack>

      { projectContent.map( contentItem => {
        if ( Array.isArray( contentItem ) ) {
          return (
            <LayoutSection centered key={ contentItem[ 0 ].id }>
              <VerticalStack hero>
                <ImageGroup imageItems={ contentItem } />
              </VerticalStack>
            </LayoutSection>
          )
        }
        else {
          return (
            <LayoutSection centered narrow key={ contentItem.id }>
              <VerticalStack hero>
                <ProjectTextSection
                  title={ contentItem.sectionTitle }
                  html={ contentItem.text || '' }
                  medium
                />
              </VerticalStack>
            </LayoutSection>
          )
        }
      } ) }

      <CTABanner
        borderTop
        text={ cta.ctaIntroText }
        link={ cta.ctaPage[ 0 ].uri }
        cta={ cta.ctaButtonText }
      />
    </>
  )
}

const StyledProjectBody = styled.div`
  max-width: 1500px;
  margin: 0 auto;
`

const groupImages = <T extends any[]>( projectContent: T ): T => {
  return projectContent.reduce(
    ( accumulator, item, i, arr ) => {
      if ( item.__typename === 'projectContent_image_BlockType' ) {
        if (
          i > 0
          && arr[ i - 1 ]
          && Array.isArray( accumulator[ accumulator.length - 1 ] )
        ) {
          accumulator[ accumulator.length - 1 ].push( item )
        }
        else {
          accumulator.push( [item] )
        }
      }
      else {
        accumulator.push( item )
      }

      return accumulator
    },
    []
  )
}

const groupTextSections = <T extends any[]>( projectContent: T ): T => {
  return projectContent.reduce(
    ( accumulator, item, i, arr ) => {
      if (
        item.__typename === 'projectContent_text_BlockType'
        && i > 0
        && arr[ i - 1 ]
        && accumulator[ accumulator.length - 1 ].__typename === 'projectContent_sectionTitle_BlockType'
      ) {
        accumulator[ accumulator.length - 1 ].text = item.text
      }
      else {
        accumulator.push( item )
      }

      return accumulator
    },
    []
  )
}

interface RoutedProject {
  match: {
    params: {
      projectSlug: string,
    },
  },
}

const RoutedProject = ( { match: { params: { projectSlug } } }: RoutedProject ) => {
  return withQuery(
    useGetProjectQuery,
    { variables: { projectSlug } },
    NotFound,
    ( data ) => data.project && data.project.length > 0
  )( Project )( {} )
}

export default RoutedProject