import React from 'react'
import { Link } from 'react-router-dom'
import styled, { css } from 'styled-components'
import imageAttrsDefaults from 'fieldwork/graphql/fragments/imageAttrsDefaults'
import { typo } from 'fieldwork/styles/fonts'
import heroCardImages from 'fieldwork/imageSizes/heroCardImages'
import LayoutSection from './LayoutSection'
import FadingImage from './FadingImage'

interface HeroCard {
  linkTo?: string,
  title: string,
  runner: string,
  ctaText?: string,
  img?: { [key: string]: any },
  color?: string,
  blend?: boolean,
  tint?: number,
}

const heroCardDefaults = {
  linkTo: '',
  title: '',
  runner: '',
  ctaText: '',
  img: imageAttrsDefaults,
  color: '',
  blend: false,
  tint: 0.2,
} as HeroCard

const HeroCard = ( {
  linkTo,
  title,
  runner,
  ctaText,
  img,
  color,
  blend,
  tint,
} = heroCardDefaults ) => {
  if ( linkTo ) {
    return (
      <StyledHeroCard>
        <Link to={ linkTo }>
          <CardContents
            title={ title }
            runner={runner  }
            ctaText={ ctaText }
            img={ img }
            color={ color }
            blend={ blend }
            tint={ tint }
          />
        </Link>
      </StyledHeroCard>
    )
  }

  return (
    <StyledHeroCard>
      <CardContents
        title={ title }
        runner={runner  }
        ctaText={ ctaText }
        img={ img }
        color={ color }
        blend={ blend }
        tint={ tint }
      />
    </StyledHeroCard>
  )
}

const CardContents = ( {
  title,
  runner,
  ctaText,
  img,
  color,
  blend,
  tint,
} = heroCardDefaults ) => (
  <>
    <CardImage
      img={ img }
      color={ color }
      blend={ blend }
      tint={ tint }
    />
    <StyledHeroCardText>
      <LayoutSection>
        <h2>{ title }</h2>
        <p>{ runner }</p>
        { ctaText ? <span>{ ctaText }</span> : null }
      </LayoutSection>
    </StyledHeroCardText>
  </>
)

interface CardImage {
  img?: { [key: string]: any },
  color?: string,
  blend?: boolean,
  tint?: number,
}

const CardImage = ( {
  img,
  color,
  blend,
  tint,
}: CardImage ) => {
  if ( img ) {
    return (
      <StyledHeroCardImage
        img={ img }
        color={ color }
        blend={ blend }
        tint={ tint }
      >
        <FadingImage
          srcSet={ heroCardImages.srcSet( img ) }
          src={ heroCardImages.src( img ) }
          alt={ img.altText }
        />
      </StyledHeroCardImage>
    )
  }

  return (
    <StyledHeroCardBackground color={ color } />
  )
}

const StyledHeroCard = styled.div`
  position: relative;

  a {
    display: block;
    text-decoration: none;
  }
`

const StyledHeroCardImage = styled.div`
  position: relative;
  overflow: hidden;
  padding-bottom: 0%;
  min-height: 280px;
  background-color: var( --highlightColor );

  ${ ( p: CardImage ) => p.blend && css`
    background-color: ${ p.color || `var( --highlightColor )` };
  ` }

  @media all and ( min-width: 600px ) {
    padding-bottom: 40%;
  }

  ${ ( p: CardImage ) => !p.blend && p.img && css`
    :before {
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      filter: grayscale( 100% );
      background-image: url( ${ heroCardImages.src( p.img ) } );
      background-size: cover;
      background-position:
        ${ p.img.focalPoint[ 0 ] * 100 }%
        ${ p.img.focalPoint[ 1 ] * 100 }%;
      mix-blend-mode: multiply;
      transition: background .3s ease-out;
    }
  ` }

  ${ ( p: CardImage ) => p.tint && css `
    :after {
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      background: rgba( 0, 0, 0, ${ p.tint } );
      transition: opacity .3s ease-out;

      ${ StyledHeroCard } a:hover & {
        opacity: 0;
      }
    }
  ` }

  ${ ( p: CardImage ) => p.img && css `
    img {
      position: absolute;
      top: 0;
      bottom: 0;
      left: 0;
      right: 0;
      width: 100%;
      height: 100%;
      -webkit-backface-visibility: hidden;
      object-fit: cover;
      object-position:
        ${ p.img.focalPoint[ 0 ] * 100 }%
        ${ p.img.focalPoint[ 1 ] * 100 }%;
      transition: opacity .3s ease-out;

      ${ StyledHeroCard } a:hover & {
        opacity: 0;
      }
    }
  ` }
`

const StyledHeroCardBackground = styled.div`
  position: relative;
  overflow: hidden;
  padding-bottom: 0%;
  min-height: 280px;
  height: 60%;
  background-color: ${ ( p: CardImage ) => p.color || 'var( --highlightColor )' };

  @media all and ( min-width: 600px ) {
    padding-bottom: 40%;
  }
`

const StyledHeroCardText = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  ${ typo.large }
  color: white;

  h2 {
    font-weight: 600;
  }

  p {
    font-weight: 300;
  }

  span {
    display: inline-block;
    color: rgba(255,255,255,0.7);
    border-bottom: 1px solid rgba(255,255,255,0.7);
    padding-bottom: 0.3em;
    padding-top: 2em;
    ${ typo.mono }
  }
`

export default HeroCard