import serviceDefaults from '../fragments/serviceDefaults'
import ctaDefaults from '../fragments/ctaDefaults'

const servicesDefaults = {
  servicesPage: [{
    title: '',
    uri: '',
    runner: '',
  }],
  services: [serviceDefaults],
  cta: [ctaDefaults],
}

export default servicesDefaults
