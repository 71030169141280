// @flow
import { css } from 'styled-components'
import colors from './colors'

export const typo = {
  sansSerif: css`
    font-family: 'Founders Grotesk', sans-serif;
    font-weight: 400;
    font-size: 18px;
    line-height: 1.2;
  `,
  mono: css`
    font-family: 'Maison Neue', mono;
    font-weight: 400;
    font-size: 14px;
    line-height: 1.2;
  `,
  large: css`
    font-family: 'Founders Grotesk', sans-serif;
    font-size: 30px;
    line-height: 1;
    font-weight: 600;
    color: var( --headlineGrey );

    @media all and ( min-width: 600px ) {
      font-size: 40px;
    }
  `,

  medium: css`
    font-family: 'Founders Grotesk', sans-serif;
    font-size: 24px;
    line-height: 1.2;
    color: var( --headlineGrey );

    @media all and ( min-width: 600px ) {
      font-size: 32px;
    }
  `,
}

const fonts = css`
  @font-face{
    font-family: 'Founders Grotesk';
    font-display: block;
    src: url('/fonts/FoundersGroteskWeb-Light.eot');
    src: url('/fonts/FoundersGroteskWeb-Light.eot?#iefix') format('embedded-opentype'),
        url('/fonts/FoundersGroteskWeb-Light.woff') format('woff');
    font-weight: 300;
    font-style: normal;
  }

  @font-face{
    font-family: 'Founders Grotesk';
    font-display: block;
    src: url('/fonts/FoundersGroteskWeb-Regular.eot');
    src: url('/fonts/FoundersGroteskWeb-Regular.eot?#iefix') format('embedded-opentype'),
        url('/fonts/FoundersGroteskWeb-Regular.woff') format('woff');
    font-weight: 400;
    font-style: normal;
  }

  @font-face{
    font-family: 'Founders Grotesk';
    font-display: block;
    src: url('/fonts/FoundersGroteskWeb-Medium.eot');
    src: url('/fonts/FoundersGroteskWeb-Medium.eot?#iefix') format('embedded-opentype'),
        url('/fonts/FoundersGroteskWeb-Medium.woff') format('woff');
    font-weight: 600;
    font-style: normal;
  }

  @font-face {
    font-family: 'Maison Neue';
    font-display: block;
    src:
        url('/fonts/MaisonNeueMono-Regular.woff2') format('woff2'),
        url('/fonts/MaisonNeueMono-Regular.woff') format('woff');
    font-weight: 400;
    font-style: normal;
  }

  html,
  body {
    ${ typo.sansSerif }
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    color: var(  --black );
  }
`

export default fonts
