import React from 'react'
import styled from 'styled-components'
import { typo } from 'fieldwork/styles/fonts'

const Values = () => (
  <StyledValues>
    <h3>Our Values</h3>
    <ol>
      <li>Keep it simple, do it well</li>
      <li>Be better, not bigger</li>
      <li>Always be tinkering</li>
      <li>Be adventurous</li>
      <li>Be open, share ideas and resources</li>
      <li>Make friends and collaborate</li>
      <li>Iterate</li>
      <li>Make stuff to change the world (even just a little bit)</li>
    </ol>
  </StyledValues>
)

const StyledValues = styled.div`
  h3 {
    ${ typo.large }
  }

  ol {
    /* margin-left: -1rem; */
    counter-reset: the-counter;
    list-style: none;
  }

  li {
    position: relative;
    ${ typo.large }
    font-weight: 300;
    counter-increment: the-counter;
    /* margin-left: -1rem; */

    &:before {
      content: counter( the-counter );
      display: block;
      width: 1rem;
      position: absolute;
      left: -0.8rem;
      bottom: 1px;
      ${ typo.mono }

      @media all and ( min-width: 600px ) {
        left: -1rem;
        bottom: 2px;
      }
    }
  }
`

export default Values