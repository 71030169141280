// @flow
import { css } from 'styled-components'

const body = css`
    html,
    body {
        position: relative;
        width: 100vw;
        height: 100vh;
        overflow: visible;
        background: var( --bgGrey );
    }

    * {
        box-sizing: border-box;
    }
`

export default body
