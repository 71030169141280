import React, { useState, useEffect } from 'react'
import LargeCopy from './LargeCopy'

const Loader = () => {
  const [count, setCount] = useState( 3 )

  const nextCount = () => {
    setCount( count => {
      return count === 3 ? 1 : count + 1
    } )
  }

  const repeat = ( num: number ) => ( text: string ) => {
    let str = ''

    for ( let i = 1; i <= num; i ++ ) {
      str += text
    }

    return str
  }

  useEffect(
    () => {
      let timer = setInterval( () => nextCount(), 1000 )

      return () => {
        clearInterval( timer )
      }
    },
    []
  )

  return (
    <LargeCopy>
      { repeat( count )( '.' ) }
    </LargeCopy>
  )
}

export default Loader