import imageAttrsDefaults from './imageAttrsDefaults'

interface ProjectDefaults {
  tint?: number,
  [key: string]: any,
}

const projectDefaults: ProjectDefaults = {
  id: 0,
  title: '',
  uri: '',
  runner: '',
  mainImage: [imageAttrsDefaults],
  tileImage: [imageAttrsDefaults],
  heroCardImage: [imageAttrsDefaults],
  tint: 0.2,
}

export default projectDefaults
