import React from 'react'
import withQuery from 'fieldwork/components/withQuery'
import { useGetProjectsQuery } from 'fieldwork/graphql/generated/graphql'
import projectsDefaults from 'fieldwork/graphql/queries/projectsDefaults'
import { setSocialForProjectsPage } from 'fieldwork/helpers/setSocial'
import Head from './Head'
import LayoutSection from './LayoutSection'
import VerticalStack from './VerticalStack'
import ProjectCardGrid from './ProjectCardGrid'
import ProjectCard from './ProjectCard'
import CTABanner from './CTABanner'

const Projects = ( { data = projectsDefaults } ) => {
  const projectsPage = data.projectsPage[ 0 ]
  const projects = data.projects
  const cta = data.cta[ 0 ]

  const {
    socialTitle,
    socialDescription,
    socialImage,
    socialUrl,
  } = setSocialForProjectsPage( projectsPage )

  return (
    <>
      <Head
        title={ socialTitle }
        description={ socialDescription }
        img={ socialImage }
        url={ socialUrl }
      />

      <LayoutSection>
        <VerticalStack fixedHeaderPush hero>
          <ProjectCardGrid projectPreviews={ projects } />
        </VerticalStack>
      </LayoutSection>

      <CTABanner
        borderTop
        text={ cta.ctaIntroText }
        link={ cta.ctaPage[ 0 ].uri }
        cta={ cta.ctaButtonText }
      />
    </>
  )
}

export default withQuery( useGetProjectsQuery )( Projects )