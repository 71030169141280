import projectDefaults from '../fragments/projectDefaults'
import ctaDefaults from '../fragments/ctaDefaults'

const projectsDefaults = {
  projectsPage: [{
    title: '',
    uri: '',
    runner: '',
  }],
  projects: [projectDefaults],
  cta: [ctaDefaults],
}

export default projectsDefaults
