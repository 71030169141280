// @flow
import React from 'react'
import styled, { css } from 'styled-components'
import imageAttrsDefaults from 'fieldwork/graphql/fragments/imageAttrsDefaults'
import fullWidthImages from 'fieldwork/imageSizes/fullWidthImages'
import FadingImage from './FadingImage'

const imageDefaults = {
  id: 0,
  portrait: false,
  fullWidth: true,
  image: [imageAttrsDefaults],
}

const ImageGroup = ( { imageItems = [imageDefaults] } ) => (
  <StyledImageGroup>
    { imageItems.map( imageItem => <ImageItem imageItem={ imageItem } key={ imageItem.id } /> ) }
  </StyledImageGroup>
)

const ImageItem = ( { imageItem = imageDefaults } ) => {
  const img = imageItem.image[ 0 ]
  const aspectRatio = img.height && img.width ? img.height / img.width : null

  let sizes = imageItem.fullWidth
    ? '(max-width: 1700px) 90vw, 1500px'
    : '(max-width: 1700px) 45vw, 750px'

  return (
    <StyledImageItem
      fullWidth={ imageItem.fullWidth }
      portrait={ imageItem.portrait }
      aspectRatio={ aspectRatio }
    >
      <FadingImage
        srcSet={ fullWidthImages.srcSet( img ) }
        sizes={ sizes }
        src={ fullWidthImages.src( img ) }
        alt={ img.altText }
      />
    </StyledImageItem>
  )
}

const StyledImageGroup = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 12px;
`
interface StyledImageItem {
  portrait?: boolean,
  fullWidth?: boolean,
  aspectRatio: number | null,
}

const StyledImageItem = styled.div`
  overflow: hidden;
  position: relative;
  background: var( --highlightColor );

  ${ ( p: StyledImageItem ) => p.portrait && css`
    grid-row-end: span 2;
  ` }

  ${ ( p: StyledImageItem ) => p.fullWidth && css`
    grid-column-end: span 2;
  ` }

  img {
    display: block;
    width: 100%;
    height: 100%;
    object-fit: cover;

    ${ ( p: StyledImageItem ) => !p.fullWidth && css`
      position: absolute;
      top: 0;
      bottom: 0;
      left: 0;
      right: 0;
    ` }
  }

  ${ ( p: StyledImageItem ) => p.aspectRatio && ( p.fullWidth || !p.portrait ) && css`
    height: 0;
    padding-bottom: ${ p.aspectRatio * 100 }%;

    img {
      position: absolute;
      top: 0;
      bottom: 0;
      left: 0;
      right: 0;
    }
  ` }
`

export default ImageGroup