import React, { useState } from 'react'
import styled, { css } from 'styled-components'

interface FadingImage {
  srcSet?: string,
  sizes?: string,
  src: string,
  alt?: string,
}

const FadingImage = ( {
  srcSet = '',
  sizes = '',
  src,
  alt = '',
}: FadingImage ) => {
  const [isLoaded, setLoaded] = useState<boolean | null>( null )

  return (
    <StyledImage
      srcSet={ srcSet }
      sizes={ sizes }
      src={ src }
      alt={ alt }
      onLoad={ () => setLoaded( true ) }
      onError={ () => setLoaded( false ) }
      isLoaded={ isLoaded }
      isBrowser={ typeof window !== 'undefined' }
    />
  )
}

interface StyledImage {
  isLoaded: boolean | null,
  isBrowser: boolean,
}

const StyledImage = styled.img`
  ${ ( p: StyledImage ) => p.isBrowser && css`
    opacity: 0;
    transition: opacity .5s linear;
  ` }

  ${ ( p: StyledImage ) => p.isLoaded && css`
    opacity: 1;
  ` }
`

export default FadingImage