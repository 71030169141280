// @flow
import React, { ReactNode } from 'react'
import { Link } from 'react-router-dom'
import styled, { css } from 'styled-components'
import { typo } from 'fieldwork/styles/fonts'
import fullWidthImages from 'fieldwork/imageSizes/fullWidthImages'
import LayoutSection from './LayoutSection'
import FadingImage from './FadingImage'

type Direction = 'forward' | 'reverse'

export interface ServiceCard {
  url: string,
  title: string,
  runner: string,
  img: {
    focalPoint: number[],
  },
  color?: string,
  direction?: Direction,
  children: ReactNode,
}

const ServiceCard = ( {
  url,
  title,
  runner,
  img,
  color = 'var( --highlightColor )',
  direction = 'forward',
  children,
}: ServiceCard ) => (
  <StyledServiceCard
    color={ color }
    direction={ direction }
  >
    <Link to={ url }>
      <StyledServiceCardContent direction={ direction } hasFooter={ !!children }>
        <div>
          <h3>{ title }</h3>
          <div dangerouslySetInnerHTML={ { __html: runner } } />
          <span>Learn more</span>
        </div>
      </StyledServiceCardContent>
      <StyledServiceCardImage focalPoint={ img.focalPoint }>
        <FadingImage
          srcSet={ fullWidthImages.srcSet( img ) }
          sizes="(min-width: 900px) 50vw, 100vw"
          src={ fullWidthImages.src( img ) }
          alt={ title }
        />
      </StyledServiceCardImage>
    </Link>

    { children ? (
      <StyledServiceCardFooter>
        { children }
      </StyledServiceCardFooter>
    ) : null }

  </StyledServiceCard>
)

const breakpoint = 900

const StyledServiceCard = styled.div`
  position: relative;
  overflow: hidden;

  > a {
    display: block;
    background-color: ${ p => p.color };
    text-decoration: none;
    color: white;

    @media all and ( min-width: ${ breakpoint }px ) {
      display: flex;
      flex-direction: ${ ( p: { direction: Direction } ) => p.direction === 'reverse' ? 'row-reverse' : 'row' };
    }
  }
`

interface StyledServiceCardContent {
  hasFooter: boolean,
  direction: Direction,
}

const StyledServiceCardContent = styled.div`
  position: relative;
  z-index: 1;
  display: flex;
  flex-direction: column;
  padding: 3rem 5vw;
  ${ typo.medium }
  color: inherit;

  @media all and ( min-width: ${ breakpoint }px ) {
    width: 50%;
    flex-grow: 1;

    ${ ( p: StyledServiceCardContent ) => p.hasFooter && css`
      padding-bottom: 9rem;
    ` }

    ${ ( p: StyledServiceCardContent ) => p.direction === 'forward' && css`
      padding-left: 5vw;
      padding-right: 3rem;
    ` }

    ${ ( p: StyledServiceCardContent ) => p.direction === 'reverse' && css`
      padding-left: 3rem;
      padding-right: 5vw;
    ` }
  }

  h3 {
    font-weight: 600;
    margin-bottom: 1em;
  }

  p {
    font-weight: 300;
  }

  span {
    display: inline-block;
    color: rgba(255,255,255,0.7);
    border-bottom: 1px solid rgba(255,255,255,0.7);
    padding-bottom: 0.3em;
    padding-top: 2em;
    ${ typo.mono }
    transition:
      color .3s ease-out,
      border-color .3s ease-out;

    ${ StyledServiceCard }:hover & {
      color: rgb(255,255,255);
      border-color: rgb(255,255,255);
    }
  }
`

const StyledServiceCardFooter = styled.div`
  position: absolute;
  bottom: 3rem;
  left: 5vw;
  max-width: 90vw;

  @media all and ( min-width: ${ breakpoint }px ) {
    max-width: 40vw;
  }
`

const StyledServiceCardImage = styled.div`
  @media all and ( min-width: ${ breakpoint }px ) {
    width: 50%;
    flex-grow: 1;
  }

  img {
    display: block;
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position:
      ${ ( p: { focalPoint: number[] } ) => p.focalPoint[ 0 ] * 100 }%
      ${ ( p: { focalPoint: number[] } ) => p.focalPoint[ 1 ] * 100 }%;
    filter: grayscale( 100% );
    mix-blend-mode: multiply;
  }
`

export default ServiceCard