import React from 'react'
import aboutDefaults from 'fieldwork/graphql/queries/aboutDefaults'
import SimpleGrid from './SimpleGrid'
import PersonCard from './PersonCard'

const personCardGridDefaults = aboutDefaults.about[ 0 ].people

const PersonCardGrid = ( { personPreviews = personCardGridDefaults } ) => {
  return (
    <SimpleGrid
      minColumnWidth={ 180 }
      gridGap={ 18 }
      verticalGap={ 36 }
      maxColumns={ 3 }
    >
      { personPreviews.map( personPreview => (
        <PersonCard key={ personPreview.id } personPreview={ personPreview } />
      ) ) }
    </SimpleGrid>
  )
}

export default PersonCardGrid