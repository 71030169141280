import fetch from 'cross-fetch'
import ApolloClient from 'apollo-boost'
import { InMemoryCache } from 'apollo-cache-inmemory'
import gql from 'graphql-tag'
import fragmentTypes from 'fieldwork/fragmentTypes.json'

import { IntrospectionFragmentMatcher } from 'apollo-cache-inmemory'

const fragmentMatcher = new IntrospectionFragmentMatcher( {
  introspectionQueryResultData: fragmentTypes
} )

const theWindow = window as any

const cache = theWindow.__APOLLO_STATE__
  ? new InMemoryCache( { fragmentMatcher } ).restore( theWindow.__APOLLO_STATE__ )
  : new InMemoryCache( { fragmentMatcher } )

let uri = `https://${ window.CMS_HOSTNAME }/graphql`
const m = document.location.href.match( /\btoken=([^&]+)/ );

if ( m ) {
  uri = `${ uri }?token=${ m[ 1 ] }`
}

const apolloClient = new ApolloClient( {
  uri,
  fetch: window.fetch,
  cache,
} )

export default apolloClient