import serviceDefaultsFragment from '../fragments/serviceDefaults'
import ctaDefaults from '../fragments/ctaDefaults'

const service = {
  ...serviceDefaultsFragment,
  textSections: [{
    id: 0,
    heading: '',
    bodyText: '',
  }],
}

const serviceDefaults = {
  service: [service],
  cta: [ctaDefaults],
}

export default serviceDefaults
