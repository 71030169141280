import React from 'react'
import styled, { css } from 'styled-components'
import imageAttrsDefaults from 'fieldwork/graphql/fragments/imageAttrsDefaults'
import fullWidthImages from 'fieldwork/imageSizes/fullWidthImages'
import FadingImage from './FadingImage'

const HeroImage = ( { img = imageAttrsDefaults } ) => {
  const aspectRatio = ( img.height && img.width ) ? img.height / img.width : null

  return (
    <StyledHeroImage aspectRatio={ aspectRatio }>
      <FadingImage
        srcSet={ fullWidthImages.srcSet( img ) }
        src={ fullWidthImages.src( img ) }
        alt={ img.altText }
      />
    </StyledHeroImage>
  )
}

interface StyledHeroImage {
  aspectRatio: number | null,
}

const StyledHeroImage = styled.div`
  position: relative;
  background-color: var( --highlightColor );

  img {
    width: 100%;
  }

  ${ ( p: StyledHeroImage ) => p.aspectRatio && css`
    padding-bottom: ${ p.aspectRatio * 100 }%;

    img {
      position: absolute;
      top: 0;
      bottom: 0;
      left: 0;
      right: 0;
      object-fit: cover;
    }
  ` }
`

export default HeroImage