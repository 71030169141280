import React from 'react'
import styled from 'styled-components'

interface ContactLinks {
  links: Array<{
    id: number,
    linkUrl: string,
    linkText: string,
  }>,
}

const ContactLinks = ( { links }: ContactLinks ) => (
  <>
    { links.map( link => (
      <StyledContactLink href={ link.linkUrl } key={ link.id }>
        { link.linkText }
      </StyledContactLink>
    ) ) }
  </>
)

const StyledContactLink = styled.a`
  display: block;
  text-decoration: none;
  color: var( --headlineGrey );
  transition: color .3s ease-out;

  &:hover {
    color: var( --highlightColor );
  }
`

export default ContactLinks