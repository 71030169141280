import { useEffect } from 'react'
import * as ReactGA from 'react-ga'

if ( typeof window !== 'undefined') {
  ReactGA.initialize( 'UA-34217297-1', {
    testMode: window.APP_ENV !== 'production',
    debug: window.APP_ENV !== 'production',
  } )
}

const usePageTracker = ( options?: ReactGA.GaOptions ) => {
  if ( typeof window !== 'undefined') {
    useEffect( () => {
      if ( options ) {
        ReactGA.set( options )
      }

      ReactGA.pageview( location.pathname )
    }, [location.pathname] )
  }
}

export default usePageTracker