import cloneDeep from 'lodash/cloneDeep'
import toWebclientUrl from './toWebclientUrl'

interface Entry {
  title?: string,
  [key: string]: any,
}

interface EntryWithSocial {
  title: string,
  socialTitle: string,
  socialDescription: string | null,
  socialImage?: {
    social: string,
    altText: string,
  },
  socialUrl: string,
  [key: string]: any,
}

const setSocial = ( {
  getTitle = ( entry: Entry ): string => entry.title === 'Fieldwork'
    ? 'Fieldwork — A Design and Technology Studio'
    : `${ entry.title } — Fieldwork — A Design and Technology Studio`,
  getDescription = ( entry: Entry ): null | string => null,
  getImage = ( entry: Entry ): null | string => null,
  getUrl = toWebclientUrl,
} ) => <T>( theEntry: Entry ): T & EntryWithSocial => {
  const entry = cloneDeep( theEntry )
  entry.title = entry.title || ''

  entry.socialTitle = entry.socialTitle || getTitle( entry )
  entry.socialDescription = entry.socialDescription || getDescription( entry )
  entry.socialImage = entry.socialImage && entry.socialImage.length
    ? entry.socialImage[0]
    : getImage( entry )
  entry.socialUrl = getUrl( entry.uri )

  return <T & EntryWithSocial> entry
}

export const setSocialForHome = setSocial( {
  getDescription: entry => entry.runner,
  getUrl: () => toWebclientUrl( '' ),
} )

export const setSocialForAbout = setSocial( {
  getDescription: entry => entry.textBlock.content,
} )

export const setSocialForContact = setSocial( {
  getDescription: entry => 'Contact Fieldwork.',
} )

export const setSocialForServicesPage = setSocial( {
  getDescription: entry => entry.runner,
} )

export const setSocialForProjectsPage = setSocial( {} )

export const setSocialForProject = setSocial( {
  getImage: entry => (
    entry.socialImage && entry.socialImage.length
      ? entry.socialImage[0]
      : entry.mainImage[0]
  ),
} )

export const setSocialForService = setSocial( {
  getDescription: entry => entry.runner,
  getImage: entry => (
    entry.socialImage && entry.socialImage.length
      ? entry.socialImage[0]
      : entry.mainImage[0]
  ),
} )

export default setSocial