import React from 'react'
import styled from 'styled-components'
import withQuery from 'fieldwork/components/withQuery'
import { useGetServiceQuery } from 'fieldwork/graphql/generated/graphql'
import serviceDefaults from 'fieldwork/graphql/queries/serviceDefaults'
import { setSocialForService } from 'fieldwork/helpers/setSocial'
import Head from './Head'
import LayoutSection from './LayoutSection'
import VerticalStack from './VerticalStack'
import HeroCard from './HeroCard'
import LargeCopy from './LargeCopy'
import SimpleGrid from './SimpleGrid'
import CTABanner from './CTABanner'
import NotFound from './NotFound'

export const Service = ( { data = serviceDefaults } ) => {
  const service = data.service[ 0 ]
  const cta = data.cta[ 0 ]

  const {
    socialTitle,
    socialDescription,
    socialImage,
    socialUrl,
  } = setSocialForService( service )

  return (
    <>
      <Head
        title={ socialTitle }
        description={ socialDescription }
        img={ socialImage }
        url={ socialUrl }
      />
      <VerticalStack hero fixedHeaderPush>
        <HeroCard
          title={ service.title }
          runner={ service.runner }
          color={ service.color }
          img={ service.mainImage[ 0 ] }
          blend
          tint={ 0.5 }
        />
      </VerticalStack>
      <LayoutSection centered>
        <VerticalStack hero>
          <LargeCopy light html={ service.introText } />
        </VerticalStack>
        <VerticalStack hero>
          <SimpleGrid
            minColumnWidth={ 300 }
            maxColumns={ 3 }
            gridGap={ 20 }
          >
            { service.textSections.map( textSection => (
              <StyledTextSection key={ textSection.id }>
                <h3>{ textSection.heading }</h3>
                <div dangerouslySetInnerHTML={ { __html: textSection.bodyText } } />
              </StyledTextSection>
            ) ) }
          </SimpleGrid>
        </VerticalStack>
      </LayoutSection>

      <CTABanner
        borderTop
        text={ cta.ctaIntroText }
        link={ cta.ctaPage[ 0 ].uri }
        cta={ cta.ctaButtonText }
      />
    </>
  )
}

const StyledTextSection = styled.div`
  h3 {
    font-weight: 600;
  }
`

interface RoutedService {
  match: {
    params: {
      serviceSlug: string,
    },
  },
}

const RoutedService = ( { match: { params: { serviceSlug } } }: RoutedService ) => {
  return withQuery(
    useGetServiceQuery,
    { variables: { serviceSlug } },
    NotFound,
    ( data ) => data.service && data.service.length > 0
  )( Service )( {} )
}

export default RoutedService
