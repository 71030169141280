import gql from 'graphql-tag';
import * as ApolloReactCommon from '@apollo/react-common';
import * as ApolloReactHooks from '@apollo/react-hooks';
export type Maybe<T> = T | null;
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string,
  String: string,
  Boolean: boolean,
  Int: number,
  Float: number,
  /** The `DateTime` scalar type represents a point in time. */
  DateTime: any,
};






export type About_About_Entry = EntryInterface & ElementInterface & {
   __typename?: 'about_about_Entry',
  /** The id of the entity */
  id?: Maybe<Scalars['ID']>,
  /** The uid of the entity */
  uid?: Maybe<Scalars['String']>,
  /** The element’s title. */
  title?: Maybe<Scalars['String']>,
  /** The element’s slug. */
  slug?: Maybe<Scalars['String']>,
  /** The element’s URI. */
  uri?: Maybe<Scalars['String']>,
  /** Whether the element is enabled or not. */
  enabled?: Maybe<Scalars['Boolean']>,
  /** Whether the element is archived or not. */
  archived?: Maybe<Scalars['Boolean']>,
  /** The ID of the site the element is associated with. */
  siteId?: Maybe<Scalars['Int']>,
  /** The element’s search score, if the `search` parameter was used when querying for the element. */
  searchScore?: Maybe<Scalars['String']>,
  /** Whether the element has been soft-deleted or not. */
  trashed?: Maybe<Scalars['Boolean']>,
  /** The element's status. */
  status?: Maybe<Scalars['String']>,
  /** The date the element was created. */
  dateCreated?: Maybe<Scalars['DateTime']>,
  /** The date the element was last updated. */
  dateUpdated?: Maybe<Scalars['DateTime']>,
  /** The element’s left position within its structure. */
  lft?: Maybe<Scalars['Int']>,
  /** The element’s right position within its structure. */
  rgt?: Maybe<Scalars['Int']>,
  /** The element’s level within its structure */
  level?: Maybe<Scalars['Int']>,
  /** The element’s structure’s root ID */
  root?: Maybe<Scalars['Int']>,
  /** The element’s structure ID. */
  structureId?: Maybe<Scalars['Int']>,
  /** The ID of the author of this entry. */
  authorId?: Maybe<Scalars['Int']>,
  /** The entry's author. */
  author?: Maybe<UserInterface>,
  /** The ID of the section that contains the entry. */
  sectionId?: Maybe<Scalars['Int']>,
  /** The handle of the section that contains the entry. */
  sectionHandle?: Maybe<Scalars['String']>,
  /** The ID of the entry type that contains the entry. */
  typeId?: Maybe<Scalars['Int']>,
  /** The handle of the entry type that contains the entry. */
  typeHandle?: Maybe<Scalars['String']>,
  /** The entry's post date. */
  postDate?: Maybe<Scalars['DateTime']>,
  /** The expiry date of the entry. */
  expiryDate?: Maybe<Scalars['DateTime']>,
  /** The entry’s children, if the section is a structure. Accepts the same arguments as the `entries` query. */
  children?: Maybe<Array<Maybe<EntryInterface>>>,
  /** The entry’s parent, if the section is a structure. */
  parent?: Maybe<EntryInterface>,
  /** The element’s full URL */
  url?: Maybe<Scalars['String']>,
  textBlock?: Maybe<Scalars['String']>,
  people?: Maybe<Array<Maybe<UserInterface>>>,
  socialTitle?: Maybe<Scalars['String']>,
  socialDescription?: Maybe<Scalars['String']>,
  socialImage?: Maybe<Array<Maybe<AssetInterface>>>,
};


export type About_About_EntryChildrenArgs = {
  id?: Maybe<Array<Maybe<Scalars['Int']>>>,
  uid?: Maybe<Array<Maybe<Scalars['String']>>>,
  status?: Maybe<Array<Maybe<Scalars['String']>>>,
  archived?: Maybe<Scalars['Boolean']>,
  trashed?: Maybe<Scalars['Boolean']>,
  site?: Maybe<Array<Maybe<Scalars['String']>>>,
  siteId?: Maybe<Scalars['String']>,
  unique?: Maybe<Scalars['Boolean']>,
  enabledForSite?: Maybe<Scalars['Boolean']>,
  title?: Maybe<Array<Maybe<Scalars['String']>>>,
  slug?: Maybe<Array<Maybe<Scalars['String']>>>,
  uri?: Maybe<Array<Maybe<Scalars['String']>>>,
  search?: Maybe<Scalars['String']>,
  relatedTo?: Maybe<Array<Maybe<Scalars['Int']>>>,
  relatedToAll?: Maybe<Array<Maybe<Scalars['Int']>>>,
  ref?: Maybe<Array<Maybe<Scalars['String']>>>,
  fixedOrder?: Maybe<Scalars['Boolean']>,
  inReverse?: Maybe<Scalars['Boolean']>,
  dateCreated?: Maybe<Scalars['String']>,
  dateUpdated?: Maybe<Scalars['String']>,
  offset?: Maybe<Scalars['Int']>,
  limit?: Maybe<Scalars['Int']>,
  orderBy?: Maybe<Scalars['String']>,
  withStructure?: Maybe<Scalars['Boolean']>,
  structureId?: Maybe<Scalars['Int']>,
  level?: Maybe<Scalars['Int']>,
  hasDescendants?: Maybe<Scalars['Boolean']>,
  ancestorOf?: Maybe<Scalars['Int']>,
  ancestorDist?: Maybe<Scalars['Int']>,
  descendantOf?: Maybe<Scalars['Int']>,
  descendantDist?: Maybe<Scalars['Int']>,
  leaves?: Maybe<Scalars['Boolean']>,
  editable?: Maybe<Scalars['Boolean']>,
  section?: Maybe<Array<Maybe<Scalars['String']>>>,
  sectionId?: Maybe<Array<Maybe<Scalars['Int']>>>,
  type?: Maybe<Array<Maybe<Scalars['String']>>>,
  typeId?: Maybe<Array<Maybe<Scalars['Int']>>>,
  authorId?: Maybe<Array<Maybe<Scalars['Int']>>>,
  authorGroup?: Maybe<Array<Maybe<Scalars['String']>>>,
  postDate?: Maybe<Scalars['String']>,
  before?: Maybe<Scalars['String']>,
  after?: Maybe<Scalars['String']>,
  expiryDate?: Maybe<Scalars['String']>
};


export type About_About_EntryPeopleArgs = {
  id?: Maybe<Array<Maybe<Scalars['Int']>>>,
  uid?: Maybe<Array<Maybe<Scalars['String']>>>,
  status?: Maybe<Array<Maybe<Scalars['String']>>>,
  archived?: Maybe<Scalars['Boolean']>,
  trashed?: Maybe<Scalars['Boolean']>,
  site?: Maybe<Array<Maybe<Scalars['String']>>>,
  siteId?: Maybe<Scalars['String']>,
  unique?: Maybe<Scalars['Boolean']>,
  enabledForSite?: Maybe<Scalars['Boolean']>,
  title?: Maybe<Array<Maybe<Scalars['String']>>>,
  slug?: Maybe<Array<Maybe<Scalars['String']>>>,
  uri?: Maybe<Array<Maybe<Scalars['String']>>>,
  search?: Maybe<Scalars['String']>,
  relatedTo?: Maybe<Array<Maybe<Scalars['Int']>>>,
  relatedToAll?: Maybe<Array<Maybe<Scalars['Int']>>>,
  ref?: Maybe<Array<Maybe<Scalars['String']>>>,
  fixedOrder?: Maybe<Scalars['Boolean']>,
  inReverse?: Maybe<Scalars['Boolean']>,
  dateCreated?: Maybe<Scalars['String']>,
  dateUpdated?: Maybe<Scalars['String']>,
  offset?: Maybe<Scalars['Int']>,
  limit?: Maybe<Scalars['Int']>,
  orderBy?: Maybe<Scalars['String']>,
  email?: Maybe<Array<Maybe<Scalars['String']>>>,
  username?: Maybe<Array<Maybe<Scalars['String']>>>,
  firstName?: Maybe<Array<Maybe<Scalars['String']>>>,
  lastName?: Maybe<Array<Maybe<Scalars['String']>>>
};


export type About_About_EntrySocialImageArgs = {
  id?: Maybe<Array<Maybe<Scalars['Int']>>>,
  uid?: Maybe<Array<Maybe<Scalars['String']>>>,
  status?: Maybe<Array<Maybe<Scalars['String']>>>,
  archived?: Maybe<Scalars['Boolean']>,
  trashed?: Maybe<Scalars['Boolean']>,
  site?: Maybe<Array<Maybe<Scalars['String']>>>,
  siteId?: Maybe<Scalars['String']>,
  unique?: Maybe<Scalars['Boolean']>,
  enabledForSite?: Maybe<Scalars['Boolean']>,
  title?: Maybe<Array<Maybe<Scalars['String']>>>,
  slug?: Maybe<Array<Maybe<Scalars['String']>>>,
  uri?: Maybe<Array<Maybe<Scalars['String']>>>,
  search?: Maybe<Scalars['String']>,
  relatedTo?: Maybe<Array<Maybe<Scalars['Int']>>>,
  relatedToAll?: Maybe<Array<Maybe<Scalars['Int']>>>,
  ref?: Maybe<Array<Maybe<Scalars['String']>>>,
  fixedOrder?: Maybe<Scalars['Boolean']>,
  inReverse?: Maybe<Scalars['Boolean']>,
  dateCreated?: Maybe<Scalars['String']>,
  dateUpdated?: Maybe<Scalars['String']>,
  offset?: Maybe<Scalars['Int']>,
  limit?: Maybe<Scalars['Int']>,
  orderBy?: Maybe<Scalars['String']>,
  volumeId?: Maybe<Array<Maybe<Scalars['Int']>>>,
  volume?: Maybe<Array<Maybe<Scalars['String']>>>,
  folderId?: Maybe<Array<Maybe<Scalars['Int']>>>,
  filename?: Maybe<Array<Maybe<Scalars['String']>>>,
  kind?: Maybe<Array<Maybe<Scalars['String']>>>,
  height?: Maybe<Scalars['String']>,
  width?: Maybe<Scalars['String']>,
  size?: Maybe<Scalars['String']>,
  dateModified?: Maybe<Scalars['String']>,
  includeSubfolders?: Maybe<Scalars['Boolean']>
};

/** This is the interface implemented by all assets. */
export type AssetInterface = {
  /** The id of the entity */
  id?: Maybe<Scalars['ID']>,
  /** The uid of the entity */
  uid?: Maybe<Scalars['String']>,
  /** The element’s title. */
  title?: Maybe<Scalars['String']>,
  /** The element’s slug. */
  slug?: Maybe<Scalars['String']>,
  /** The element’s URI. */
  uri?: Maybe<Scalars['String']>,
  /** Whether the element is enabled or not. */
  enabled?: Maybe<Scalars['Boolean']>,
  /** Whether the element is archived or not. */
  archived?: Maybe<Scalars['Boolean']>,
  /** The ID of the site the element is associated with. */
  siteId?: Maybe<Scalars['Int']>,
  /** The element’s search score, if the `search` parameter was used when querying for the element. */
  searchScore?: Maybe<Scalars['String']>,
  /** Whether the element has been soft-deleted or not. */
  trashed?: Maybe<Scalars['Boolean']>,
  /** The element's status. */
  status?: Maybe<Scalars['String']>,
  /** The date the element was created. */
  dateCreated?: Maybe<Scalars['DateTime']>,
  /** The date the element was last updated. */
  dateUpdated?: Maybe<Scalars['DateTime']>,
  /** The ID of the volume that the asset belongs to. */
  volumeId?: Maybe<Scalars['Int']>,
  /** The ID of the folder that the asset belongs to. */
  folderId?: Maybe<Scalars['Int']>,
  /** The filename of the asset file. */
  filename?: Maybe<Scalars['String']>,
  /** The file extension for the asset file. */
  extension?: Maybe<Scalars['String']>,
  /** Whether a user-defined focal point is set on the asset. */
  hasFocalPoint?: Maybe<Scalars['Boolean']>,
  /** The focal point represented as an array with `x` and `y` keys, or null if it's not an image. */
  focalPoint?: Maybe<Array<Maybe<Scalars['Float']>>>,
  /** The file kind. */
  kind?: Maybe<Scalars['String']>,
  /** The file size in bytes. */
  size?: Maybe<Scalars['String']>,
  /** The height in pixels or null if it's not an image. */
  height?: Maybe<Scalars['Int']>,
  /** The width in pixels or null if it's not an image. */
  width?: Maybe<Scalars['Int']>,
  /** An `<img>` tag based on this asset. */
  img?: Maybe<Scalars['String']>,
  /** The full URL of the asset. This field accepts the same fields as the `transform` directive. */
  url?: Maybe<Scalars['String']>,
  /** The file’s MIME type, if it can be determined. */
  mimeType?: Maybe<Scalars['String']>,
  /** The asset's path in the volume. */
  path?: Maybe<Scalars['String']>,
  /** The date the asset file was last modified. */
  dateModified?: Maybe<Scalars['DateTime']>,
};


/** This is the interface implemented by all assets. */
export type AssetInterfaceUrlArgs = {
  handle?: Maybe<Scalars['String']>,
  transform?: Maybe<Scalars['String']>,
  width?: Maybe<Scalars['Int']>,
  height?: Maybe<Scalars['Int']>,
  mode?: Maybe<Scalars['String']>,
  position?: Maybe<Scalars['String']>,
  interlace?: Maybe<Scalars['String']>,
  quality?: Maybe<Scalars['Int']>,
  format?: Maybe<Scalars['String']>,
  immediately?: Maybe<Scalars['Boolean']>
};

export type Contact_Contact_Entry = EntryInterface & ElementInterface & {
   __typename?: 'contact_contact_Entry',
  /** The id of the entity */
  id?: Maybe<Scalars['ID']>,
  /** The uid of the entity */
  uid?: Maybe<Scalars['String']>,
  /** The element’s title. */
  title?: Maybe<Scalars['String']>,
  /** The element’s slug. */
  slug?: Maybe<Scalars['String']>,
  /** The element’s URI. */
  uri?: Maybe<Scalars['String']>,
  /** Whether the element is enabled or not. */
  enabled?: Maybe<Scalars['Boolean']>,
  /** Whether the element is archived or not. */
  archived?: Maybe<Scalars['Boolean']>,
  /** The ID of the site the element is associated with. */
  siteId?: Maybe<Scalars['Int']>,
  /** The element’s search score, if the `search` parameter was used when querying for the element. */
  searchScore?: Maybe<Scalars['String']>,
  /** Whether the element has been soft-deleted or not. */
  trashed?: Maybe<Scalars['Boolean']>,
  /** The element's status. */
  status?: Maybe<Scalars['String']>,
  /** The date the element was created. */
  dateCreated?: Maybe<Scalars['DateTime']>,
  /** The date the element was last updated. */
  dateUpdated?: Maybe<Scalars['DateTime']>,
  /** The element’s left position within its structure. */
  lft?: Maybe<Scalars['Int']>,
  /** The element’s right position within its structure. */
  rgt?: Maybe<Scalars['Int']>,
  /** The element’s level within its structure */
  level?: Maybe<Scalars['Int']>,
  /** The element’s structure’s root ID */
  root?: Maybe<Scalars['Int']>,
  /** The element’s structure ID. */
  structureId?: Maybe<Scalars['Int']>,
  /** The ID of the author of this entry. */
  authorId?: Maybe<Scalars['Int']>,
  /** The entry's author. */
  author?: Maybe<UserInterface>,
  /** The ID of the section that contains the entry. */
  sectionId?: Maybe<Scalars['Int']>,
  /** The handle of the section that contains the entry. */
  sectionHandle?: Maybe<Scalars['String']>,
  /** The ID of the entry type that contains the entry. */
  typeId?: Maybe<Scalars['Int']>,
  /** The handle of the entry type that contains the entry. */
  typeHandle?: Maybe<Scalars['String']>,
  /** The entry's post date. */
  postDate?: Maybe<Scalars['DateTime']>,
  /** The expiry date of the entry. */
  expiryDate?: Maybe<Scalars['DateTime']>,
  /** The entry’s children, if the section is a structure. Accepts the same arguments as the `entries` query. */
  children?: Maybe<Array<Maybe<EntryInterface>>>,
  /** The entry’s parent, if the section is a structure. */
  parent?: Maybe<EntryInterface>,
  /** The element’s full URL */
  url?: Maybe<Scalars['String']>,
  mapLatitude?: Maybe<Scalars['String']>,
  mapLongitude?: Maybe<Scalars['String']>,
  mapZoom?: Maybe<Scalars['String']>,
  links?: Maybe<Array<Maybe<Links_MatrixField>>>,
  contacts?: Maybe<Array<Maybe<Contacts_MatrixField>>>,
  socialTitle?: Maybe<Scalars['String']>,
  socialDescription?: Maybe<Scalars['String']>,
  socialImage?: Maybe<Array<Maybe<AssetInterface>>>,
};


export type Contact_Contact_EntryChildrenArgs = {
  id?: Maybe<Array<Maybe<Scalars['Int']>>>,
  uid?: Maybe<Array<Maybe<Scalars['String']>>>,
  status?: Maybe<Array<Maybe<Scalars['String']>>>,
  archived?: Maybe<Scalars['Boolean']>,
  trashed?: Maybe<Scalars['Boolean']>,
  site?: Maybe<Array<Maybe<Scalars['String']>>>,
  siteId?: Maybe<Scalars['String']>,
  unique?: Maybe<Scalars['Boolean']>,
  enabledForSite?: Maybe<Scalars['Boolean']>,
  title?: Maybe<Array<Maybe<Scalars['String']>>>,
  slug?: Maybe<Array<Maybe<Scalars['String']>>>,
  uri?: Maybe<Array<Maybe<Scalars['String']>>>,
  search?: Maybe<Scalars['String']>,
  relatedTo?: Maybe<Array<Maybe<Scalars['Int']>>>,
  relatedToAll?: Maybe<Array<Maybe<Scalars['Int']>>>,
  ref?: Maybe<Array<Maybe<Scalars['String']>>>,
  fixedOrder?: Maybe<Scalars['Boolean']>,
  inReverse?: Maybe<Scalars['Boolean']>,
  dateCreated?: Maybe<Scalars['String']>,
  dateUpdated?: Maybe<Scalars['String']>,
  offset?: Maybe<Scalars['Int']>,
  limit?: Maybe<Scalars['Int']>,
  orderBy?: Maybe<Scalars['String']>,
  withStructure?: Maybe<Scalars['Boolean']>,
  structureId?: Maybe<Scalars['Int']>,
  level?: Maybe<Scalars['Int']>,
  hasDescendants?: Maybe<Scalars['Boolean']>,
  ancestorOf?: Maybe<Scalars['Int']>,
  ancestorDist?: Maybe<Scalars['Int']>,
  descendantOf?: Maybe<Scalars['Int']>,
  descendantDist?: Maybe<Scalars['Int']>,
  leaves?: Maybe<Scalars['Boolean']>,
  editable?: Maybe<Scalars['Boolean']>,
  section?: Maybe<Array<Maybe<Scalars['String']>>>,
  sectionId?: Maybe<Array<Maybe<Scalars['Int']>>>,
  type?: Maybe<Array<Maybe<Scalars['String']>>>,
  typeId?: Maybe<Array<Maybe<Scalars['Int']>>>,
  authorId?: Maybe<Array<Maybe<Scalars['Int']>>>,
  authorGroup?: Maybe<Array<Maybe<Scalars['String']>>>,
  postDate?: Maybe<Scalars['String']>,
  before?: Maybe<Scalars['String']>,
  after?: Maybe<Scalars['String']>,
  expiryDate?: Maybe<Scalars['String']>
};


export type Contact_Contact_EntryLinksArgs = {
  id?: Maybe<Array<Maybe<Scalars['Int']>>>,
  uid?: Maybe<Array<Maybe<Scalars['String']>>>,
  status?: Maybe<Array<Maybe<Scalars['String']>>>,
  archived?: Maybe<Scalars['Boolean']>,
  trashed?: Maybe<Scalars['Boolean']>,
  site?: Maybe<Array<Maybe<Scalars['String']>>>,
  siteId?: Maybe<Scalars['String']>,
  unique?: Maybe<Scalars['Boolean']>,
  enabledForSite?: Maybe<Scalars['Boolean']>,
  title?: Maybe<Array<Maybe<Scalars['String']>>>,
  slug?: Maybe<Array<Maybe<Scalars['String']>>>,
  uri?: Maybe<Array<Maybe<Scalars['String']>>>,
  search?: Maybe<Scalars['String']>,
  relatedTo?: Maybe<Array<Maybe<Scalars['Int']>>>,
  relatedToAll?: Maybe<Array<Maybe<Scalars['Int']>>>,
  ref?: Maybe<Array<Maybe<Scalars['String']>>>,
  fixedOrder?: Maybe<Scalars['Boolean']>,
  inReverse?: Maybe<Scalars['Boolean']>,
  dateCreated?: Maybe<Scalars['String']>,
  dateUpdated?: Maybe<Scalars['String']>,
  offset?: Maybe<Scalars['Int']>,
  limit?: Maybe<Scalars['Int']>,
  orderBy?: Maybe<Scalars['String']>,
  fieldId?: Maybe<Array<Maybe<Scalars['Int']>>>,
  ownerId?: Maybe<Array<Maybe<Scalars['String']>>>,
  typeId?: Maybe<Array<Maybe<Scalars['Int']>>>,
  type?: Maybe<Array<Maybe<Scalars['String']>>>
};


export type Contact_Contact_EntryContactsArgs = {
  id?: Maybe<Array<Maybe<Scalars['Int']>>>,
  uid?: Maybe<Array<Maybe<Scalars['String']>>>,
  status?: Maybe<Array<Maybe<Scalars['String']>>>,
  archived?: Maybe<Scalars['Boolean']>,
  trashed?: Maybe<Scalars['Boolean']>,
  site?: Maybe<Array<Maybe<Scalars['String']>>>,
  siteId?: Maybe<Scalars['String']>,
  unique?: Maybe<Scalars['Boolean']>,
  enabledForSite?: Maybe<Scalars['Boolean']>,
  title?: Maybe<Array<Maybe<Scalars['String']>>>,
  slug?: Maybe<Array<Maybe<Scalars['String']>>>,
  uri?: Maybe<Array<Maybe<Scalars['String']>>>,
  search?: Maybe<Scalars['String']>,
  relatedTo?: Maybe<Array<Maybe<Scalars['Int']>>>,
  relatedToAll?: Maybe<Array<Maybe<Scalars['Int']>>>,
  ref?: Maybe<Array<Maybe<Scalars['String']>>>,
  fixedOrder?: Maybe<Scalars['Boolean']>,
  inReverse?: Maybe<Scalars['Boolean']>,
  dateCreated?: Maybe<Scalars['String']>,
  dateUpdated?: Maybe<Scalars['String']>,
  offset?: Maybe<Scalars['Int']>,
  limit?: Maybe<Scalars['Int']>,
  orderBy?: Maybe<Scalars['String']>,
  fieldId?: Maybe<Array<Maybe<Scalars['Int']>>>,
  ownerId?: Maybe<Array<Maybe<Scalars['String']>>>,
  typeId?: Maybe<Array<Maybe<Scalars['Int']>>>,
  type?: Maybe<Array<Maybe<Scalars['String']>>>
};


export type Contact_Contact_EntrySocialImageArgs = {
  id?: Maybe<Array<Maybe<Scalars['Int']>>>,
  uid?: Maybe<Array<Maybe<Scalars['String']>>>,
  status?: Maybe<Array<Maybe<Scalars['String']>>>,
  archived?: Maybe<Scalars['Boolean']>,
  trashed?: Maybe<Scalars['Boolean']>,
  site?: Maybe<Array<Maybe<Scalars['String']>>>,
  siteId?: Maybe<Scalars['String']>,
  unique?: Maybe<Scalars['Boolean']>,
  enabledForSite?: Maybe<Scalars['Boolean']>,
  title?: Maybe<Array<Maybe<Scalars['String']>>>,
  slug?: Maybe<Array<Maybe<Scalars['String']>>>,
  uri?: Maybe<Array<Maybe<Scalars['String']>>>,
  search?: Maybe<Scalars['String']>,
  relatedTo?: Maybe<Array<Maybe<Scalars['Int']>>>,
  relatedToAll?: Maybe<Array<Maybe<Scalars['Int']>>>,
  ref?: Maybe<Array<Maybe<Scalars['String']>>>,
  fixedOrder?: Maybe<Scalars['Boolean']>,
  inReverse?: Maybe<Scalars['Boolean']>,
  dateCreated?: Maybe<Scalars['String']>,
  dateUpdated?: Maybe<Scalars['String']>,
  offset?: Maybe<Scalars['Int']>,
  limit?: Maybe<Scalars['Int']>,
  orderBy?: Maybe<Scalars['String']>,
  volumeId?: Maybe<Array<Maybe<Scalars['Int']>>>,
  volume?: Maybe<Array<Maybe<Scalars['String']>>>,
  folderId?: Maybe<Array<Maybe<Scalars['Int']>>>,
  filename?: Maybe<Array<Maybe<Scalars['String']>>>,
  kind?: Maybe<Array<Maybe<Scalars['String']>>>,
  height?: Maybe<Scalars['String']>,
  width?: Maybe<Scalars['String']>,
  size?: Maybe<Scalars['String']>,
  dateModified?: Maybe<Scalars['String']>,
  includeSubfolders?: Maybe<Scalars['Boolean']>
};

export type Contacts_Contact_BlockType = MatrixBlockInterface & ElementInterface & {
   __typename?: 'contacts_contact_BlockType',
  /** The id of the entity */
  id?: Maybe<Scalars['ID']>,
  /** The uid of the entity */
  uid?: Maybe<Scalars['String']>,
  /** The element’s title. */
  title?: Maybe<Scalars['String']>,
  /** The element’s slug. */
  slug?: Maybe<Scalars['String']>,
  /** The element’s URI. */
  uri?: Maybe<Scalars['String']>,
  /** Whether the element is enabled or not. */
  enabled?: Maybe<Scalars['Boolean']>,
  /** Whether the element is archived or not. */
  archived?: Maybe<Scalars['Boolean']>,
  /** The ID of the site the element is associated with. */
  siteId?: Maybe<Scalars['Int']>,
  /** The element’s search score, if the `search` parameter was used when querying for the element. */
  searchScore?: Maybe<Scalars['String']>,
  /** Whether the element has been soft-deleted or not. */
  trashed?: Maybe<Scalars['Boolean']>,
  /** The element's status. */
  status?: Maybe<Scalars['String']>,
  /** The date the element was created. */
  dateCreated?: Maybe<Scalars['DateTime']>,
  /** The date the element was last updated. */
  dateUpdated?: Maybe<Scalars['DateTime']>,
  /** The ID of the field that owns the matrix block. */
  fieldId?: Maybe<Scalars['Int']>,
  /** The ID of the element that owns the matrix block. */
  ownerId?: Maybe<Scalars['Int']>,
  /** The ID of the matrix block's type. */
  typeId?: Maybe<Scalars['Int']>,
  /** The handle of the matrix block's type. */
  typeHandle?: Maybe<Scalars['String']>,
  /** The sort order of the matrix block within the owner element field. */
  sortOrder?: Maybe<Scalars['Int']>,
  contactTitle?: Maybe<Scalars['String']>,
  description?: Maybe<Scalars['String']>,
  email?: Maybe<Scalars['String']>,
};

export type Contacts_MatrixField = Contacts_Contact_BlockType;

export type Cta_GlobalSet = GlobalSetInterface & ElementInterface & {
   __typename?: 'cta_GlobalSet',
  /** The id of the entity */
  id?: Maybe<Scalars['ID']>,
  /** The uid of the entity */
  uid?: Maybe<Scalars['String']>,
  /** The element’s title. */
  title?: Maybe<Scalars['String']>,
  /** The element’s slug. */
  slug?: Maybe<Scalars['String']>,
  /** The element’s URI. */
  uri?: Maybe<Scalars['String']>,
  /** Whether the element is enabled or not. */
  enabled?: Maybe<Scalars['Boolean']>,
  /** Whether the element is archived or not. */
  archived?: Maybe<Scalars['Boolean']>,
  /** The ID of the site the element is associated with. */
  siteId?: Maybe<Scalars['Int']>,
  /** The element’s search score, if the `search` parameter was used when querying for the element. */
  searchScore?: Maybe<Scalars['String']>,
  /** Whether the element has been soft-deleted or not. */
  trashed?: Maybe<Scalars['Boolean']>,
  /** The element's status. */
  status?: Maybe<Scalars['String']>,
  /** The date the element was created. */
  dateCreated?: Maybe<Scalars['DateTime']>,
  /** The date the element was last updated. */
  dateUpdated?: Maybe<Scalars['DateTime']>,
  /** The name of the global set. */
  name?: Maybe<Scalars['String']>,
  /** The handle of the global set. */
  handle?: Maybe<Scalars['String']>,
  ctaIntroText?: Maybe<Scalars['String']>,
  ctaButtonText?: Maybe<Scalars['String']>,
  ctaPage?: Maybe<Array<Maybe<EntryInterface>>>,
};


export type Cta_GlobalSetCtaPageArgs = {
  id?: Maybe<Array<Maybe<Scalars['Int']>>>,
  uid?: Maybe<Array<Maybe<Scalars['String']>>>,
  status?: Maybe<Array<Maybe<Scalars['String']>>>,
  archived?: Maybe<Scalars['Boolean']>,
  trashed?: Maybe<Scalars['Boolean']>,
  site?: Maybe<Array<Maybe<Scalars['String']>>>,
  siteId?: Maybe<Scalars['String']>,
  unique?: Maybe<Scalars['Boolean']>,
  enabledForSite?: Maybe<Scalars['Boolean']>,
  title?: Maybe<Array<Maybe<Scalars['String']>>>,
  slug?: Maybe<Array<Maybe<Scalars['String']>>>,
  uri?: Maybe<Array<Maybe<Scalars['String']>>>,
  search?: Maybe<Scalars['String']>,
  relatedTo?: Maybe<Array<Maybe<Scalars['Int']>>>,
  relatedToAll?: Maybe<Array<Maybe<Scalars['Int']>>>,
  ref?: Maybe<Array<Maybe<Scalars['String']>>>,
  fixedOrder?: Maybe<Scalars['Boolean']>,
  inReverse?: Maybe<Scalars['Boolean']>,
  dateCreated?: Maybe<Scalars['String']>,
  dateUpdated?: Maybe<Scalars['String']>,
  offset?: Maybe<Scalars['Int']>,
  limit?: Maybe<Scalars['Int']>,
  orderBy?: Maybe<Scalars['String']>,
  withStructure?: Maybe<Scalars['Boolean']>,
  structureId?: Maybe<Scalars['Int']>,
  level?: Maybe<Scalars['Int']>,
  hasDescendants?: Maybe<Scalars['Boolean']>,
  ancestorOf?: Maybe<Scalars['Int']>,
  ancestorDist?: Maybe<Scalars['Int']>,
  descendantOf?: Maybe<Scalars['Int']>,
  descendantDist?: Maybe<Scalars['Int']>,
  leaves?: Maybe<Scalars['Boolean']>,
  editable?: Maybe<Scalars['Boolean']>,
  section?: Maybe<Array<Maybe<Scalars['String']>>>,
  sectionId?: Maybe<Array<Maybe<Scalars['Int']>>>,
  type?: Maybe<Array<Maybe<Scalars['String']>>>,
  typeId?: Maybe<Array<Maybe<Scalars['Int']>>>,
  authorId?: Maybe<Array<Maybe<Scalars['Int']>>>,
  authorGroup?: Maybe<Array<Maybe<Scalars['String']>>>,
  postDate?: Maybe<Scalars['String']>,
  before?: Maybe<Scalars['String']>,
  after?: Maybe<Scalars['String']>,
  expiryDate?: Maybe<Scalars['String']>
};


export type Element = ElementInterface & {
   __typename?: 'Element',
  /** The id of the entity */
  id?: Maybe<Scalars['ID']>,
  /** The uid of the entity */
  uid?: Maybe<Scalars['String']>,
  /** The element’s title. */
  title?: Maybe<Scalars['String']>,
  /** The element’s slug. */
  slug?: Maybe<Scalars['String']>,
  /** The element’s URI. */
  uri?: Maybe<Scalars['String']>,
  /** Whether the element is enabled or not. */
  enabled?: Maybe<Scalars['Boolean']>,
  /** Whether the element is archived or not. */
  archived?: Maybe<Scalars['Boolean']>,
  /** The ID of the site the element is associated with. */
  siteId?: Maybe<Scalars['Int']>,
  /** The element’s search score, if the `search` parameter was used when querying for the element. */
  searchScore?: Maybe<Scalars['String']>,
  /** Whether the element has been soft-deleted or not. */
  trashed?: Maybe<Scalars['Boolean']>,
  /** The element's status. */
  status?: Maybe<Scalars['String']>,
  /** The date the element was created. */
  dateCreated?: Maybe<Scalars['DateTime']>,
  /** The date the element was last updated. */
  dateUpdated?: Maybe<Scalars['DateTime']>,
};

/** This is the interface implemented by all elements. */
export type ElementInterface = {
  /** The id of the entity */
  id?: Maybe<Scalars['ID']>,
  /** The uid of the entity */
  uid?: Maybe<Scalars['String']>,
  /** The element’s title. */
  title?: Maybe<Scalars['String']>,
  /** The element’s slug. */
  slug?: Maybe<Scalars['String']>,
  /** The element’s URI. */
  uri?: Maybe<Scalars['String']>,
  /** Whether the element is enabled or not. */
  enabled?: Maybe<Scalars['Boolean']>,
  /** Whether the element is archived or not. */
  archived?: Maybe<Scalars['Boolean']>,
  /** The ID of the site the element is associated with. */
  siteId?: Maybe<Scalars['Int']>,
  /** The element’s search score, if the `search` parameter was used when querying for the element. */
  searchScore?: Maybe<Scalars['String']>,
  /** Whether the element has been soft-deleted or not. */
  trashed?: Maybe<Scalars['Boolean']>,
  /** The element's status. */
  status?: Maybe<Scalars['String']>,
  /** The date the element was created. */
  dateCreated?: Maybe<Scalars['DateTime']>,
  /** The date the element was last updated. */
  dateUpdated?: Maybe<Scalars['DateTime']>,
};

/** This is the interface implemented by all entries. */
export type EntryInterface = {
  /** The id of the entity */
  id?: Maybe<Scalars['ID']>,
  /** The uid of the entity */
  uid?: Maybe<Scalars['String']>,
  /** The element’s title. */
  title?: Maybe<Scalars['String']>,
  /** The element’s slug. */
  slug?: Maybe<Scalars['String']>,
  /** The element’s URI. */
  uri?: Maybe<Scalars['String']>,
  /** Whether the element is enabled or not. */
  enabled?: Maybe<Scalars['Boolean']>,
  /** Whether the element is archived or not. */
  archived?: Maybe<Scalars['Boolean']>,
  /** The ID of the site the element is associated with. */
  siteId?: Maybe<Scalars['Int']>,
  /** The element’s search score, if the `search` parameter was used when querying for the element. */
  searchScore?: Maybe<Scalars['String']>,
  /** Whether the element has been soft-deleted or not. */
  trashed?: Maybe<Scalars['Boolean']>,
  /** The element's status. */
  status?: Maybe<Scalars['String']>,
  /** The date the element was created. */
  dateCreated?: Maybe<Scalars['DateTime']>,
  /** The date the element was last updated. */
  dateUpdated?: Maybe<Scalars['DateTime']>,
  /** The element’s left position within its structure. */
  lft?: Maybe<Scalars['Int']>,
  /** The element’s right position within its structure. */
  rgt?: Maybe<Scalars['Int']>,
  /** The element’s level within its structure */
  level?: Maybe<Scalars['Int']>,
  /** The element’s structure’s root ID */
  root?: Maybe<Scalars['Int']>,
  /** The element’s structure ID. */
  structureId?: Maybe<Scalars['Int']>,
  /** The ID of the author of this entry. */
  authorId?: Maybe<Scalars['Int']>,
  /** The entry's author. */
  author?: Maybe<UserInterface>,
  /** The ID of the section that contains the entry. */
  sectionId?: Maybe<Scalars['Int']>,
  /** The handle of the section that contains the entry. */
  sectionHandle?: Maybe<Scalars['String']>,
  /** The ID of the entry type that contains the entry. */
  typeId?: Maybe<Scalars['Int']>,
  /** The handle of the entry type that contains the entry. */
  typeHandle?: Maybe<Scalars['String']>,
  /** The entry's post date. */
  postDate?: Maybe<Scalars['DateTime']>,
  /** The expiry date of the entry. */
  expiryDate?: Maybe<Scalars['DateTime']>,
  /** The entry’s children, if the section is a structure. Accepts the same arguments as the `entries` query. */
  children?: Maybe<Array<Maybe<EntryInterface>>>,
  /** The entry’s parent, if the section is a structure. */
  parent?: Maybe<EntryInterface>,
  /** The element’s full URL */
  url?: Maybe<Scalars['String']>,
};


/** This is the interface implemented by all entries. */
export type EntryInterfaceChildrenArgs = {
  id?: Maybe<Array<Maybe<Scalars['Int']>>>,
  uid?: Maybe<Array<Maybe<Scalars['String']>>>,
  status?: Maybe<Array<Maybe<Scalars['String']>>>,
  archived?: Maybe<Scalars['Boolean']>,
  trashed?: Maybe<Scalars['Boolean']>,
  site?: Maybe<Array<Maybe<Scalars['String']>>>,
  siteId?: Maybe<Scalars['String']>,
  unique?: Maybe<Scalars['Boolean']>,
  enabledForSite?: Maybe<Scalars['Boolean']>,
  title?: Maybe<Array<Maybe<Scalars['String']>>>,
  slug?: Maybe<Array<Maybe<Scalars['String']>>>,
  uri?: Maybe<Array<Maybe<Scalars['String']>>>,
  search?: Maybe<Scalars['String']>,
  relatedTo?: Maybe<Array<Maybe<Scalars['Int']>>>,
  relatedToAll?: Maybe<Array<Maybe<Scalars['Int']>>>,
  ref?: Maybe<Array<Maybe<Scalars['String']>>>,
  fixedOrder?: Maybe<Scalars['Boolean']>,
  inReverse?: Maybe<Scalars['Boolean']>,
  dateCreated?: Maybe<Scalars['String']>,
  dateUpdated?: Maybe<Scalars['String']>,
  offset?: Maybe<Scalars['Int']>,
  limit?: Maybe<Scalars['Int']>,
  orderBy?: Maybe<Scalars['String']>,
  withStructure?: Maybe<Scalars['Boolean']>,
  structureId?: Maybe<Scalars['Int']>,
  level?: Maybe<Scalars['Int']>,
  hasDescendants?: Maybe<Scalars['Boolean']>,
  ancestorOf?: Maybe<Scalars['Int']>,
  ancestorDist?: Maybe<Scalars['Int']>,
  descendantOf?: Maybe<Scalars['Int']>,
  descendantDist?: Maybe<Scalars['Int']>,
  leaves?: Maybe<Scalars['Boolean']>,
  editable?: Maybe<Scalars['Boolean']>,
  section?: Maybe<Array<Maybe<Scalars['String']>>>,
  sectionId?: Maybe<Array<Maybe<Scalars['Int']>>>,
  type?: Maybe<Array<Maybe<Scalars['String']>>>,
  typeId?: Maybe<Array<Maybe<Scalars['Int']>>>,
  authorId?: Maybe<Array<Maybe<Scalars['Int']>>>,
  authorGroup?: Maybe<Array<Maybe<Scalars['String']>>>,
  postDate?: Maybe<Scalars['String']>,
  before?: Maybe<Scalars['String']>,
  after?: Maybe<Scalars['String']>,
  expiryDate?: Maybe<Scalars['String']>
};

export type Error404_Error404_Entry = EntryInterface & ElementInterface & {
   __typename?: 'error404_error404_Entry',
  /** The id of the entity */
  id?: Maybe<Scalars['ID']>,
  /** The uid of the entity */
  uid?: Maybe<Scalars['String']>,
  /** The element’s title. */
  title?: Maybe<Scalars['String']>,
  /** The element’s slug. */
  slug?: Maybe<Scalars['String']>,
  /** The element’s URI. */
  uri?: Maybe<Scalars['String']>,
  /** Whether the element is enabled or not. */
  enabled?: Maybe<Scalars['Boolean']>,
  /** Whether the element is archived or not. */
  archived?: Maybe<Scalars['Boolean']>,
  /** The ID of the site the element is associated with. */
  siteId?: Maybe<Scalars['Int']>,
  /** The element’s search score, if the `search` parameter was used when querying for the element. */
  searchScore?: Maybe<Scalars['String']>,
  /** Whether the element has been soft-deleted or not. */
  trashed?: Maybe<Scalars['Boolean']>,
  /** The element's status. */
  status?: Maybe<Scalars['String']>,
  /** The date the element was created. */
  dateCreated?: Maybe<Scalars['DateTime']>,
  /** The date the element was last updated. */
  dateUpdated?: Maybe<Scalars['DateTime']>,
  /** The element’s left position within its structure. */
  lft?: Maybe<Scalars['Int']>,
  /** The element’s right position within its structure. */
  rgt?: Maybe<Scalars['Int']>,
  /** The element’s level within its structure */
  level?: Maybe<Scalars['Int']>,
  /** The element’s structure’s root ID */
  root?: Maybe<Scalars['Int']>,
  /** The element’s structure ID. */
  structureId?: Maybe<Scalars['Int']>,
  /** The ID of the author of this entry. */
  authorId?: Maybe<Scalars['Int']>,
  /** The entry's author. */
  author?: Maybe<UserInterface>,
  /** The ID of the section that contains the entry. */
  sectionId?: Maybe<Scalars['Int']>,
  /** The handle of the section that contains the entry. */
  sectionHandle?: Maybe<Scalars['String']>,
  /** The ID of the entry type that contains the entry. */
  typeId?: Maybe<Scalars['Int']>,
  /** The handle of the entry type that contains the entry. */
  typeHandle?: Maybe<Scalars['String']>,
  /** The entry's post date. */
  postDate?: Maybe<Scalars['DateTime']>,
  /** The expiry date of the entry. */
  expiryDate?: Maybe<Scalars['DateTime']>,
  /** The entry’s children, if the section is a structure. Accepts the same arguments as the `entries` query. */
  children?: Maybe<Array<Maybe<EntryInterface>>>,
  /** The entry’s parent, if the section is a structure. */
  parent?: Maybe<EntryInterface>,
  /** The element’s full URL */
  url?: Maybe<Scalars['String']>,
  textBlock?: Maybe<Scalars['String']>,
};


export type Error404_Error404_EntryChildrenArgs = {
  id?: Maybe<Array<Maybe<Scalars['Int']>>>,
  uid?: Maybe<Array<Maybe<Scalars['String']>>>,
  status?: Maybe<Array<Maybe<Scalars['String']>>>,
  archived?: Maybe<Scalars['Boolean']>,
  trashed?: Maybe<Scalars['Boolean']>,
  site?: Maybe<Array<Maybe<Scalars['String']>>>,
  siteId?: Maybe<Scalars['String']>,
  unique?: Maybe<Scalars['Boolean']>,
  enabledForSite?: Maybe<Scalars['Boolean']>,
  title?: Maybe<Array<Maybe<Scalars['String']>>>,
  slug?: Maybe<Array<Maybe<Scalars['String']>>>,
  uri?: Maybe<Array<Maybe<Scalars['String']>>>,
  search?: Maybe<Scalars['String']>,
  relatedTo?: Maybe<Array<Maybe<Scalars['Int']>>>,
  relatedToAll?: Maybe<Array<Maybe<Scalars['Int']>>>,
  ref?: Maybe<Array<Maybe<Scalars['String']>>>,
  fixedOrder?: Maybe<Scalars['Boolean']>,
  inReverse?: Maybe<Scalars['Boolean']>,
  dateCreated?: Maybe<Scalars['String']>,
  dateUpdated?: Maybe<Scalars['String']>,
  offset?: Maybe<Scalars['Int']>,
  limit?: Maybe<Scalars['Int']>,
  orderBy?: Maybe<Scalars['String']>,
  withStructure?: Maybe<Scalars['Boolean']>,
  structureId?: Maybe<Scalars['Int']>,
  level?: Maybe<Scalars['Int']>,
  hasDescendants?: Maybe<Scalars['Boolean']>,
  ancestorOf?: Maybe<Scalars['Int']>,
  ancestorDist?: Maybe<Scalars['Int']>,
  descendantOf?: Maybe<Scalars['Int']>,
  descendantDist?: Maybe<Scalars['Int']>,
  leaves?: Maybe<Scalars['Boolean']>,
  editable?: Maybe<Scalars['Boolean']>,
  section?: Maybe<Array<Maybe<Scalars['String']>>>,
  sectionId?: Maybe<Array<Maybe<Scalars['Int']>>>,
  type?: Maybe<Array<Maybe<Scalars['String']>>>,
  typeId?: Maybe<Array<Maybe<Scalars['Int']>>>,
  authorId?: Maybe<Array<Maybe<Scalars['Int']>>>,
  authorGroup?: Maybe<Array<Maybe<Scalars['String']>>>,
  postDate?: Maybe<Scalars['String']>,
  before?: Maybe<Scalars['String']>,
  after?: Maybe<Scalars['String']>,
  expiryDate?: Maybe<Scalars['String']>
};

export type Fieldwork_GlobalSet = GlobalSetInterface & ElementInterface & {
   __typename?: 'fieldwork_GlobalSet',
  /** The id of the entity */
  id?: Maybe<Scalars['ID']>,
  /** The uid of the entity */
  uid?: Maybe<Scalars['String']>,
  /** The element’s title. */
  title?: Maybe<Scalars['String']>,
  /** The element’s slug. */
  slug?: Maybe<Scalars['String']>,
  /** The element’s URI. */
  uri?: Maybe<Scalars['String']>,
  /** Whether the element is enabled or not. */
  enabled?: Maybe<Scalars['Boolean']>,
  /** Whether the element is archived or not. */
  archived?: Maybe<Scalars['Boolean']>,
  /** The ID of the site the element is associated with. */
  siteId?: Maybe<Scalars['Int']>,
  /** The element’s search score, if the `search` parameter was used when querying for the element. */
  searchScore?: Maybe<Scalars['String']>,
  /** Whether the element has been soft-deleted or not. */
  trashed?: Maybe<Scalars['Boolean']>,
  /** The element's status. */
  status?: Maybe<Scalars['String']>,
  /** The date the element was created. */
  dateCreated?: Maybe<Scalars['DateTime']>,
  /** The date the element was last updated. */
  dateUpdated?: Maybe<Scalars['DateTime']>,
  /** The name of the global set. */
  name?: Maybe<Scalars['String']>,
  /** The handle of the global set. */
  handle?: Maybe<Scalars['String']>,
  address?: Maybe<Scalars['String']>,
  email?: Maybe<Scalars['String']>,
  phone?: Maybe<Scalars['String']>,
  social?: Maybe<Array<Maybe<Social_MatrixField>>>,
};


export type Fieldwork_GlobalSetSocialArgs = {
  id?: Maybe<Array<Maybe<Scalars['Int']>>>,
  uid?: Maybe<Array<Maybe<Scalars['String']>>>,
  status?: Maybe<Array<Maybe<Scalars['String']>>>,
  archived?: Maybe<Scalars['Boolean']>,
  trashed?: Maybe<Scalars['Boolean']>,
  site?: Maybe<Array<Maybe<Scalars['String']>>>,
  siteId?: Maybe<Scalars['String']>,
  unique?: Maybe<Scalars['Boolean']>,
  enabledForSite?: Maybe<Scalars['Boolean']>,
  title?: Maybe<Array<Maybe<Scalars['String']>>>,
  slug?: Maybe<Array<Maybe<Scalars['String']>>>,
  uri?: Maybe<Array<Maybe<Scalars['String']>>>,
  search?: Maybe<Scalars['String']>,
  relatedTo?: Maybe<Array<Maybe<Scalars['Int']>>>,
  relatedToAll?: Maybe<Array<Maybe<Scalars['Int']>>>,
  ref?: Maybe<Array<Maybe<Scalars['String']>>>,
  fixedOrder?: Maybe<Scalars['Boolean']>,
  inReverse?: Maybe<Scalars['Boolean']>,
  dateCreated?: Maybe<Scalars['String']>,
  dateUpdated?: Maybe<Scalars['String']>,
  offset?: Maybe<Scalars['Int']>,
  limit?: Maybe<Scalars['Int']>,
  orderBy?: Maybe<Scalars['String']>,
  fieldId?: Maybe<Array<Maybe<Scalars['Int']>>>,
  ownerId?: Maybe<Array<Maybe<Scalars['String']>>>,
  typeId?: Maybe<Array<Maybe<Scalars['Int']>>>,
  type?: Maybe<Array<Maybe<Scalars['String']>>>
};

/** This is the interface implemented by all global sets. */
export type GlobalSetInterface = {
  /** The id of the entity */
  id?: Maybe<Scalars['ID']>,
  /** The uid of the entity */
  uid?: Maybe<Scalars['String']>,
  /** The element’s title. */
  title?: Maybe<Scalars['String']>,
  /** The element’s slug. */
  slug?: Maybe<Scalars['String']>,
  /** The element’s URI. */
  uri?: Maybe<Scalars['String']>,
  /** Whether the element is enabled or not. */
  enabled?: Maybe<Scalars['Boolean']>,
  /** Whether the element is archived or not. */
  archived?: Maybe<Scalars['Boolean']>,
  /** The ID of the site the element is associated with. */
  siteId?: Maybe<Scalars['Int']>,
  /** The element’s search score, if the `search` parameter was used when querying for the element. */
  searchScore?: Maybe<Scalars['String']>,
  /** Whether the element has been soft-deleted or not. */
  trashed?: Maybe<Scalars['Boolean']>,
  /** The element's status. */
  status?: Maybe<Scalars['String']>,
  /** The date the element was created. */
  dateCreated?: Maybe<Scalars['DateTime']>,
  /** The date the element was last updated. */
  dateUpdated?: Maybe<Scalars['DateTime']>,
  /** The name of the global set. */
  name?: Maybe<Scalars['String']>,
  /** The handle of the global set. */
  handle?: Maybe<Scalars['String']>,
};

export type HighlightColors_GlobalSet = GlobalSetInterface & ElementInterface & {
   __typename?: 'highlightColors_GlobalSet',
  /** The id of the entity */
  id?: Maybe<Scalars['ID']>,
  /** The uid of the entity */
  uid?: Maybe<Scalars['String']>,
  /** The element’s title. */
  title?: Maybe<Scalars['String']>,
  /** The element’s slug. */
  slug?: Maybe<Scalars['String']>,
  /** The element’s URI. */
  uri?: Maybe<Scalars['String']>,
  /** Whether the element is enabled or not. */
  enabled?: Maybe<Scalars['Boolean']>,
  /** Whether the element is archived or not. */
  archived?: Maybe<Scalars['Boolean']>,
  /** The ID of the site the element is associated with. */
  siteId?: Maybe<Scalars['Int']>,
  /** The element’s search score, if the `search` parameter was used when querying for the element. */
  searchScore?: Maybe<Scalars['String']>,
  /** Whether the element has been soft-deleted or not. */
  trashed?: Maybe<Scalars['Boolean']>,
  /** The element's status. */
  status?: Maybe<Scalars['String']>,
  /** The date the element was created. */
  dateCreated?: Maybe<Scalars['DateTime']>,
  /** The date the element was last updated. */
  dateUpdated?: Maybe<Scalars['DateTime']>,
  /** The name of the global set. */
  name?: Maybe<Scalars['String']>,
  /** The handle of the global set. */
  handle?: Maybe<Scalars['String']>,
  highlightColors?: Maybe<Array<Maybe<HighlightColors_TableRow>>>,
  colors?: Maybe<Scalars['String']>,
};

export type HighlightColors_TableRow = {
   __typename?: 'highlightColors_TableRow',
  col1?: Maybe<Scalars['String']>,
  color?: Maybe<Scalars['String']>,
};

export type Homepage_Homepage_Entry = EntryInterface & ElementInterface & {
   __typename?: 'homepage_homepage_Entry',
  /** The id of the entity */
  id?: Maybe<Scalars['ID']>,
  /** The uid of the entity */
  uid?: Maybe<Scalars['String']>,
  /** The element’s title. */
  title?: Maybe<Scalars['String']>,
  /** The element’s slug. */
  slug?: Maybe<Scalars['String']>,
  /** The element’s URI. */
  uri?: Maybe<Scalars['String']>,
  /** Whether the element is enabled or not. */
  enabled?: Maybe<Scalars['Boolean']>,
  /** Whether the element is archived or not. */
  archived?: Maybe<Scalars['Boolean']>,
  /** The ID of the site the element is associated with. */
  siteId?: Maybe<Scalars['Int']>,
  /** The element’s search score, if the `search` parameter was used when querying for the element. */
  searchScore?: Maybe<Scalars['String']>,
  /** Whether the element has been soft-deleted or not. */
  trashed?: Maybe<Scalars['Boolean']>,
  /** The element's status. */
  status?: Maybe<Scalars['String']>,
  /** The date the element was created. */
  dateCreated?: Maybe<Scalars['DateTime']>,
  /** The date the element was last updated. */
  dateUpdated?: Maybe<Scalars['DateTime']>,
  /** The element’s left position within its structure. */
  lft?: Maybe<Scalars['Int']>,
  /** The element’s right position within its structure. */
  rgt?: Maybe<Scalars['Int']>,
  /** The element’s level within its structure */
  level?: Maybe<Scalars['Int']>,
  /** The element’s structure’s root ID */
  root?: Maybe<Scalars['Int']>,
  /** The element’s structure ID. */
  structureId?: Maybe<Scalars['Int']>,
  /** The ID of the author of this entry. */
  authorId?: Maybe<Scalars['Int']>,
  /** The entry's author. */
  author?: Maybe<UserInterface>,
  /** The ID of the section that contains the entry. */
  sectionId?: Maybe<Scalars['Int']>,
  /** The handle of the section that contains the entry. */
  sectionHandle?: Maybe<Scalars['String']>,
  /** The ID of the entry type that contains the entry. */
  typeId?: Maybe<Scalars['Int']>,
  /** The handle of the entry type that contains the entry. */
  typeHandle?: Maybe<Scalars['String']>,
  /** The entry's post date. */
  postDate?: Maybe<Scalars['DateTime']>,
  /** The expiry date of the entry. */
  expiryDate?: Maybe<Scalars['DateTime']>,
  /** The entry’s children, if the section is a structure. Accepts the same arguments as the `entries` query. */
  children?: Maybe<Array<Maybe<EntryInterface>>>,
  /** The entry’s parent, if the section is a structure. */
  parent?: Maybe<EntryInterface>,
  /** The element’s full URL */
  url?: Maybe<Scalars['String']>,
  runner?: Maybe<Scalars['String']>,
  projects?: Maybe<Array<Maybe<EntryInterface>>>,
  socialTitle?: Maybe<Scalars['String']>,
  socialDescription?: Maybe<Scalars['String']>,
  socialImage?: Maybe<Array<Maybe<AssetInterface>>>,
};


export type Homepage_Homepage_EntryChildrenArgs = {
  id?: Maybe<Array<Maybe<Scalars['Int']>>>,
  uid?: Maybe<Array<Maybe<Scalars['String']>>>,
  status?: Maybe<Array<Maybe<Scalars['String']>>>,
  archived?: Maybe<Scalars['Boolean']>,
  trashed?: Maybe<Scalars['Boolean']>,
  site?: Maybe<Array<Maybe<Scalars['String']>>>,
  siteId?: Maybe<Scalars['String']>,
  unique?: Maybe<Scalars['Boolean']>,
  enabledForSite?: Maybe<Scalars['Boolean']>,
  title?: Maybe<Array<Maybe<Scalars['String']>>>,
  slug?: Maybe<Array<Maybe<Scalars['String']>>>,
  uri?: Maybe<Array<Maybe<Scalars['String']>>>,
  search?: Maybe<Scalars['String']>,
  relatedTo?: Maybe<Array<Maybe<Scalars['Int']>>>,
  relatedToAll?: Maybe<Array<Maybe<Scalars['Int']>>>,
  ref?: Maybe<Array<Maybe<Scalars['String']>>>,
  fixedOrder?: Maybe<Scalars['Boolean']>,
  inReverse?: Maybe<Scalars['Boolean']>,
  dateCreated?: Maybe<Scalars['String']>,
  dateUpdated?: Maybe<Scalars['String']>,
  offset?: Maybe<Scalars['Int']>,
  limit?: Maybe<Scalars['Int']>,
  orderBy?: Maybe<Scalars['String']>,
  withStructure?: Maybe<Scalars['Boolean']>,
  structureId?: Maybe<Scalars['Int']>,
  level?: Maybe<Scalars['Int']>,
  hasDescendants?: Maybe<Scalars['Boolean']>,
  ancestorOf?: Maybe<Scalars['Int']>,
  ancestorDist?: Maybe<Scalars['Int']>,
  descendantOf?: Maybe<Scalars['Int']>,
  descendantDist?: Maybe<Scalars['Int']>,
  leaves?: Maybe<Scalars['Boolean']>,
  editable?: Maybe<Scalars['Boolean']>,
  section?: Maybe<Array<Maybe<Scalars['String']>>>,
  sectionId?: Maybe<Array<Maybe<Scalars['Int']>>>,
  type?: Maybe<Array<Maybe<Scalars['String']>>>,
  typeId?: Maybe<Array<Maybe<Scalars['Int']>>>,
  authorId?: Maybe<Array<Maybe<Scalars['Int']>>>,
  authorGroup?: Maybe<Array<Maybe<Scalars['String']>>>,
  postDate?: Maybe<Scalars['String']>,
  before?: Maybe<Scalars['String']>,
  after?: Maybe<Scalars['String']>,
  expiryDate?: Maybe<Scalars['String']>
};


export type Homepage_Homepage_EntryProjectsArgs = {
  id?: Maybe<Array<Maybe<Scalars['Int']>>>,
  uid?: Maybe<Array<Maybe<Scalars['String']>>>,
  status?: Maybe<Array<Maybe<Scalars['String']>>>,
  archived?: Maybe<Scalars['Boolean']>,
  trashed?: Maybe<Scalars['Boolean']>,
  site?: Maybe<Array<Maybe<Scalars['String']>>>,
  siteId?: Maybe<Scalars['String']>,
  unique?: Maybe<Scalars['Boolean']>,
  enabledForSite?: Maybe<Scalars['Boolean']>,
  title?: Maybe<Array<Maybe<Scalars['String']>>>,
  slug?: Maybe<Array<Maybe<Scalars['String']>>>,
  uri?: Maybe<Array<Maybe<Scalars['String']>>>,
  search?: Maybe<Scalars['String']>,
  relatedTo?: Maybe<Array<Maybe<Scalars['Int']>>>,
  relatedToAll?: Maybe<Array<Maybe<Scalars['Int']>>>,
  ref?: Maybe<Array<Maybe<Scalars['String']>>>,
  fixedOrder?: Maybe<Scalars['Boolean']>,
  inReverse?: Maybe<Scalars['Boolean']>,
  dateCreated?: Maybe<Scalars['String']>,
  dateUpdated?: Maybe<Scalars['String']>,
  offset?: Maybe<Scalars['Int']>,
  limit?: Maybe<Scalars['Int']>,
  orderBy?: Maybe<Scalars['String']>,
  withStructure?: Maybe<Scalars['Boolean']>,
  structureId?: Maybe<Scalars['Int']>,
  level?: Maybe<Scalars['Int']>,
  hasDescendants?: Maybe<Scalars['Boolean']>,
  ancestorOf?: Maybe<Scalars['Int']>,
  ancestorDist?: Maybe<Scalars['Int']>,
  descendantOf?: Maybe<Scalars['Int']>,
  descendantDist?: Maybe<Scalars['Int']>,
  leaves?: Maybe<Scalars['Boolean']>,
  editable?: Maybe<Scalars['Boolean']>,
  section?: Maybe<Array<Maybe<Scalars['String']>>>,
  sectionId?: Maybe<Array<Maybe<Scalars['Int']>>>,
  type?: Maybe<Array<Maybe<Scalars['String']>>>,
  typeId?: Maybe<Array<Maybe<Scalars['Int']>>>,
  authorId?: Maybe<Array<Maybe<Scalars['Int']>>>,
  authorGroup?: Maybe<Array<Maybe<Scalars['String']>>>,
  postDate?: Maybe<Scalars['String']>,
  before?: Maybe<Scalars['String']>,
  after?: Maybe<Scalars['String']>,
  expiryDate?: Maybe<Scalars['String']>
};


export type Homepage_Homepage_EntrySocialImageArgs = {
  id?: Maybe<Array<Maybe<Scalars['Int']>>>,
  uid?: Maybe<Array<Maybe<Scalars['String']>>>,
  status?: Maybe<Array<Maybe<Scalars['String']>>>,
  archived?: Maybe<Scalars['Boolean']>,
  trashed?: Maybe<Scalars['Boolean']>,
  site?: Maybe<Array<Maybe<Scalars['String']>>>,
  siteId?: Maybe<Scalars['String']>,
  unique?: Maybe<Scalars['Boolean']>,
  enabledForSite?: Maybe<Scalars['Boolean']>,
  title?: Maybe<Array<Maybe<Scalars['String']>>>,
  slug?: Maybe<Array<Maybe<Scalars['String']>>>,
  uri?: Maybe<Array<Maybe<Scalars['String']>>>,
  search?: Maybe<Scalars['String']>,
  relatedTo?: Maybe<Array<Maybe<Scalars['Int']>>>,
  relatedToAll?: Maybe<Array<Maybe<Scalars['Int']>>>,
  ref?: Maybe<Array<Maybe<Scalars['String']>>>,
  fixedOrder?: Maybe<Scalars['Boolean']>,
  inReverse?: Maybe<Scalars['Boolean']>,
  dateCreated?: Maybe<Scalars['String']>,
  dateUpdated?: Maybe<Scalars['String']>,
  offset?: Maybe<Scalars['Int']>,
  limit?: Maybe<Scalars['Int']>,
  orderBy?: Maybe<Scalars['String']>,
  volumeId?: Maybe<Array<Maybe<Scalars['Int']>>>,
  volume?: Maybe<Array<Maybe<Scalars['String']>>>,
  folderId?: Maybe<Array<Maybe<Scalars['Int']>>>,
  filename?: Maybe<Array<Maybe<Scalars['String']>>>,
  kind?: Maybe<Array<Maybe<Scalars['String']>>>,
  height?: Maybe<Scalars['String']>,
  width?: Maybe<Scalars['String']>,
  size?: Maybe<Scalars['String']>,
  dateModified?: Maybe<Scalars['String']>,
  includeSubfolders?: Maybe<Scalars['Boolean']>
};

export type Links_Link_BlockType = MatrixBlockInterface & ElementInterface & {
   __typename?: 'links_link_BlockType',
  /** The id of the entity */
  id?: Maybe<Scalars['ID']>,
  /** The uid of the entity */
  uid?: Maybe<Scalars['String']>,
  /** The element’s title. */
  title?: Maybe<Scalars['String']>,
  /** The element’s slug. */
  slug?: Maybe<Scalars['String']>,
  /** The element’s URI. */
  uri?: Maybe<Scalars['String']>,
  /** Whether the element is enabled or not. */
  enabled?: Maybe<Scalars['Boolean']>,
  /** Whether the element is archived or not. */
  archived?: Maybe<Scalars['Boolean']>,
  /** The ID of the site the element is associated with. */
  siteId?: Maybe<Scalars['Int']>,
  /** The element’s search score, if the `search` parameter was used when querying for the element. */
  searchScore?: Maybe<Scalars['String']>,
  /** Whether the element has been soft-deleted or not. */
  trashed?: Maybe<Scalars['Boolean']>,
  /** The element's status. */
  status?: Maybe<Scalars['String']>,
  /** The date the element was created. */
  dateCreated?: Maybe<Scalars['DateTime']>,
  /** The date the element was last updated. */
  dateUpdated?: Maybe<Scalars['DateTime']>,
  /** The ID of the field that owns the matrix block. */
  fieldId?: Maybe<Scalars['Int']>,
  /** The ID of the element that owns the matrix block. */
  ownerId?: Maybe<Scalars['Int']>,
  /** The ID of the matrix block's type. */
  typeId?: Maybe<Scalars['Int']>,
  /** The handle of the matrix block's type. */
  typeHandle?: Maybe<Scalars['String']>,
  /** The sort order of the matrix block within the owner element field. */
  sortOrder?: Maybe<Scalars['Int']>,
  linkText?: Maybe<Scalars['String']>,
  linkUrl?: Maybe<Scalars['String']>,
};

export type Links_MatrixField = Links_Link_BlockType;

/** This is the interface implemented by all matrix blocks. */
export type MatrixBlockInterface = {
  /** The id of the entity */
  id?: Maybe<Scalars['ID']>,
  /** The uid of the entity */
  uid?: Maybe<Scalars['String']>,
  /** The element’s title. */
  title?: Maybe<Scalars['String']>,
  /** The element’s slug. */
  slug?: Maybe<Scalars['String']>,
  /** The element’s URI. */
  uri?: Maybe<Scalars['String']>,
  /** Whether the element is enabled or not. */
  enabled?: Maybe<Scalars['Boolean']>,
  /** Whether the element is archived or not. */
  archived?: Maybe<Scalars['Boolean']>,
  /** The ID of the site the element is associated with. */
  siteId?: Maybe<Scalars['Int']>,
  /** The element’s search score, if the `search` parameter was used when querying for the element. */
  searchScore?: Maybe<Scalars['String']>,
  /** Whether the element has been soft-deleted or not. */
  trashed?: Maybe<Scalars['Boolean']>,
  /** The element's status. */
  status?: Maybe<Scalars['String']>,
  /** The date the element was created. */
  dateCreated?: Maybe<Scalars['DateTime']>,
  /** The date the element was last updated. */
  dateUpdated?: Maybe<Scalars['DateTime']>,
  /** The ID of the field that owns the matrix block. */
  fieldId?: Maybe<Scalars['Int']>,
  /** The ID of the element that owns the matrix block. */
  ownerId?: Maybe<Scalars['Int']>,
  /** The ID of the matrix block's type. */
  typeId?: Maybe<Scalars['Int']>,
  /** The handle of the matrix block's type. */
  typeHandle?: Maybe<Scalars['String']>,
  /** The sort order of the matrix block within the owner element field. */
  sortOrder?: Maybe<Scalars['Int']>,
};

export type ProjectContent_Image_BlockType = MatrixBlockInterface & ElementInterface & {
   __typename?: 'projectContent_image_BlockType',
  /** The id of the entity */
  id?: Maybe<Scalars['ID']>,
  /** The uid of the entity */
  uid?: Maybe<Scalars['String']>,
  /** The element’s title. */
  title?: Maybe<Scalars['String']>,
  /** The element’s slug. */
  slug?: Maybe<Scalars['String']>,
  /** The element’s URI. */
  uri?: Maybe<Scalars['String']>,
  /** Whether the element is enabled or not. */
  enabled?: Maybe<Scalars['Boolean']>,
  /** Whether the element is archived or not. */
  archived?: Maybe<Scalars['Boolean']>,
  /** The ID of the site the element is associated with. */
  siteId?: Maybe<Scalars['Int']>,
  /** The element’s search score, if the `search` parameter was used when querying for the element. */
  searchScore?: Maybe<Scalars['String']>,
  /** Whether the element has been soft-deleted or not. */
  trashed?: Maybe<Scalars['Boolean']>,
  /** The element's status. */
  status?: Maybe<Scalars['String']>,
  /** The date the element was created. */
  dateCreated?: Maybe<Scalars['DateTime']>,
  /** The date the element was last updated. */
  dateUpdated?: Maybe<Scalars['DateTime']>,
  /** The ID of the field that owns the matrix block. */
  fieldId?: Maybe<Scalars['Int']>,
  /** The ID of the element that owns the matrix block. */
  ownerId?: Maybe<Scalars['Int']>,
  /** The ID of the matrix block's type. */
  typeId?: Maybe<Scalars['Int']>,
  /** The handle of the matrix block's type. */
  typeHandle?: Maybe<Scalars['String']>,
  /** The sort order of the matrix block within the owner element field. */
  sortOrder?: Maybe<Scalars['Int']>,
  image?: Maybe<Array<Maybe<AssetInterface>>>,
  portrait?: Maybe<Scalars['Boolean']>,
  fullWidth?: Maybe<Scalars['Boolean']>,
};


export type ProjectContent_Image_BlockTypeImageArgs = {
  id?: Maybe<Array<Maybe<Scalars['Int']>>>,
  uid?: Maybe<Array<Maybe<Scalars['String']>>>,
  status?: Maybe<Array<Maybe<Scalars['String']>>>,
  archived?: Maybe<Scalars['Boolean']>,
  trashed?: Maybe<Scalars['Boolean']>,
  site?: Maybe<Array<Maybe<Scalars['String']>>>,
  siteId?: Maybe<Scalars['String']>,
  unique?: Maybe<Scalars['Boolean']>,
  enabledForSite?: Maybe<Scalars['Boolean']>,
  title?: Maybe<Array<Maybe<Scalars['String']>>>,
  slug?: Maybe<Array<Maybe<Scalars['String']>>>,
  uri?: Maybe<Array<Maybe<Scalars['String']>>>,
  search?: Maybe<Scalars['String']>,
  relatedTo?: Maybe<Array<Maybe<Scalars['Int']>>>,
  relatedToAll?: Maybe<Array<Maybe<Scalars['Int']>>>,
  ref?: Maybe<Array<Maybe<Scalars['String']>>>,
  fixedOrder?: Maybe<Scalars['Boolean']>,
  inReverse?: Maybe<Scalars['Boolean']>,
  dateCreated?: Maybe<Scalars['String']>,
  dateUpdated?: Maybe<Scalars['String']>,
  offset?: Maybe<Scalars['Int']>,
  limit?: Maybe<Scalars['Int']>,
  orderBy?: Maybe<Scalars['String']>,
  volumeId?: Maybe<Array<Maybe<Scalars['Int']>>>,
  volume?: Maybe<Array<Maybe<Scalars['String']>>>,
  folderId?: Maybe<Array<Maybe<Scalars['Int']>>>,
  filename?: Maybe<Array<Maybe<Scalars['String']>>>,
  kind?: Maybe<Array<Maybe<Scalars['String']>>>,
  height?: Maybe<Scalars['String']>,
  width?: Maybe<Scalars['String']>,
  size?: Maybe<Scalars['String']>,
  dateModified?: Maybe<Scalars['String']>,
  includeSubfolders?: Maybe<Scalars['Boolean']>
};

export type ProjectContent_MatrixField = ProjectContent_Image_BlockType | ProjectContent_Text_BlockType | ProjectContent_SectionTitle_BlockType;

export type ProjectContent_SectionTitle_BlockType = MatrixBlockInterface & ElementInterface & {
   __typename?: 'projectContent_sectionTitle_BlockType',
  /** The id of the entity */
  id?: Maybe<Scalars['ID']>,
  /** The uid of the entity */
  uid?: Maybe<Scalars['String']>,
  /** The element’s title. */
  title?: Maybe<Scalars['String']>,
  /** The element’s slug. */
  slug?: Maybe<Scalars['String']>,
  /** The element’s URI. */
  uri?: Maybe<Scalars['String']>,
  /** Whether the element is enabled or not. */
  enabled?: Maybe<Scalars['Boolean']>,
  /** Whether the element is archived or not. */
  archived?: Maybe<Scalars['Boolean']>,
  /** The ID of the site the element is associated with. */
  siteId?: Maybe<Scalars['Int']>,
  /** The element’s search score, if the `search` parameter was used when querying for the element. */
  searchScore?: Maybe<Scalars['String']>,
  /** Whether the element has been soft-deleted or not. */
  trashed?: Maybe<Scalars['Boolean']>,
  /** The element's status. */
  status?: Maybe<Scalars['String']>,
  /** The date the element was created. */
  dateCreated?: Maybe<Scalars['DateTime']>,
  /** The date the element was last updated. */
  dateUpdated?: Maybe<Scalars['DateTime']>,
  /** The ID of the field that owns the matrix block. */
  fieldId?: Maybe<Scalars['Int']>,
  /** The ID of the element that owns the matrix block. */
  ownerId?: Maybe<Scalars['Int']>,
  /** The ID of the matrix block's type. */
  typeId?: Maybe<Scalars['Int']>,
  /** The handle of the matrix block's type. */
  typeHandle?: Maybe<Scalars['String']>,
  /** The sort order of the matrix block within the owner element field. */
  sortOrder?: Maybe<Scalars['Int']>,
  sectionTitle?: Maybe<Scalars['String']>,
};

export type ProjectContent_Text_BlockType = MatrixBlockInterface & ElementInterface & {
   __typename?: 'projectContent_text_BlockType',
  /** The id of the entity */
  id?: Maybe<Scalars['ID']>,
  /** The uid of the entity */
  uid?: Maybe<Scalars['String']>,
  /** The element’s title. */
  title?: Maybe<Scalars['String']>,
  /** The element’s slug. */
  slug?: Maybe<Scalars['String']>,
  /** The element’s URI. */
  uri?: Maybe<Scalars['String']>,
  /** Whether the element is enabled or not. */
  enabled?: Maybe<Scalars['Boolean']>,
  /** Whether the element is archived or not. */
  archived?: Maybe<Scalars['Boolean']>,
  /** The ID of the site the element is associated with. */
  siteId?: Maybe<Scalars['Int']>,
  /** The element’s search score, if the `search` parameter was used when querying for the element. */
  searchScore?: Maybe<Scalars['String']>,
  /** Whether the element has been soft-deleted or not. */
  trashed?: Maybe<Scalars['Boolean']>,
  /** The element's status. */
  status?: Maybe<Scalars['String']>,
  /** The date the element was created. */
  dateCreated?: Maybe<Scalars['DateTime']>,
  /** The date the element was last updated. */
  dateUpdated?: Maybe<Scalars['DateTime']>,
  /** The ID of the field that owns the matrix block. */
  fieldId?: Maybe<Scalars['Int']>,
  /** The ID of the element that owns the matrix block. */
  ownerId?: Maybe<Scalars['Int']>,
  /** The ID of the matrix block's type. */
  typeId?: Maybe<Scalars['Int']>,
  /** The handle of the matrix block's type. */
  typeHandle?: Maybe<Scalars['String']>,
  /** The sort order of the matrix block within the owner element field. */
  sortOrder?: Maybe<Scalars['Int']>,
  text?: Maybe<Scalars['String']>,
};

export type Projects_Project_Entry = EntryInterface & ElementInterface & {
   __typename?: 'projects_project_Entry',
  /** The id of the entity */
  id?: Maybe<Scalars['ID']>,
  /** The uid of the entity */
  uid?: Maybe<Scalars['String']>,
  /** The element’s title. */
  title?: Maybe<Scalars['String']>,
  /** The element’s slug. */
  slug?: Maybe<Scalars['String']>,
  /** The element’s URI. */
  uri?: Maybe<Scalars['String']>,
  /** Whether the element is enabled or not. */
  enabled?: Maybe<Scalars['Boolean']>,
  /** Whether the element is archived or not. */
  archived?: Maybe<Scalars['Boolean']>,
  /** The ID of the site the element is associated with. */
  siteId?: Maybe<Scalars['Int']>,
  /** The element’s search score, if the `search` parameter was used when querying for the element. */
  searchScore?: Maybe<Scalars['String']>,
  /** Whether the element has been soft-deleted or not. */
  trashed?: Maybe<Scalars['Boolean']>,
  /** The element's status. */
  status?: Maybe<Scalars['String']>,
  /** The date the element was created. */
  dateCreated?: Maybe<Scalars['DateTime']>,
  /** The date the element was last updated. */
  dateUpdated?: Maybe<Scalars['DateTime']>,
  /** The element’s left position within its structure. */
  lft?: Maybe<Scalars['Int']>,
  /** The element’s right position within its structure. */
  rgt?: Maybe<Scalars['Int']>,
  /** The element’s level within its structure */
  level?: Maybe<Scalars['Int']>,
  /** The element’s structure’s root ID */
  root?: Maybe<Scalars['Int']>,
  /** The element’s structure ID. */
  structureId?: Maybe<Scalars['Int']>,
  /** The ID of the author of this entry. */
  authorId?: Maybe<Scalars['Int']>,
  /** The entry's author. */
  author?: Maybe<UserInterface>,
  /** The ID of the section that contains the entry. */
  sectionId?: Maybe<Scalars['Int']>,
  /** The handle of the section that contains the entry. */
  sectionHandle?: Maybe<Scalars['String']>,
  /** The ID of the entry type that contains the entry. */
  typeId?: Maybe<Scalars['Int']>,
  /** The handle of the entry type that contains the entry. */
  typeHandle?: Maybe<Scalars['String']>,
  /** The entry's post date. */
  postDate?: Maybe<Scalars['DateTime']>,
  /** The expiry date of the entry. */
  expiryDate?: Maybe<Scalars['DateTime']>,
  /** The entry’s children, if the section is a structure. Accepts the same arguments as the `entries` query. */
  children?: Maybe<Array<Maybe<EntryInterface>>>,
  /** The entry’s parent, if the section is a structure. */
  parent?: Maybe<EntryInterface>,
  /** The element’s full URL */
  url?: Maybe<Scalars['String']>,
  runner?: Maybe<Scalars['String']>,
  projectContent?: Maybe<Array<Maybe<ProjectContent_MatrixField>>>,
  mainImage?: Maybe<Array<Maybe<AssetInterface>>>,
  tileImage?: Maybe<Array<Maybe<AssetInterface>>>,
  heroCardImage?: Maybe<Array<Maybe<AssetInterface>>>,
  tint?: Maybe<Scalars['String']>,
  socialTitle?: Maybe<Scalars['String']>,
  socialDescription?: Maybe<Scalars['String']>,
  socialImage?: Maybe<Array<Maybe<AssetInterface>>>,
};


export type Projects_Project_EntryChildrenArgs = {
  id?: Maybe<Array<Maybe<Scalars['Int']>>>,
  uid?: Maybe<Array<Maybe<Scalars['String']>>>,
  status?: Maybe<Array<Maybe<Scalars['String']>>>,
  archived?: Maybe<Scalars['Boolean']>,
  trashed?: Maybe<Scalars['Boolean']>,
  site?: Maybe<Array<Maybe<Scalars['String']>>>,
  siteId?: Maybe<Scalars['String']>,
  unique?: Maybe<Scalars['Boolean']>,
  enabledForSite?: Maybe<Scalars['Boolean']>,
  title?: Maybe<Array<Maybe<Scalars['String']>>>,
  slug?: Maybe<Array<Maybe<Scalars['String']>>>,
  uri?: Maybe<Array<Maybe<Scalars['String']>>>,
  search?: Maybe<Scalars['String']>,
  relatedTo?: Maybe<Array<Maybe<Scalars['Int']>>>,
  relatedToAll?: Maybe<Array<Maybe<Scalars['Int']>>>,
  ref?: Maybe<Array<Maybe<Scalars['String']>>>,
  fixedOrder?: Maybe<Scalars['Boolean']>,
  inReverse?: Maybe<Scalars['Boolean']>,
  dateCreated?: Maybe<Scalars['String']>,
  dateUpdated?: Maybe<Scalars['String']>,
  offset?: Maybe<Scalars['Int']>,
  limit?: Maybe<Scalars['Int']>,
  orderBy?: Maybe<Scalars['String']>,
  withStructure?: Maybe<Scalars['Boolean']>,
  structureId?: Maybe<Scalars['Int']>,
  level?: Maybe<Scalars['Int']>,
  hasDescendants?: Maybe<Scalars['Boolean']>,
  ancestorOf?: Maybe<Scalars['Int']>,
  ancestorDist?: Maybe<Scalars['Int']>,
  descendantOf?: Maybe<Scalars['Int']>,
  descendantDist?: Maybe<Scalars['Int']>,
  leaves?: Maybe<Scalars['Boolean']>,
  editable?: Maybe<Scalars['Boolean']>,
  section?: Maybe<Array<Maybe<Scalars['String']>>>,
  sectionId?: Maybe<Array<Maybe<Scalars['Int']>>>,
  type?: Maybe<Array<Maybe<Scalars['String']>>>,
  typeId?: Maybe<Array<Maybe<Scalars['Int']>>>,
  authorId?: Maybe<Array<Maybe<Scalars['Int']>>>,
  authorGroup?: Maybe<Array<Maybe<Scalars['String']>>>,
  postDate?: Maybe<Scalars['String']>,
  before?: Maybe<Scalars['String']>,
  after?: Maybe<Scalars['String']>,
  expiryDate?: Maybe<Scalars['String']>
};


export type Projects_Project_EntryProjectContentArgs = {
  id?: Maybe<Array<Maybe<Scalars['Int']>>>,
  uid?: Maybe<Array<Maybe<Scalars['String']>>>,
  status?: Maybe<Array<Maybe<Scalars['String']>>>,
  archived?: Maybe<Scalars['Boolean']>,
  trashed?: Maybe<Scalars['Boolean']>,
  site?: Maybe<Array<Maybe<Scalars['String']>>>,
  siteId?: Maybe<Scalars['String']>,
  unique?: Maybe<Scalars['Boolean']>,
  enabledForSite?: Maybe<Scalars['Boolean']>,
  title?: Maybe<Array<Maybe<Scalars['String']>>>,
  slug?: Maybe<Array<Maybe<Scalars['String']>>>,
  uri?: Maybe<Array<Maybe<Scalars['String']>>>,
  search?: Maybe<Scalars['String']>,
  relatedTo?: Maybe<Array<Maybe<Scalars['Int']>>>,
  relatedToAll?: Maybe<Array<Maybe<Scalars['Int']>>>,
  ref?: Maybe<Array<Maybe<Scalars['String']>>>,
  fixedOrder?: Maybe<Scalars['Boolean']>,
  inReverse?: Maybe<Scalars['Boolean']>,
  dateCreated?: Maybe<Scalars['String']>,
  dateUpdated?: Maybe<Scalars['String']>,
  offset?: Maybe<Scalars['Int']>,
  limit?: Maybe<Scalars['Int']>,
  orderBy?: Maybe<Scalars['String']>,
  fieldId?: Maybe<Array<Maybe<Scalars['Int']>>>,
  ownerId?: Maybe<Array<Maybe<Scalars['String']>>>,
  typeId?: Maybe<Array<Maybe<Scalars['Int']>>>,
  type?: Maybe<Array<Maybe<Scalars['String']>>>
};


export type Projects_Project_EntryMainImageArgs = {
  id?: Maybe<Array<Maybe<Scalars['Int']>>>,
  uid?: Maybe<Array<Maybe<Scalars['String']>>>,
  status?: Maybe<Array<Maybe<Scalars['String']>>>,
  archived?: Maybe<Scalars['Boolean']>,
  trashed?: Maybe<Scalars['Boolean']>,
  site?: Maybe<Array<Maybe<Scalars['String']>>>,
  siteId?: Maybe<Scalars['String']>,
  unique?: Maybe<Scalars['Boolean']>,
  enabledForSite?: Maybe<Scalars['Boolean']>,
  title?: Maybe<Array<Maybe<Scalars['String']>>>,
  slug?: Maybe<Array<Maybe<Scalars['String']>>>,
  uri?: Maybe<Array<Maybe<Scalars['String']>>>,
  search?: Maybe<Scalars['String']>,
  relatedTo?: Maybe<Array<Maybe<Scalars['Int']>>>,
  relatedToAll?: Maybe<Array<Maybe<Scalars['Int']>>>,
  ref?: Maybe<Array<Maybe<Scalars['String']>>>,
  fixedOrder?: Maybe<Scalars['Boolean']>,
  inReverse?: Maybe<Scalars['Boolean']>,
  dateCreated?: Maybe<Scalars['String']>,
  dateUpdated?: Maybe<Scalars['String']>,
  offset?: Maybe<Scalars['Int']>,
  limit?: Maybe<Scalars['Int']>,
  orderBy?: Maybe<Scalars['String']>,
  volumeId?: Maybe<Array<Maybe<Scalars['Int']>>>,
  volume?: Maybe<Array<Maybe<Scalars['String']>>>,
  folderId?: Maybe<Array<Maybe<Scalars['Int']>>>,
  filename?: Maybe<Array<Maybe<Scalars['String']>>>,
  kind?: Maybe<Array<Maybe<Scalars['String']>>>,
  height?: Maybe<Scalars['String']>,
  width?: Maybe<Scalars['String']>,
  size?: Maybe<Scalars['String']>,
  dateModified?: Maybe<Scalars['String']>,
  includeSubfolders?: Maybe<Scalars['Boolean']>
};


export type Projects_Project_EntryTileImageArgs = {
  id?: Maybe<Array<Maybe<Scalars['Int']>>>,
  uid?: Maybe<Array<Maybe<Scalars['String']>>>,
  status?: Maybe<Array<Maybe<Scalars['String']>>>,
  archived?: Maybe<Scalars['Boolean']>,
  trashed?: Maybe<Scalars['Boolean']>,
  site?: Maybe<Array<Maybe<Scalars['String']>>>,
  siteId?: Maybe<Scalars['String']>,
  unique?: Maybe<Scalars['Boolean']>,
  enabledForSite?: Maybe<Scalars['Boolean']>,
  title?: Maybe<Array<Maybe<Scalars['String']>>>,
  slug?: Maybe<Array<Maybe<Scalars['String']>>>,
  uri?: Maybe<Array<Maybe<Scalars['String']>>>,
  search?: Maybe<Scalars['String']>,
  relatedTo?: Maybe<Array<Maybe<Scalars['Int']>>>,
  relatedToAll?: Maybe<Array<Maybe<Scalars['Int']>>>,
  ref?: Maybe<Array<Maybe<Scalars['String']>>>,
  fixedOrder?: Maybe<Scalars['Boolean']>,
  inReverse?: Maybe<Scalars['Boolean']>,
  dateCreated?: Maybe<Scalars['String']>,
  dateUpdated?: Maybe<Scalars['String']>,
  offset?: Maybe<Scalars['Int']>,
  limit?: Maybe<Scalars['Int']>,
  orderBy?: Maybe<Scalars['String']>,
  volumeId?: Maybe<Array<Maybe<Scalars['Int']>>>,
  volume?: Maybe<Array<Maybe<Scalars['String']>>>,
  folderId?: Maybe<Array<Maybe<Scalars['Int']>>>,
  filename?: Maybe<Array<Maybe<Scalars['String']>>>,
  kind?: Maybe<Array<Maybe<Scalars['String']>>>,
  height?: Maybe<Scalars['String']>,
  width?: Maybe<Scalars['String']>,
  size?: Maybe<Scalars['String']>,
  dateModified?: Maybe<Scalars['String']>,
  includeSubfolders?: Maybe<Scalars['Boolean']>
};


export type Projects_Project_EntryHeroCardImageArgs = {
  id?: Maybe<Array<Maybe<Scalars['Int']>>>,
  uid?: Maybe<Array<Maybe<Scalars['String']>>>,
  status?: Maybe<Array<Maybe<Scalars['String']>>>,
  archived?: Maybe<Scalars['Boolean']>,
  trashed?: Maybe<Scalars['Boolean']>,
  site?: Maybe<Array<Maybe<Scalars['String']>>>,
  siteId?: Maybe<Scalars['String']>,
  unique?: Maybe<Scalars['Boolean']>,
  enabledForSite?: Maybe<Scalars['Boolean']>,
  title?: Maybe<Array<Maybe<Scalars['String']>>>,
  slug?: Maybe<Array<Maybe<Scalars['String']>>>,
  uri?: Maybe<Array<Maybe<Scalars['String']>>>,
  search?: Maybe<Scalars['String']>,
  relatedTo?: Maybe<Array<Maybe<Scalars['Int']>>>,
  relatedToAll?: Maybe<Array<Maybe<Scalars['Int']>>>,
  ref?: Maybe<Array<Maybe<Scalars['String']>>>,
  fixedOrder?: Maybe<Scalars['Boolean']>,
  inReverse?: Maybe<Scalars['Boolean']>,
  dateCreated?: Maybe<Scalars['String']>,
  dateUpdated?: Maybe<Scalars['String']>,
  offset?: Maybe<Scalars['Int']>,
  limit?: Maybe<Scalars['Int']>,
  orderBy?: Maybe<Scalars['String']>,
  volumeId?: Maybe<Array<Maybe<Scalars['Int']>>>,
  volume?: Maybe<Array<Maybe<Scalars['String']>>>,
  folderId?: Maybe<Array<Maybe<Scalars['Int']>>>,
  filename?: Maybe<Array<Maybe<Scalars['String']>>>,
  kind?: Maybe<Array<Maybe<Scalars['String']>>>,
  height?: Maybe<Scalars['String']>,
  width?: Maybe<Scalars['String']>,
  size?: Maybe<Scalars['String']>,
  dateModified?: Maybe<Scalars['String']>,
  includeSubfolders?: Maybe<Scalars['Boolean']>
};


export type Projects_Project_EntrySocialImageArgs = {
  id?: Maybe<Array<Maybe<Scalars['Int']>>>,
  uid?: Maybe<Array<Maybe<Scalars['String']>>>,
  status?: Maybe<Array<Maybe<Scalars['String']>>>,
  archived?: Maybe<Scalars['Boolean']>,
  trashed?: Maybe<Scalars['Boolean']>,
  site?: Maybe<Array<Maybe<Scalars['String']>>>,
  siteId?: Maybe<Scalars['String']>,
  unique?: Maybe<Scalars['Boolean']>,
  enabledForSite?: Maybe<Scalars['Boolean']>,
  title?: Maybe<Array<Maybe<Scalars['String']>>>,
  slug?: Maybe<Array<Maybe<Scalars['String']>>>,
  uri?: Maybe<Array<Maybe<Scalars['String']>>>,
  search?: Maybe<Scalars['String']>,
  relatedTo?: Maybe<Array<Maybe<Scalars['Int']>>>,
  relatedToAll?: Maybe<Array<Maybe<Scalars['Int']>>>,
  ref?: Maybe<Array<Maybe<Scalars['String']>>>,
  fixedOrder?: Maybe<Scalars['Boolean']>,
  inReverse?: Maybe<Scalars['Boolean']>,
  dateCreated?: Maybe<Scalars['String']>,
  dateUpdated?: Maybe<Scalars['String']>,
  offset?: Maybe<Scalars['Int']>,
  limit?: Maybe<Scalars['Int']>,
  orderBy?: Maybe<Scalars['String']>,
  volumeId?: Maybe<Array<Maybe<Scalars['Int']>>>,
  volume?: Maybe<Array<Maybe<Scalars['String']>>>,
  folderId?: Maybe<Array<Maybe<Scalars['Int']>>>,
  filename?: Maybe<Array<Maybe<Scalars['String']>>>,
  kind?: Maybe<Array<Maybe<Scalars['String']>>>,
  height?: Maybe<Scalars['String']>,
  width?: Maybe<Scalars['String']>,
  size?: Maybe<Scalars['String']>,
  dateModified?: Maybe<Scalars['String']>,
  includeSubfolders?: Maybe<Scalars['Boolean']>
};

export type ProjectsPage_ProjectsPage_Entry = EntryInterface & ElementInterface & {
   __typename?: 'projectsPage_projectsPage_Entry',
  /** The id of the entity */
  id?: Maybe<Scalars['ID']>,
  /** The uid of the entity */
  uid?: Maybe<Scalars['String']>,
  /** The element’s title. */
  title?: Maybe<Scalars['String']>,
  /** The element’s slug. */
  slug?: Maybe<Scalars['String']>,
  /** The element’s URI. */
  uri?: Maybe<Scalars['String']>,
  /** Whether the element is enabled or not. */
  enabled?: Maybe<Scalars['Boolean']>,
  /** Whether the element is archived or not. */
  archived?: Maybe<Scalars['Boolean']>,
  /** The ID of the site the element is associated with. */
  siteId?: Maybe<Scalars['Int']>,
  /** The element’s search score, if the `search` parameter was used when querying for the element. */
  searchScore?: Maybe<Scalars['String']>,
  /** Whether the element has been soft-deleted or not. */
  trashed?: Maybe<Scalars['Boolean']>,
  /** The element's status. */
  status?: Maybe<Scalars['String']>,
  /** The date the element was created. */
  dateCreated?: Maybe<Scalars['DateTime']>,
  /** The date the element was last updated. */
  dateUpdated?: Maybe<Scalars['DateTime']>,
  /** The element’s left position within its structure. */
  lft?: Maybe<Scalars['Int']>,
  /** The element’s right position within its structure. */
  rgt?: Maybe<Scalars['Int']>,
  /** The element’s level within its structure */
  level?: Maybe<Scalars['Int']>,
  /** The element’s structure’s root ID */
  root?: Maybe<Scalars['Int']>,
  /** The element’s structure ID. */
  structureId?: Maybe<Scalars['Int']>,
  /** The ID of the author of this entry. */
  authorId?: Maybe<Scalars['Int']>,
  /** The entry's author. */
  author?: Maybe<UserInterface>,
  /** The ID of the section that contains the entry. */
  sectionId?: Maybe<Scalars['Int']>,
  /** The handle of the section that contains the entry. */
  sectionHandle?: Maybe<Scalars['String']>,
  /** The ID of the entry type that contains the entry. */
  typeId?: Maybe<Scalars['Int']>,
  /** The handle of the entry type that contains the entry. */
  typeHandle?: Maybe<Scalars['String']>,
  /** The entry's post date. */
  postDate?: Maybe<Scalars['DateTime']>,
  /** The expiry date of the entry. */
  expiryDate?: Maybe<Scalars['DateTime']>,
  /** The entry’s children, if the section is a structure. Accepts the same arguments as the `entries` query. */
  children?: Maybe<Array<Maybe<EntryInterface>>>,
  /** The entry’s parent, if the section is a structure. */
  parent?: Maybe<EntryInterface>,
  /** The element’s full URL */
  url?: Maybe<Scalars['String']>,
  socialDescription?: Maybe<Scalars['String']>,
  socialImage?: Maybe<Array<Maybe<AssetInterface>>>,
  socialTitle?: Maybe<Scalars['String']>,
};


export type ProjectsPage_ProjectsPage_EntryChildrenArgs = {
  id?: Maybe<Array<Maybe<Scalars['Int']>>>,
  uid?: Maybe<Array<Maybe<Scalars['String']>>>,
  status?: Maybe<Array<Maybe<Scalars['String']>>>,
  archived?: Maybe<Scalars['Boolean']>,
  trashed?: Maybe<Scalars['Boolean']>,
  site?: Maybe<Array<Maybe<Scalars['String']>>>,
  siteId?: Maybe<Scalars['String']>,
  unique?: Maybe<Scalars['Boolean']>,
  enabledForSite?: Maybe<Scalars['Boolean']>,
  title?: Maybe<Array<Maybe<Scalars['String']>>>,
  slug?: Maybe<Array<Maybe<Scalars['String']>>>,
  uri?: Maybe<Array<Maybe<Scalars['String']>>>,
  search?: Maybe<Scalars['String']>,
  relatedTo?: Maybe<Array<Maybe<Scalars['Int']>>>,
  relatedToAll?: Maybe<Array<Maybe<Scalars['Int']>>>,
  ref?: Maybe<Array<Maybe<Scalars['String']>>>,
  fixedOrder?: Maybe<Scalars['Boolean']>,
  inReverse?: Maybe<Scalars['Boolean']>,
  dateCreated?: Maybe<Scalars['String']>,
  dateUpdated?: Maybe<Scalars['String']>,
  offset?: Maybe<Scalars['Int']>,
  limit?: Maybe<Scalars['Int']>,
  orderBy?: Maybe<Scalars['String']>,
  withStructure?: Maybe<Scalars['Boolean']>,
  structureId?: Maybe<Scalars['Int']>,
  level?: Maybe<Scalars['Int']>,
  hasDescendants?: Maybe<Scalars['Boolean']>,
  ancestorOf?: Maybe<Scalars['Int']>,
  ancestorDist?: Maybe<Scalars['Int']>,
  descendantOf?: Maybe<Scalars['Int']>,
  descendantDist?: Maybe<Scalars['Int']>,
  leaves?: Maybe<Scalars['Boolean']>,
  editable?: Maybe<Scalars['Boolean']>,
  section?: Maybe<Array<Maybe<Scalars['String']>>>,
  sectionId?: Maybe<Array<Maybe<Scalars['Int']>>>,
  type?: Maybe<Array<Maybe<Scalars['String']>>>,
  typeId?: Maybe<Array<Maybe<Scalars['Int']>>>,
  authorId?: Maybe<Array<Maybe<Scalars['Int']>>>,
  authorGroup?: Maybe<Array<Maybe<Scalars['String']>>>,
  postDate?: Maybe<Scalars['String']>,
  before?: Maybe<Scalars['String']>,
  after?: Maybe<Scalars['String']>,
  expiryDate?: Maybe<Scalars['String']>
};


export type ProjectsPage_ProjectsPage_EntrySocialImageArgs = {
  id?: Maybe<Array<Maybe<Scalars['Int']>>>,
  uid?: Maybe<Array<Maybe<Scalars['String']>>>,
  status?: Maybe<Array<Maybe<Scalars['String']>>>,
  archived?: Maybe<Scalars['Boolean']>,
  trashed?: Maybe<Scalars['Boolean']>,
  site?: Maybe<Array<Maybe<Scalars['String']>>>,
  siteId?: Maybe<Scalars['String']>,
  unique?: Maybe<Scalars['Boolean']>,
  enabledForSite?: Maybe<Scalars['Boolean']>,
  title?: Maybe<Array<Maybe<Scalars['String']>>>,
  slug?: Maybe<Array<Maybe<Scalars['String']>>>,
  uri?: Maybe<Array<Maybe<Scalars['String']>>>,
  search?: Maybe<Scalars['String']>,
  relatedTo?: Maybe<Array<Maybe<Scalars['Int']>>>,
  relatedToAll?: Maybe<Array<Maybe<Scalars['Int']>>>,
  ref?: Maybe<Array<Maybe<Scalars['String']>>>,
  fixedOrder?: Maybe<Scalars['Boolean']>,
  inReverse?: Maybe<Scalars['Boolean']>,
  dateCreated?: Maybe<Scalars['String']>,
  dateUpdated?: Maybe<Scalars['String']>,
  offset?: Maybe<Scalars['Int']>,
  limit?: Maybe<Scalars['Int']>,
  orderBy?: Maybe<Scalars['String']>,
  volumeId?: Maybe<Array<Maybe<Scalars['Int']>>>,
  volume?: Maybe<Array<Maybe<Scalars['String']>>>,
  folderId?: Maybe<Array<Maybe<Scalars['Int']>>>,
  filename?: Maybe<Array<Maybe<Scalars['String']>>>,
  kind?: Maybe<Array<Maybe<Scalars['String']>>>,
  height?: Maybe<Scalars['String']>,
  width?: Maybe<Scalars['String']>,
  size?: Maybe<Scalars['String']>,
  dateModified?: Maybe<Scalars['String']>,
  includeSubfolders?: Maybe<Scalars['Boolean']>
};

export type Query = {
   __typename?: 'Query',
  ping?: Maybe<Scalars['String']>,
  /** This query is used to query for entries. */
  entries?: Maybe<Array<Maybe<EntryInterface>>>,
  /** This query is used to query for assets. */
  assets?: Maybe<Array<Maybe<AssetInterface>>>,
  /** This query is used to query for users. */
  users?: Maybe<Array<Maybe<UserInterface>>>,
  /** This query is used to query for global sets. */
  globalSets?: Maybe<Array<Maybe<GlobalSetInterface>>>,
};


export type QueryEntriesArgs = {
  id?: Maybe<Array<Maybe<Scalars['Int']>>>,
  uid?: Maybe<Array<Maybe<Scalars['String']>>>,
  status?: Maybe<Array<Maybe<Scalars['String']>>>,
  archived?: Maybe<Scalars['Boolean']>,
  trashed?: Maybe<Scalars['Boolean']>,
  site?: Maybe<Array<Maybe<Scalars['String']>>>,
  siteId?: Maybe<Scalars['String']>,
  unique?: Maybe<Scalars['Boolean']>,
  enabledForSite?: Maybe<Scalars['Boolean']>,
  title?: Maybe<Array<Maybe<Scalars['String']>>>,
  slug?: Maybe<Array<Maybe<Scalars['String']>>>,
  uri?: Maybe<Array<Maybe<Scalars['String']>>>,
  search?: Maybe<Scalars['String']>,
  relatedTo?: Maybe<Array<Maybe<Scalars['Int']>>>,
  relatedToAll?: Maybe<Array<Maybe<Scalars['Int']>>>,
  ref?: Maybe<Array<Maybe<Scalars['String']>>>,
  fixedOrder?: Maybe<Scalars['Boolean']>,
  inReverse?: Maybe<Scalars['Boolean']>,
  dateCreated?: Maybe<Scalars['String']>,
  dateUpdated?: Maybe<Scalars['String']>,
  offset?: Maybe<Scalars['Int']>,
  limit?: Maybe<Scalars['Int']>,
  orderBy?: Maybe<Scalars['String']>,
  withStructure?: Maybe<Scalars['Boolean']>,
  structureId?: Maybe<Scalars['Int']>,
  level?: Maybe<Scalars['Int']>,
  hasDescendants?: Maybe<Scalars['Boolean']>,
  ancestorOf?: Maybe<Scalars['Int']>,
  ancestorDist?: Maybe<Scalars['Int']>,
  descendantOf?: Maybe<Scalars['Int']>,
  descendantDist?: Maybe<Scalars['Int']>,
  leaves?: Maybe<Scalars['Boolean']>,
  editable?: Maybe<Scalars['Boolean']>,
  section?: Maybe<Array<Maybe<Scalars['String']>>>,
  sectionId?: Maybe<Array<Maybe<Scalars['Int']>>>,
  type?: Maybe<Array<Maybe<Scalars['String']>>>,
  typeId?: Maybe<Array<Maybe<Scalars['Int']>>>,
  authorId?: Maybe<Array<Maybe<Scalars['Int']>>>,
  authorGroup?: Maybe<Array<Maybe<Scalars['String']>>>,
  postDate?: Maybe<Scalars['String']>,
  before?: Maybe<Scalars['String']>,
  after?: Maybe<Scalars['String']>,
  expiryDate?: Maybe<Scalars['String']>
};


export type QueryAssetsArgs = {
  id?: Maybe<Array<Maybe<Scalars['Int']>>>,
  uid?: Maybe<Array<Maybe<Scalars['String']>>>,
  status?: Maybe<Array<Maybe<Scalars['String']>>>,
  archived?: Maybe<Scalars['Boolean']>,
  trashed?: Maybe<Scalars['Boolean']>,
  site?: Maybe<Array<Maybe<Scalars['String']>>>,
  siteId?: Maybe<Scalars['String']>,
  unique?: Maybe<Scalars['Boolean']>,
  enabledForSite?: Maybe<Scalars['Boolean']>,
  title?: Maybe<Array<Maybe<Scalars['String']>>>,
  slug?: Maybe<Array<Maybe<Scalars['String']>>>,
  uri?: Maybe<Array<Maybe<Scalars['String']>>>,
  search?: Maybe<Scalars['String']>,
  relatedTo?: Maybe<Array<Maybe<Scalars['Int']>>>,
  relatedToAll?: Maybe<Array<Maybe<Scalars['Int']>>>,
  ref?: Maybe<Array<Maybe<Scalars['String']>>>,
  fixedOrder?: Maybe<Scalars['Boolean']>,
  inReverse?: Maybe<Scalars['Boolean']>,
  dateCreated?: Maybe<Scalars['String']>,
  dateUpdated?: Maybe<Scalars['String']>,
  offset?: Maybe<Scalars['Int']>,
  limit?: Maybe<Scalars['Int']>,
  orderBy?: Maybe<Scalars['String']>,
  volumeId?: Maybe<Array<Maybe<Scalars['Int']>>>,
  volume?: Maybe<Array<Maybe<Scalars['String']>>>,
  folderId?: Maybe<Array<Maybe<Scalars['Int']>>>,
  filename?: Maybe<Array<Maybe<Scalars['String']>>>,
  kind?: Maybe<Array<Maybe<Scalars['String']>>>,
  height?: Maybe<Scalars['String']>,
  width?: Maybe<Scalars['String']>,
  size?: Maybe<Scalars['String']>,
  dateModified?: Maybe<Scalars['String']>,
  includeSubfolders?: Maybe<Scalars['Boolean']>
};


export type QueryUsersArgs = {
  id?: Maybe<Array<Maybe<Scalars['Int']>>>,
  uid?: Maybe<Array<Maybe<Scalars['String']>>>,
  status?: Maybe<Array<Maybe<Scalars['String']>>>,
  archived?: Maybe<Scalars['Boolean']>,
  trashed?: Maybe<Scalars['Boolean']>,
  site?: Maybe<Array<Maybe<Scalars['String']>>>,
  siteId?: Maybe<Scalars['String']>,
  unique?: Maybe<Scalars['Boolean']>,
  enabledForSite?: Maybe<Scalars['Boolean']>,
  title?: Maybe<Array<Maybe<Scalars['String']>>>,
  slug?: Maybe<Array<Maybe<Scalars['String']>>>,
  uri?: Maybe<Array<Maybe<Scalars['String']>>>,
  search?: Maybe<Scalars['String']>,
  relatedTo?: Maybe<Array<Maybe<Scalars['Int']>>>,
  relatedToAll?: Maybe<Array<Maybe<Scalars['Int']>>>,
  ref?: Maybe<Array<Maybe<Scalars['String']>>>,
  fixedOrder?: Maybe<Scalars['Boolean']>,
  inReverse?: Maybe<Scalars['Boolean']>,
  dateCreated?: Maybe<Scalars['String']>,
  dateUpdated?: Maybe<Scalars['String']>,
  offset?: Maybe<Scalars['Int']>,
  limit?: Maybe<Scalars['Int']>,
  orderBy?: Maybe<Scalars['String']>,
  email?: Maybe<Array<Maybe<Scalars['String']>>>,
  username?: Maybe<Array<Maybe<Scalars['String']>>>,
  firstName?: Maybe<Array<Maybe<Scalars['String']>>>,
  lastName?: Maybe<Array<Maybe<Scalars['String']>>>
};


export type QueryGlobalSetsArgs = {
  id?: Maybe<Array<Maybe<Scalars['Int']>>>,
  uid?: Maybe<Array<Maybe<Scalars['String']>>>,
  status?: Maybe<Array<Maybe<Scalars['String']>>>,
  archived?: Maybe<Scalars['Boolean']>,
  trashed?: Maybe<Scalars['Boolean']>,
  site?: Maybe<Array<Maybe<Scalars['String']>>>,
  siteId?: Maybe<Scalars['String']>,
  unique?: Maybe<Scalars['Boolean']>,
  enabledForSite?: Maybe<Scalars['Boolean']>,
  title?: Maybe<Array<Maybe<Scalars['String']>>>,
  slug?: Maybe<Array<Maybe<Scalars['String']>>>,
  uri?: Maybe<Array<Maybe<Scalars['String']>>>,
  search?: Maybe<Scalars['String']>,
  relatedTo?: Maybe<Array<Maybe<Scalars['Int']>>>,
  relatedToAll?: Maybe<Array<Maybe<Scalars['Int']>>>,
  ref?: Maybe<Array<Maybe<Scalars['String']>>>,
  fixedOrder?: Maybe<Scalars['Boolean']>,
  inReverse?: Maybe<Scalars['Boolean']>,
  dateCreated?: Maybe<Scalars['String']>,
  dateUpdated?: Maybe<Scalars['String']>,
  offset?: Maybe<Scalars['Int']>,
  limit?: Maybe<Scalars['Int']>,
  orderBy?: Maybe<Scalars['String']>,
  handle?: Maybe<Array<Maybe<Scalars['String']>>>
};

export type S3_Asset = AssetInterface & ElementInterface & {
   __typename?: 's3_Asset',
  /** The id of the entity */
  id?: Maybe<Scalars['ID']>,
  /** The uid of the entity */
  uid?: Maybe<Scalars['String']>,
  /** The element’s title. */
  title?: Maybe<Scalars['String']>,
  /** The element’s slug. */
  slug?: Maybe<Scalars['String']>,
  /** The element’s URI. */
  uri?: Maybe<Scalars['String']>,
  /** Whether the element is enabled or not. */
  enabled?: Maybe<Scalars['Boolean']>,
  /** Whether the element is archived or not. */
  archived?: Maybe<Scalars['Boolean']>,
  /** The ID of the site the element is associated with. */
  siteId?: Maybe<Scalars['Int']>,
  /** The element’s search score, if the `search` parameter was used when querying for the element. */
  searchScore?: Maybe<Scalars['String']>,
  /** Whether the element has been soft-deleted or not. */
  trashed?: Maybe<Scalars['Boolean']>,
  /** The element's status. */
  status?: Maybe<Scalars['String']>,
  /** The date the element was created. */
  dateCreated?: Maybe<Scalars['DateTime']>,
  /** The date the element was last updated. */
  dateUpdated?: Maybe<Scalars['DateTime']>,
  /** The ID of the volume that the asset belongs to. */
  volumeId?: Maybe<Scalars['Int']>,
  /** The ID of the folder that the asset belongs to. */
  folderId?: Maybe<Scalars['Int']>,
  /** The filename of the asset file. */
  filename?: Maybe<Scalars['String']>,
  /** The file extension for the asset file. */
  extension?: Maybe<Scalars['String']>,
  /** Whether a user-defined focal point is set on the asset. */
  hasFocalPoint?: Maybe<Scalars['Boolean']>,
  /** The focal point represented as an array with `x` and `y` keys, or null if it's not an image. */
  focalPoint?: Maybe<Array<Maybe<Scalars['Float']>>>,
  /** The file kind. */
  kind?: Maybe<Scalars['String']>,
  /** The file size in bytes. */
  size?: Maybe<Scalars['String']>,
  /** The height in pixels or null if it's not an image. */
  height?: Maybe<Scalars['Int']>,
  /** The width in pixels or null if it's not an image. */
  width?: Maybe<Scalars['Int']>,
  /** An `<img>` tag based on this asset. */
  img?: Maybe<Scalars['String']>,
  /** The full URL of the asset. This field accepts the same fields as the `transform` directive. */
  url?: Maybe<Scalars['String']>,
  /** The file’s MIME type, if it can be determined. */
  mimeType?: Maybe<Scalars['String']>,
  /** The asset's path in the volume. */
  path?: Maybe<Scalars['String']>,
  /** The date the asset file was last modified. */
  dateModified?: Maybe<Scalars['DateTime']>,
  altText?: Maybe<Scalars['String']>,
};


export type S3_AssetUrlArgs = {
  handle?: Maybe<Scalars['String']>,
  transform?: Maybe<Scalars['String']>,
  width?: Maybe<Scalars['Int']>,
  height?: Maybe<Scalars['Int']>,
  mode?: Maybe<Scalars['String']>,
  position?: Maybe<Scalars['String']>,
  interlace?: Maybe<Scalars['String']>,
  quality?: Maybe<Scalars['Int']>,
  format?: Maybe<Scalars['String']>,
  immediately?: Maybe<Scalars['Boolean']>
};

export type Services_Services_Entry = EntryInterface & ElementInterface & {
   __typename?: 'services_services_Entry',
  /** The id of the entity */
  id?: Maybe<Scalars['ID']>,
  /** The uid of the entity */
  uid?: Maybe<Scalars['String']>,
  /** The element’s title. */
  title?: Maybe<Scalars['String']>,
  /** The element’s slug. */
  slug?: Maybe<Scalars['String']>,
  /** The element’s URI. */
  uri?: Maybe<Scalars['String']>,
  /** Whether the element is enabled or not. */
  enabled?: Maybe<Scalars['Boolean']>,
  /** Whether the element is archived or not. */
  archived?: Maybe<Scalars['Boolean']>,
  /** The ID of the site the element is associated with. */
  siteId?: Maybe<Scalars['Int']>,
  /** The element’s search score, if the `search` parameter was used when querying for the element. */
  searchScore?: Maybe<Scalars['String']>,
  /** Whether the element has been soft-deleted or not. */
  trashed?: Maybe<Scalars['Boolean']>,
  /** The element's status. */
  status?: Maybe<Scalars['String']>,
  /** The date the element was created. */
  dateCreated?: Maybe<Scalars['DateTime']>,
  /** The date the element was last updated. */
  dateUpdated?: Maybe<Scalars['DateTime']>,
  /** The element’s left position within its structure. */
  lft?: Maybe<Scalars['Int']>,
  /** The element’s right position within its structure. */
  rgt?: Maybe<Scalars['Int']>,
  /** The element’s level within its structure */
  level?: Maybe<Scalars['Int']>,
  /** The element’s structure’s root ID */
  root?: Maybe<Scalars['Int']>,
  /** The element’s structure ID. */
  structureId?: Maybe<Scalars['Int']>,
  /** The ID of the author of this entry. */
  authorId?: Maybe<Scalars['Int']>,
  /** The entry's author. */
  author?: Maybe<UserInterface>,
  /** The ID of the section that contains the entry. */
  sectionId?: Maybe<Scalars['Int']>,
  /** The handle of the section that contains the entry. */
  sectionHandle?: Maybe<Scalars['String']>,
  /** The ID of the entry type that contains the entry. */
  typeId?: Maybe<Scalars['Int']>,
  /** The handle of the entry type that contains the entry. */
  typeHandle?: Maybe<Scalars['String']>,
  /** The entry's post date. */
  postDate?: Maybe<Scalars['DateTime']>,
  /** The expiry date of the entry. */
  expiryDate?: Maybe<Scalars['DateTime']>,
  /** The entry’s children, if the section is a structure. Accepts the same arguments as the `entries` query. */
  children?: Maybe<Array<Maybe<EntryInterface>>>,
  /** The entry’s parent, if the section is a structure. */
  parent?: Maybe<EntryInterface>,
  /** The element’s full URL */
  url?: Maybe<Scalars['String']>,
  runner?: Maybe<Scalars['String']>,
  introText?: Maybe<Scalars['String']>,
  mainImage?: Maybe<Array<Maybe<AssetInterface>>>,
  tileImage?: Maybe<Array<Maybe<AssetInterface>>>,
  colors?: Maybe<Scalars['String']>,
  textSections?: Maybe<Array<Maybe<TextSections_MatrixField>>>,
  linkTitle?: Maybe<Scalars['String']>,
  linkDescription?: Maybe<Scalars['String']>,
  linkDestination?: Maybe<Array<Maybe<EntryInterface>>>,
  socialTitle?: Maybe<Scalars['String']>,
  socialDescription?: Maybe<Scalars['String']>,
  socialImage?: Maybe<Array<Maybe<AssetInterface>>>,
};


export type Services_Services_EntryChildrenArgs = {
  id?: Maybe<Array<Maybe<Scalars['Int']>>>,
  uid?: Maybe<Array<Maybe<Scalars['String']>>>,
  status?: Maybe<Array<Maybe<Scalars['String']>>>,
  archived?: Maybe<Scalars['Boolean']>,
  trashed?: Maybe<Scalars['Boolean']>,
  site?: Maybe<Array<Maybe<Scalars['String']>>>,
  siteId?: Maybe<Scalars['String']>,
  unique?: Maybe<Scalars['Boolean']>,
  enabledForSite?: Maybe<Scalars['Boolean']>,
  title?: Maybe<Array<Maybe<Scalars['String']>>>,
  slug?: Maybe<Array<Maybe<Scalars['String']>>>,
  uri?: Maybe<Array<Maybe<Scalars['String']>>>,
  search?: Maybe<Scalars['String']>,
  relatedTo?: Maybe<Array<Maybe<Scalars['Int']>>>,
  relatedToAll?: Maybe<Array<Maybe<Scalars['Int']>>>,
  ref?: Maybe<Array<Maybe<Scalars['String']>>>,
  fixedOrder?: Maybe<Scalars['Boolean']>,
  inReverse?: Maybe<Scalars['Boolean']>,
  dateCreated?: Maybe<Scalars['String']>,
  dateUpdated?: Maybe<Scalars['String']>,
  offset?: Maybe<Scalars['Int']>,
  limit?: Maybe<Scalars['Int']>,
  orderBy?: Maybe<Scalars['String']>,
  withStructure?: Maybe<Scalars['Boolean']>,
  structureId?: Maybe<Scalars['Int']>,
  level?: Maybe<Scalars['Int']>,
  hasDescendants?: Maybe<Scalars['Boolean']>,
  ancestorOf?: Maybe<Scalars['Int']>,
  ancestorDist?: Maybe<Scalars['Int']>,
  descendantOf?: Maybe<Scalars['Int']>,
  descendantDist?: Maybe<Scalars['Int']>,
  leaves?: Maybe<Scalars['Boolean']>,
  editable?: Maybe<Scalars['Boolean']>,
  section?: Maybe<Array<Maybe<Scalars['String']>>>,
  sectionId?: Maybe<Array<Maybe<Scalars['Int']>>>,
  type?: Maybe<Array<Maybe<Scalars['String']>>>,
  typeId?: Maybe<Array<Maybe<Scalars['Int']>>>,
  authorId?: Maybe<Array<Maybe<Scalars['Int']>>>,
  authorGroup?: Maybe<Array<Maybe<Scalars['String']>>>,
  postDate?: Maybe<Scalars['String']>,
  before?: Maybe<Scalars['String']>,
  after?: Maybe<Scalars['String']>,
  expiryDate?: Maybe<Scalars['String']>
};


export type Services_Services_EntryMainImageArgs = {
  id?: Maybe<Array<Maybe<Scalars['Int']>>>,
  uid?: Maybe<Array<Maybe<Scalars['String']>>>,
  status?: Maybe<Array<Maybe<Scalars['String']>>>,
  archived?: Maybe<Scalars['Boolean']>,
  trashed?: Maybe<Scalars['Boolean']>,
  site?: Maybe<Array<Maybe<Scalars['String']>>>,
  siteId?: Maybe<Scalars['String']>,
  unique?: Maybe<Scalars['Boolean']>,
  enabledForSite?: Maybe<Scalars['Boolean']>,
  title?: Maybe<Array<Maybe<Scalars['String']>>>,
  slug?: Maybe<Array<Maybe<Scalars['String']>>>,
  uri?: Maybe<Array<Maybe<Scalars['String']>>>,
  search?: Maybe<Scalars['String']>,
  relatedTo?: Maybe<Array<Maybe<Scalars['Int']>>>,
  relatedToAll?: Maybe<Array<Maybe<Scalars['Int']>>>,
  ref?: Maybe<Array<Maybe<Scalars['String']>>>,
  fixedOrder?: Maybe<Scalars['Boolean']>,
  inReverse?: Maybe<Scalars['Boolean']>,
  dateCreated?: Maybe<Scalars['String']>,
  dateUpdated?: Maybe<Scalars['String']>,
  offset?: Maybe<Scalars['Int']>,
  limit?: Maybe<Scalars['Int']>,
  orderBy?: Maybe<Scalars['String']>,
  volumeId?: Maybe<Array<Maybe<Scalars['Int']>>>,
  volume?: Maybe<Array<Maybe<Scalars['String']>>>,
  folderId?: Maybe<Array<Maybe<Scalars['Int']>>>,
  filename?: Maybe<Array<Maybe<Scalars['String']>>>,
  kind?: Maybe<Array<Maybe<Scalars['String']>>>,
  height?: Maybe<Scalars['String']>,
  width?: Maybe<Scalars['String']>,
  size?: Maybe<Scalars['String']>,
  dateModified?: Maybe<Scalars['String']>,
  includeSubfolders?: Maybe<Scalars['Boolean']>
};


export type Services_Services_EntryTileImageArgs = {
  id?: Maybe<Array<Maybe<Scalars['Int']>>>,
  uid?: Maybe<Array<Maybe<Scalars['String']>>>,
  status?: Maybe<Array<Maybe<Scalars['String']>>>,
  archived?: Maybe<Scalars['Boolean']>,
  trashed?: Maybe<Scalars['Boolean']>,
  site?: Maybe<Array<Maybe<Scalars['String']>>>,
  siteId?: Maybe<Scalars['String']>,
  unique?: Maybe<Scalars['Boolean']>,
  enabledForSite?: Maybe<Scalars['Boolean']>,
  title?: Maybe<Array<Maybe<Scalars['String']>>>,
  slug?: Maybe<Array<Maybe<Scalars['String']>>>,
  uri?: Maybe<Array<Maybe<Scalars['String']>>>,
  search?: Maybe<Scalars['String']>,
  relatedTo?: Maybe<Array<Maybe<Scalars['Int']>>>,
  relatedToAll?: Maybe<Array<Maybe<Scalars['Int']>>>,
  ref?: Maybe<Array<Maybe<Scalars['String']>>>,
  fixedOrder?: Maybe<Scalars['Boolean']>,
  inReverse?: Maybe<Scalars['Boolean']>,
  dateCreated?: Maybe<Scalars['String']>,
  dateUpdated?: Maybe<Scalars['String']>,
  offset?: Maybe<Scalars['Int']>,
  limit?: Maybe<Scalars['Int']>,
  orderBy?: Maybe<Scalars['String']>,
  volumeId?: Maybe<Array<Maybe<Scalars['Int']>>>,
  volume?: Maybe<Array<Maybe<Scalars['String']>>>,
  folderId?: Maybe<Array<Maybe<Scalars['Int']>>>,
  filename?: Maybe<Array<Maybe<Scalars['String']>>>,
  kind?: Maybe<Array<Maybe<Scalars['String']>>>,
  height?: Maybe<Scalars['String']>,
  width?: Maybe<Scalars['String']>,
  size?: Maybe<Scalars['String']>,
  dateModified?: Maybe<Scalars['String']>,
  includeSubfolders?: Maybe<Scalars['Boolean']>
};


export type Services_Services_EntryTextSectionsArgs = {
  id?: Maybe<Array<Maybe<Scalars['Int']>>>,
  uid?: Maybe<Array<Maybe<Scalars['String']>>>,
  status?: Maybe<Array<Maybe<Scalars['String']>>>,
  archived?: Maybe<Scalars['Boolean']>,
  trashed?: Maybe<Scalars['Boolean']>,
  site?: Maybe<Array<Maybe<Scalars['String']>>>,
  siteId?: Maybe<Scalars['String']>,
  unique?: Maybe<Scalars['Boolean']>,
  enabledForSite?: Maybe<Scalars['Boolean']>,
  title?: Maybe<Array<Maybe<Scalars['String']>>>,
  slug?: Maybe<Array<Maybe<Scalars['String']>>>,
  uri?: Maybe<Array<Maybe<Scalars['String']>>>,
  search?: Maybe<Scalars['String']>,
  relatedTo?: Maybe<Array<Maybe<Scalars['Int']>>>,
  relatedToAll?: Maybe<Array<Maybe<Scalars['Int']>>>,
  ref?: Maybe<Array<Maybe<Scalars['String']>>>,
  fixedOrder?: Maybe<Scalars['Boolean']>,
  inReverse?: Maybe<Scalars['Boolean']>,
  dateCreated?: Maybe<Scalars['String']>,
  dateUpdated?: Maybe<Scalars['String']>,
  offset?: Maybe<Scalars['Int']>,
  limit?: Maybe<Scalars['Int']>,
  orderBy?: Maybe<Scalars['String']>,
  fieldId?: Maybe<Array<Maybe<Scalars['Int']>>>,
  ownerId?: Maybe<Array<Maybe<Scalars['String']>>>,
  typeId?: Maybe<Array<Maybe<Scalars['Int']>>>,
  type?: Maybe<Array<Maybe<Scalars['String']>>>
};


export type Services_Services_EntryLinkDestinationArgs = {
  id?: Maybe<Array<Maybe<Scalars['Int']>>>,
  uid?: Maybe<Array<Maybe<Scalars['String']>>>,
  status?: Maybe<Array<Maybe<Scalars['String']>>>,
  archived?: Maybe<Scalars['Boolean']>,
  trashed?: Maybe<Scalars['Boolean']>,
  site?: Maybe<Array<Maybe<Scalars['String']>>>,
  siteId?: Maybe<Scalars['String']>,
  unique?: Maybe<Scalars['Boolean']>,
  enabledForSite?: Maybe<Scalars['Boolean']>,
  title?: Maybe<Array<Maybe<Scalars['String']>>>,
  slug?: Maybe<Array<Maybe<Scalars['String']>>>,
  uri?: Maybe<Array<Maybe<Scalars['String']>>>,
  search?: Maybe<Scalars['String']>,
  relatedTo?: Maybe<Array<Maybe<Scalars['Int']>>>,
  relatedToAll?: Maybe<Array<Maybe<Scalars['Int']>>>,
  ref?: Maybe<Array<Maybe<Scalars['String']>>>,
  fixedOrder?: Maybe<Scalars['Boolean']>,
  inReverse?: Maybe<Scalars['Boolean']>,
  dateCreated?: Maybe<Scalars['String']>,
  dateUpdated?: Maybe<Scalars['String']>,
  offset?: Maybe<Scalars['Int']>,
  limit?: Maybe<Scalars['Int']>,
  orderBy?: Maybe<Scalars['String']>,
  withStructure?: Maybe<Scalars['Boolean']>,
  structureId?: Maybe<Scalars['Int']>,
  level?: Maybe<Scalars['Int']>,
  hasDescendants?: Maybe<Scalars['Boolean']>,
  ancestorOf?: Maybe<Scalars['Int']>,
  ancestorDist?: Maybe<Scalars['Int']>,
  descendantOf?: Maybe<Scalars['Int']>,
  descendantDist?: Maybe<Scalars['Int']>,
  leaves?: Maybe<Scalars['Boolean']>,
  editable?: Maybe<Scalars['Boolean']>,
  section?: Maybe<Array<Maybe<Scalars['String']>>>,
  sectionId?: Maybe<Array<Maybe<Scalars['Int']>>>,
  type?: Maybe<Array<Maybe<Scalars['String']>>>,
  typeId?: Maybe<Array<Maybe<Scalars['Int']>>>,
  authorId?: Maybe<Array<Maybe<Scalars['Int']>>>,
  authorGroup?: Maybe<Array<Maybe<Scalars['String']>>>,
  postDate?: Maybe<Scalars['String']>,
  before?: Maybe<Scalars['String']>,
  after?: Maybe<Scalars['String']>,
  expiryDate?: Maybe<Scalars['String']>
};


export type Services_Services_EntrySocialImageArgs = {
  id?: Maybe<Array<Maybe<Scalars['Int']>>>,
  uid?: Maybe<Array<Maybe<Scalars['String']>>>,
  status?: Maybe<Array<Maybe<Scalars['String']>>>,
  archived?: Maybe<Scalars['Boolean']>,
  trashed?: Maybe<Scalars['Boolean']>,
  site?: Maybe<Array<Maybe<Scalars['String']>>>,
  siteId?: Maybe<Scalars['String']>,
  unique?: Maybe<Scalars['Boolean']>,
  enabledForSite?: Maybe<Scalars['Boolean']>,
  title?: Maybe<Array<Maybe<Scalars['String']>>>,
  slug?: Maybe<Array<Maybe<Scalars['String']>>>,
  uri?: Maybe<Array<Maybe<Scalars['String']>>>,
  search?: Maybe<Scalars['String']>,
  relatedTo?: Maybe<Array<Maybe<Scalars['Int']>>>,
  relatedToAll?: Maybe<Array<Maybe<Scalars['Int']>>>,
  ref?: Maybe<Array<Maybe<Scalars['String']>>>,
  fixedOrder?: Maybe<Scalars['Boolean']>,
  inReverse?: Maybe<Scalars['Boolean']>,
  dateCreated?: Maybe<Scalars['String']>,
  dateUpdated?: Maybe<Scalars['String']>,
  offset?: Maybe<Scalars['Int']>,
  limit?: Maybe<Scalars['Int']>,
  orderBy?: Maybe<Scalars['String']>,
  volumeId?: Maybe<Array<Maybe<Scalars['Int']>>>,
  volume?: Maybe<Array<Maybe<Scalars['String']>>>,
  folderId?: Maybe<Array<Maybe<Scalars['Int']>>>,
  filename?: Maybe<Array<Maybe<Scalars['String']>>>,
  kind?: Maybe<Array<Maybe<Scalars['String']>>>,
  height?: Maybe<Scalars['String']>,
  width?: Maybe<Scalars['String']>,
  size?: Maybe<Scalars['String']>,
  dateModified?: Maybe<Scalars['String']>,
  includeSubfolders?: Maybe<Scalars['Boolean']>
};

export type ServicesPage_ServicesPage_Entry = EntryInterface & ElementInterface & {
   __typename?: 'servicesPage_servicesPage_Entry',
  /** The id of the entity */
  id?: Maybe<Scalars['ID']>,
  /** The uid of the entity */
  uid?: Maybe<Scalars['String']>,
  /** The element’s title. */
  title?: Maybe<Scalars['String']>,
  /** The element’s slug. */
  slug?: Maybe<Scalars['String']>,
  /** The element’s URI. */
  uri?: Maybe<Scalars['String']>,
  /** Whether the element is enabled or not. */
  enabled?: Maybe<Scalars['Boolean']>,
  /** Whether the element is archived or not. */
  archived?: Maybe<Scalars['Boolean']>,
  /** The ID of the site the element is associated with. */
  siteId?: Maybe<Scalars['Int']>,
  /** The element’s search score, if the `search` parameter was used when querying for the element. */
  searchScore?: Maybe<Scalars['String']>,
  /** Whether the element has been soft-deleted or not. */
  trashed?: Maybe<Scalars['Boolean']>,
  /** The element's status. */
  status?: Maybe<Scalars['String']>,
  /** The date the element was created. */
  dateCreated?: Maybe<Scalars['DateTime']>,
  /** The date the element was last updated. */
  dateUpdated?: Maybe<Scalars['DateTime']>,
  /** The element’s left position within its structure. */
  lft?: Maybe<Scalars['Int']>,
  /** The element’s right position within its structure. */
  rgt?: Maybe<Scalars['Int']>,
  /** The element’s level within its structure */
  level?: Maybe<Scalars['Int']>,
  /** The element’s structure’s root ID */
  root?: Maybe<Scalars['Int']>,
  /** The element’s structure ID. */
  structureId?: Maybe<Scalars['Int']>,
  /** The ID of the author of this entry. */
  authorId?: Maybe<Scalars['Int']>,
  /** The entry's author. */
  author?: Maybe<UserInterface>,
  /** The ID of the section that contains the entry. */
  sectionId?: Maybe<Scalars['Int']>,
  /** The handle of the section that contains the entry. */
  sectionHandle?: Maybe<Scalars['String']>,
  /** The ID of the entry type that contains the entry. */
  typeId?: Maybe<Scalars['Int']>,
  /** The handle of the entry type that contains the entry. */
  typeHandle?: Maybe<Scalars['String']>,
  /** The entry's post date. */
  postDate?: Maybe<Scalars['DateTime']>,
  /** The expiry date of the entry. */
  expiryDate?: Maybe<Scalars['DateTime']>,
  /** The entry’s children, if the section is a structure. Accepts the same arguments as the `entries` query. */
  children?: Maybe<Array<Maybe<EntryInterface>>>,
  /** The entry’s parent, if the section is a structure. */
  parent?: Maybe<EntryInterface>,
  /** The element’s full URL */
  url?: Maybe<Scalars['String']>,
  runner?: Maybe<Scalars['String']>,
  socialDescription?: Maybe<Scalars['String']>,
  socialImage?: Maybe<Array<Maybe<AssetInterface>>>,
  socialTitle?: Maybe<Scalars['String']>,
};


export type ServicesPage_ServicesPage_EntryChildrenArgs = {
  id?: Maybe<Array<Maybe<Scalars['Int']>>>,
  uid?: Maybe<Array<Maybe<Scalars['String']>>>,
  status?: Maybe<Array<Maybe<Scalars['String']>>>,
  archived?: Maybe<Scalars['Boolean']>,
  trashed?: Maybe<Scalars['Boolean']>,
  site?: Maybe<Array<Maybe<Scalars['String']>>>,
  siteId?: Maybe<Scalars['String']>,
  unique?: Maybe<Scalars['Boolean']>,
  enabledForSite?: Maybe<Scalars['Boolean']>,
  title?: Maybe<Array<Maybe<Scalars['String']>>>,
  slug?: Maybe<Array<Maybe<Scalars['String']>>>,
  uri?: Maybe<Array<Maybe<Scalars['String']>>>,
  search?: Maybe<Scalars['String']>,
  relatedTo?: Maybe<Array<Maybe<Scalars['Int']>>>,
  relatedToAll?: Maybe<Array<Maybe<Scalars['Int']>>>,
  ref?: Maybe<Array<Maybe<Scalars['String']>>>,
  fixedOrder?: Maybe<Scalars['Boolean']>,
  inReverse?: Maybe<Scalars['Boolean']>,
  dateCreated?: Maybe<Scalars['String']>,
  dateUpdated?: Maybe<Scalars['String']>,
  offset?: Maybe<Scalars['Int']>,
  limit?: Maybe<Scalars['Int']>,
  orderBy?: Maybe<Scalars['String']>,
  withStructure?: Maybe<Scalars['Boolean']>,
  structureId?: Maybe<Scalars['Int']>,
  level?: Maybe<Scalars['Int']>,
  hasDescendants?: Maybe<Scalars['Boolean']>,
  ancestorOf?: Maybe<Scalars['Int']>,
  ancestorDist?: Maybe<Scalars['Int']>,
  descendantOf?: Maybe<Scalars['Int']>,
  descendantDist?: Maybe<Scalars['Int']>,
  leaves?: Maybe<Scalars['Boolean']>,
  editable?: Maybe<Scalars['Boolean']>,
  section?: Maybe<Array<Maybe<Scalars['String']>>>,
  sectionId?: Maybe<Array<Maybe<Scalars['Int']>>>,
  type?: Maybe<Array<Maybe<Scalars['String']>>>,
  typeId?: Maybe<Array<Maybe<Scalars['Int']>>>,
  authorId?: Maybe<Array<Maybe<Scalars['Int']>>>,
  authorGroup?: Maybe<Array<Maybe<Scalars['String']>>>,
  postDate?: Maybe<Scalars['String']>,
  before?: Maybe<Scalars['String']>,
  after?: Maybe<Scalars['String']>,
  expiryDate?: Maybe<Scalars['String']>
};


export type ServicesPage_ServicesPage_EntrySocialImageArgs = {
  id?: Maybe<Array<Maybe<Scalars['Int']>>>,
  uid?: Maybe<Array<Maybe<Scalars['String']>>>,
  status?: Maybe<Array<Maybe<Scalars['String']>>>,
  archived?: Maybe<Scalars['Boolean']>,
  trashed?: Maybe<Scalars['Boolean']>,
  site?: Maybe<Array<Maybe<Scalars['String']>>>,
  siteId?: Maybe<Scalars['String']>,
  unique?: Maybe<Scalars['Boolean']>,
  enabledForSite?: Maybe<Scalars['Boolean']>,
  title?: Maybe<Array<Maybe<Scalars['String']>>>,
  slug?: Maybe<Array<Maybe<Scalars['String']>>>,
  uri?: Maybe<Array<Maybe<Scalars['String']>>>,
  search?: Maybe<Scalars['String']>,
  relatedTo?: Maybe<Array<Maybe<Scalars['Int']>>>,
  relatedToAll?: Maybe<Array<Maybe<Scalars['Int']>>>,
  ref?: Maybe<Array<Maybe<Scalars['String']>>>,
  fixedOrder?: Maybe<Scalars['Boolean']>,
  inReverse?: Maybe<Scalars['Boolean']>,
  dateCreated?: Maybe<Scalars['String']>,
  dateUpdated?: Maybe<Scalars['String']>,
  offset?: Maybe<Scalars['Int']>,
  limit?: Maybe<Scalars['Int']>,
  orderBy?: Maybe<Scalars['String']>,
  volumeId?: Maybe<Array<Maybe<Scalars['Int']>>>,
  volume?: Maybe<Array<Maybe<Scalars['String']>>>,
  folderId?: Maybe<Array<Maybe<Scalars['Int']>>>,
  filename?: Maybe<Array<Maybe<Scalars['String']>>>,
  kind?: Maybe<Array<Maybe<Scalars['String']>>>,
  height?: Maybe<Scalars['String']>,
  width?: Maybe<Scalars['String']>,
  size?: Maybe<Scalars['String']>,
  dateModified?: Maybe<Scalars['String']>,
  includeSubfolders?: Maybe<Scalars['Boolean']>
};

export type Social_EmailAddress_BlockType = MatrixBlockInterface & ElementInterface & {
   __typename?: 'social_emailAddress_BlockType',
  /** The id of the entity */
  id?: Maybe<Scalars['ID']>,
  /** The uid of the entity */
  uid?: Maybe<Scalars['String']>,
  /** The element’s title. */
  title?: Maybe<Scalars['String']>,
  /** The element’s slug. */
  slug?: Maybe<Scalars['String']>,
  /** The element’s URI. */
  uri?: Maybe<Scalars['String']>,
  /** Whether the element is enabled or not. */
  enabled?: Maybe<Scalars['Boolean']>,
  /** Whether the element is archived or not. */
  archived?: Maybe<Scalars['Boolean']>,
  /** The ID of the site the element is associated with. */
  siteId?: Maybe<Scalars['Int']>,
  /** The element’s search score, if the `search` parameter was used when querying for the element. */
  searchScore?: Maybe<Scalars['String']>,
  /** Whether the element has been soft-deleted or not. */
  trashed?: Maybe<Scalars['Boolean']>,
  /** The element's status. */
  status?: Maybe<Scalars['String']>,
  /** The date the element was created. */
  dateCreated?: Maybe<Scalars['DateTime']>,
  /** The date the element was last updated. */
  dateUpdated?: Maybe<Scalars['DateTime']>,
  /** The ID of the field that owns the matrix block. */
  fieldId?: Maybe<Scalars['Int']>,
  /** The ID of the element that owns the matrix block. */
  ownerId?: Maybe<Scalars['Int']>,
  /** The ID of the matrix block's type. */
  typeId?: Maybe<Scalars['Int']>,
  /** The handle of the matrix block's type. */
  typeHandle?: Maybe<Scalars['String']>,
  /** The sort order of the matrix block within the owner element field. */
  sortOrder?: Maybe<Scalars['Int']>,
  email?: Maybe<Scalars['String']>,
};

export type Social_MatrixField = Social_SocialLink_BlockType | Social_EmailAddress_BlockType;

export type Social_SocialLink_BlockType = MatrixBlockInterface & ElementInterface & {
   __typename?: 'social_socialLink_BlockType',
  /** The id of the entity */
  id?: Maybe<Scalars['ID']>,
  /** The uid of the entity */
  uid?: Maybe<Scalars['String']>,
  /** The element’s title. */
  title?: Maybe<Scalars['String']>,
  /** The element’s slug. */
  slug?: Maybe<Scalars['String']>,
  /** The element’s URI. */
  uri?: Maybe<Scalars['String']>,
  /** Whether the element is enabled or not. */
  enabled?: Maybe<Scalars['Boolean']>,
  /** Whether the element is archived or not. */
  archived?: Maybe<Scalars['Boolean']>,
  /** The ID of the site the element is associated with. */
  siteId?: Maybe<Scalars['Int']>,
  /** The element’s search score, if the `search` parameter was used when querying for the element. */
  searchScore?: Maybe<Scalars['String']>,
  /** Whether the element has been soft-deleted or not. */
  trashed?: Maybe<Scalars['Boolean']>,
  /** The element's status. */
  status?: Maybe<Scalars['String']>,
  /** The date the element was created. */
  dateCreated?: Maybe<Scalars['DateTime']>,
  /** The date the element was last updated. */
  dateUpdated?: Maybe<Scalars['DateTime']>,
  /** The ID of the field that owns the matrix block. */
  fieldId?: Maybe<Scalars['Int']>,
  /** The ID of the element that owns the matrix block. */
  ownerId?: Maybe<Scalars['Int']>,
  /** The ID of the matrix block's type. */
  typeId?: Maybe<Scalars['Int']>,
  /** The handle of the matrix block's type. */
  typeHandle?: Maybe<Scalars['String']>,
  /** The sort order of the matrix block within the owner element field. */
  sortOrder?: Maybe<Scalars['Int']>,
  socialAccount?: Maybe<Scalars['String']>,
  socialUrl?: Maybe<Scalars['String']>,
};

export type TextSections_MatrixField = TextSections_TextSection_BlockType;

export type TextSections_TextSection_BlockType = MatrixBlockInterface & ElementInterface & {
   __typename?: 'textSections_textSection_BlockType',
  /** The id of the entity */
  id?: Maybe<Scalars['ID']>,
  /** The uid of the entity */
  uid?: Maybe<Scalars['String']>,
  /** The element’s title. */
  title?: Maybe<Scalars['String']>,
  /** The element’s slug. */
  slug?: Maybe<Scalars['String']>,
  /** The element’s URI. */
  uri?: Maybe<Scalars['String']>,
  /** Whether the element is enabled or not. */
  enabled?: Maybe<Scalars['Boolean']>,
  /** Whether the element is archived or not. */
  archived?: Maybe<Scalars['Boolean']>,
  /** The ID of the site the element is associated with. */
  siteId?: Maybe<Scalars['Int']>,
  /** The element’s search score, if the `search` parameter was used when querying for the element. */
  searchScore?: Maybe<Scalars['String']>,
  /** Whether the element has been soft-deleted or not. */
  trashed?: Maybe<Scalars['Boolean']>,
  /** The element's status. */
  status?: Maybe<Scalars['String']>,
  /** The date the element was created. */
  dateCreated?: Maybe<Scalars['DateTime']>,
  /** The date the element was last updated. */
  dateUpdated?: Maybe<Scalars['DateTime']>,
  /** The ID of the field that owns the matrix block. */
  fieldId?: Maybe<Scalars['Int']>,
  /** The ID of the element that owns the matrix block. */
  ownerId?: Maybe<Scalars['Int']>,
  /** The ID of the matrix block's type. */
  typeId?: Maybe<Scalars['Int']>,
  /** The handle of the matrix block's type. */
  typeHandle?: Maybe<Scalars['String']>,
  /** The sort order of the matrix block within the owner element field. */
  sortOrder?: Maybe<Scalars['Int']>,
  heading?: Maybe<Scalars['String']>,
  bodyText?: Maybe<Scalars['String']>,
  image?: Maybe<Array<Maybe<AssetInterface>>>,
};


export type TextSections_TextSection_BlockTypeImageArgs = {
  id?: Maybe<Array<Maybe<Scalars['Int']>>>,
  uid?: Maybe<Array<Maybe<Scalars['String']>>>,
  status?: Maybe<Array<Maybe<Scalars['String']>>>,
  archived?: Maybe<Scalars['Boolean']>,
  trashed?: Maybe<Scalars['Boolean']>,
  site?: Maybe<Array<Maybe<Scalars['String']>>>,
  siteId?: Maybe<Scalars['String']>,
  unique?: Maybe<Scalars['Boolean']>,
  enabledForSite?: Maybe<Scalars['Boolean']>,
  title?: Maybe<Array<Maybe<Scalars['String']>>>,
  slug?: Maybe<Array<Maybe<Scalars['String']>>>,
  uri?: Maybe<Array<Maybe<Scalars['String']>>>,
  search?: Maybe<Scalars['String']>,
  relatedTo?: Maybe<Array<Maybe<Scalars['Int']>>>,
  relatedToAll?: Maybe<Array<Maybe<Scalars['Int']>>>,
  ref?: Maybe<Array<Maybe<Scalars['String']>>>,
  fixedOrder?: Maybe<Scalars['Boolean']>,
  inReverse?: Maybe<Scalars['Boolean']>,
  dateCreated?: Maybe<Scalars['String']>,
  dateUpdated?: Maybe<Scalars['String']>,
  offset?: Maybe<Scalars['Int']>,
  limit?: Maybe<Scalars['Int']>,
  orderBy?: Maybe<Scalars['String']>,
  volumeId?: Maybe<Array<Maybe<Scalars['Int']>>>,
  volume?: Maybe<Array<Maybe<Scalars['String']>>>,
  folderId?: Maybe<Array<Maybe<Scalars['Int']>>>,
  filename?: Maybe<Array<Maybe<Scalars['String']>>>,
  kind?: Maybe<Array<Maybe<Scalars['String']>>>,
  height?: Maybe<Scalars['String']>,
  width?: Maybe<Scalars['String']>,
  size?: Maybe<Scalars['String']>,
  dateModified?: Maybe<Scalars['String']>,
  includeSubfolders?: Maybe<Scalars['Boolean']>
};

export type User = UserInterface & ElementInterface & {
   __typename?: 'User',
  /** The id of the entity */
  id?: Maybe<Scalars['ID']>,
  /** The uid of the entity */
  uid?: Maybe<Scalars['String']>,
  /** The element’s title. */
  title?: Maybe<Scalars['String']>,
  /** The element’s slug. */
  slug?: Maybe<Scalars['String']>,
  /** The element’s URI. */
  uri?: Maybe<Scalars['String']>,
  /** Whether the element is enabled or not. */
  enabled?: Maybe<Scalars['Boolean']>,
  /** Whether the element is archived or not. */
  archived?: Maybe<Scalars['Boolean']>,
  /** The ID of the site the element is associated with. */
  siteId?: Maybe<Scalars['Int']>,
  /** The element’s search score, if the `search` parameter was used when querying for the element. */
  searchScore?: Maybe<Scalars['String']>,
  /** Whether the element has been soft-deleted or not. */
  trashed?: Maybe<Scalars['Boolean']>,
  /** The element's status. */
  status?: Maybe<Scalars['String']>,
  /** The date the element was created. */
  dateCreated?: Maybe<Scalars['DateTime']>,
  /** The date the element was last updated. */
  dateUpdated?: Maybe<Scalars['DateTime']>,
  /** The user's photo. */
  photo?: Maybe<AssetInterface>,
  /** The user's first name or username. */
  friendlyName?: Maybe<Scalars['String']>,
  /** The user's full name. */
  fullName?: Maybe<Scalars['String']>,
  /** The user's full name or username. */
  name?: Maybe<Scalars['String']>,
  /** The user’s preferences. */
  preferences?: Maybe<Scalars['String']>,
  /** The user’s preferred language. */
  preferredLanguage?: Maybe<Scalars['String']>,
  /** The username. */
  username?: Maybe<Scalars['String']>,
  /** The user's first name. */
  firstName?: Maybe<Scalars['String']>,
  /** The user's last name. */
  lastName?: Maybe<Scalars['String']>,
  /** The user's email. */
  email?: Maybe<Scalars['String']>,
  jobTitle?: Maybe<Scalars['String']>,
  social?: Maybe<Array<Maybe<Social_MatrixField>>>,
};


export type UserSocialArgs = {
  id?: Maybe<Array<Maybe<Scalars['Int']>>>,
  uid?: Maybe<Array<Maybe<Scalars['String']>>>,
  status?: Maybe<Array<Maybe<Scalars['String']>>>,
  archived?: Maybe<Scalars['Boolean']>,
  trashed?: Maybe<Scalars['Boolean']>,
  site?: Maybe<Array<Maybe<Scalars['String']>>>,
  siteId?: Maybe<Scalars['String']>,
  unique?: Maybe<Scalars['Boolean']>,
  enabledForSite?: Maybe<Scalars['Boolean']>,
  title?: Maybe<Array<Maybe<Scalars['String']>>>,
  slug?: Maybe<Array<Maybe<Scalars['String']>>>,
  uri?: Maybe<Array<Maybe<Scalars['String']>>>,
  search?: Maybe<Scalars['String']>,
  relatedTo?: Maybe<Array<Maybe<Scalars['Int']>>>,
  relatedToAll?: Maybe<Array<Maybe<Scalars['Int']>>>,
  ref?: Maybe<Array<Maybe<Scalars['String']>>>,
  fixedOrder?: Maybe<Scalars['Boolean']>,
  inReverse?: Maybe<Scalars['Boolean']>,
  dateCreated?: Maybe<Scalars['String']>,
  dateUpdated?: Maybe<Scalars['String']>,
  offset?: Maybe<Scalars['Int']>,
  limit?: Maybe<Scalars['Int']>,
  orderBy?: Maybe<Scalars['String']>,
  fieldId?: Maybe<Array<Maybe<Scalars['Int']>>>,
  ownerId?: Maybe<Array<Maybe<Scalars['String']>>>,
  typeId?: Maybe<Array<Maybe<Scalars['Int']>>>,
  type?: Maybe<Array<Maybe<Scalars['String']>>>
};

/** This is the interface implemented by all users. */
export type UserInterface = {
  /** The id of the entity */
  id?: Maybe<Scalars['ID']>,
  /** The uid of the entity */
  uid?: Maybe<Scalars['String']>,
  /** The element’s title. */
  title?: Maybe<Scalars['String']>,
  /** The element’s slug. */
  slug?: Maybe<Scalars['String']>,
  /** The element’s URI. */
  uri?: Maybe<Scalars['String']>,
  /** Whether the element is enabled or not. */
  enabled?: Maybe<Scalars['Boolean']>,
  /** Whether the element is archived or not. */
  archived?: Maybe<Scalars['Boolean']>,
  /** The ID of the site the element is associated with. */
  siteId?: Maybe<Scalars['Int']>,
  /** The element’s search score, if the `search` parameter was used when querying for the element. */
  searchScore?: Maybe<Scalars['String']>,
  /** Whether the element has been soft-deleted or not. */
  trashed?: Maybe<Scalars['Boolean']>,
  /** The element's status. */
  status?: Maybe<Scalars['String']>,
  /** The date the element was created. */
  dateCreated?: Maybe<Scalars['DateTime']>,
  /** The date the element was last updated. */
  dateUpdated?: Maybe<Scalars['DateTime']>,
  /** The user's photo. */
  photo?: Maybe<AssetInterface>,
  /** The user's first name or username. */
  friendlyName?: Maybe<Scalars['String']>,
  /** The user's full name. */
  fullName?: Maybe<Scalars['String']>,
  /** The user's full name or username. */
  name?: Maybe<Scalars['String']>,
  /** The user’s preferences. */
  preferences?: Maybe<Scalars['String']>,
  /** The user’s preferred language. */
  preferredLanguage?: Maybe<Scalars['String']>,
  /** The username. */
  username?: Maybe<Scalars['String']>,
  /** The user's first name. */
  firstName?: Maybe<Scalars['String']>,
  /** The user's last name. */
  lastName?: Maybe<Scalars['String']>,
  /** The user's email. */
  email?: Maybe<Scalars['String']>,
};
export type CtaFragmentFragment = (
  { __typename?: 'cta_GlobalSet' }
  & Pick<Cta_GlobalSet, 'ctaIntroText' | 'ctaButtonText'>
  & { ctaPage: Maybe<Array<Maybe<(
    { __typename?: 'about_about_Entry' }
    & Pick<About_About_Entry, 'uri'>
  ) | (
    { __typename?: 'contact_contact_Entry' }
    & Pick<Contact_Contact_Entry, 'uri'>
  ) | (
    { __typename?: 'error404_error404_Entry' }
    & Pick<Error404_Error404_Entry, 'uri'>
  ) | (
    { __typename?: 'homepage_homepage_Entry' }
    & Pick<Homepage_Homepage_Entry, 'uri'>
  ) | (
    { __typename?: 'projects_project_Entry' }
    & Pick<Projects_Project_Entry, 'uri'>
  ) | (
    { __typename?: 'projectsPage_projectsPage_Entry' }
    & Pick<ProjectsPage_ProjectsPage_Entry, 'uri'>
  ) | (
    { __typename?: 'services_services_Entry' }
    & Pick<Services_Services_Entry, 'uri'>
  ) | (
    { __typename?: 'servicesPage_servicesPage_Entry' }
    & Pick<ServicesPage_ServicesPage_Entry, 'uri'>
  )>>> }
);

export type FullWidthImagesFragmentFragment = (
  { __typename?: 's3_Asset' }
  & { crop_2880: S3_Asset['url'], crop_1920: S3_Asset['url'], crop_1600: S3_Asset['url'], crop_1370: S3_Asset['url'], crop_1025: S3_Asset['url'], crop_770: S3_Asset['url'] }
);

export type HeroCardImagesFragmentFragment = (
  { __typename?: 's3_Asset' }
  & { crop_2880x1152: S3_Asset['url'], crop_1920x768: S3_Asset['url'], crop_1600x640: S3_Asset['url'], crop_1370x548: S3_Asset['url'], crop_1025x410: S3_Asset['url'], crop_770x308: S3_Asset['url'] }
);

export type ImageAttrsFragmentFragment = (
  { __typename?: 's3_Asset' }
  & Pick<S3_Asset, 'width' | 'height' | 'focalPoint' | 'altText'>
);

export type ProjectCardImagesFragmentFragment = (
  { __typename?: 's3_Asset' }
  & { crop_2880x1680: S3_Asset['url'], crop_1920x1152: S3_Asset['url'], crop_1600x960: S3_Asset['url'], crop_1370x822: S3_Asset['url'], crop_1025x615: S3_Asset['url'], crop_770x462: S3_Asset['url'] }
);

export type ProjectFragmentFragment = (
  { __typename: 'projects_project_Entry' }
  & Pick<Projects_Project_Entry, 'id' | 'title' | 'uri' | 'runner' | 'tint'>
  & { mainImage: Maybe<Array<Maybe<{ __typename?: 's3_Asset' }
    & HeroCardImagesFragmentFragment
    & ProjectCardImagesFragmentFragment
    & ImageAttrsFragmentFragment
  >>>, tileImage: Maybe<Array<Maybe<{ __typename?: 's3_Asset' }
    & HeroCardImagesFragmentFragment
    & ProjectCardImagesFragmentFragment
    & ImageAttrsFragmentFragment
  >>>, heroCardImage: Maybe<Array<Maybe<{ __typename?: 's3_Asset' }
    & HeroCardImagesFragmentFragment
    & ProjectCardImagesFragmentFragment
    & ImageAttrsFragmentFragment
  >>> }
);

export type ServiceFragmentFragment = (
  { __typename: 'services_services_Entry' }
  & Pick<Services_Services_Entry, 'id' | 'title' | 'uri' | 'runner' | 'introText' | 'colors' | 'linkTitle' | 'linkDescription'>
  & { color: Services_Services_Entry['colors'] }
  & { mainImage: Maybe<Array<Maybe<{ __typename?: 's3_Asset' }
    & HeroCardImagesFragmentFragment
    & FullWidthImagesFragmentFragment
    & ImageAttrsFragmentFragment
  >>>, tileImage: Maybe<Array<Maybe<{ __typename?: 's3_Asset' }
    & FullWidthImagesFragmentFragment
    & ImageAttrsFragmentFragment
  >>>, linkDestination: Maybe<Array<Maybe<(
    { __typename?: 'about_about_Entry' }
    & Pick<About_About_Entry, 'uri'>
  ) | (
    { __typename?: 'contact_contact_Entry' }
    & Pick<Contact_Contact_Entry, 'uri'>
  ) | (
    { __typename?: 'error404_error404_Entry' }
    & Pick<Error404_Error404_Entry, 'uri'>
  ) | (
    { __typename?: 'homepage_homepage_Entry' }
    & Pick<Homepage_Homepage_Entry, 'uri'>
  ) | (
    { __typename?: 'projects_project_Entry' }
    & Pick<Projects_Project_Entry, 'uri'>
  ) | (
    { __typename?: 'projectsPage_projectsPage_Entry' }
    & Pick<ProjectsPage_ProjectsPage_Entry, 'uri'>
  ) | (
    { __typename?: 'services_services_Entry' }
    & Pick<Services_Services_Entry, 'uri'>
  ) | (
    { __typename?: 'servicesPage_servicesPage_Entry' }
    & Pick<ServicesPage_ServicesPage_Entry, 'uri'>
  )>>> }
);

export type SocialFieldsFragmentFragment = (
  { __typename?: 'servicesPage_servicesPage_Entry' }
  & Pick<ServicesPage_ServicesPage_Entry, 'id' | 'uri' | 'socialTitle' | 'socialDescription'>
  & { socialImage: Maybe<Array<Maybe<(
    { __typename?: 's3_Asset' }
    & Pick<S3_Asset, 'url'>
  )>>> }
);

export type GetAboutQueryVariables = {};


export type GetAboutQuery = (
  { __typename?: 'Query' }
  & { about: Maybe<Array<Maybe<(
    { __typename?: 'about_about_Entry' }
    & Pick<About_About_Entry, 'id' | 'title' | 'uri' | 'textBlock' | 'socialTitle' | 'socialDescription'>
    & { socialImage: Maybe<Array<Maybe<(
      { __typename?: 's3_Asset' }
      & { social: S3_Asset['url'] }
    )>>>, people: Maybe<Array<Maybe<(
      { __typename?: 'User' }
      & Pick<User, 'id' | 'fullName' | 'jobTitle'>
      & { photo: Maybe<(
        { __typename?: 's3_Asset' }
        & Pick<S3_Asset, 'url'>
      )>, social: Maybe<Array<Maybe<(
        { __typename?: 'social_socialLink_BlockType' }
        & Pick<Social_SocialLink_BlockType, 'id' | 'socialAccount' | 'socialUrl'>
      ) | (
        { __typename?: 'social_emailAddress_BlockType' }
        & Pick<Social_EmailAddress_BlockType, 'id' | 'email'>
      )>>> }
    )>>> }
  ) | { __typename?: 'contact_contact_Entry' } | { __typename?: 'error404_error404_Entry' } | { __typename?: 'homepage_homepage_Entry' } | { __typename?: 'projects_project_Entry' } | { __typename?: 'projectsPage_projectsPage_Entry' } | { __typename?: 'services_services_Entry' } | { __typename?: 'servicesPage_servicesPage_Entry' }>>>, cta: Maybe<Array<Maybe<({ __typename?: 'cta_GlobalSet' } | { __typename?: 'fieldwork_GlobalSet' } | { __typename?: 'highlightColors_GlobalSet' })
    & CtaFragmentFragment
  >>> }
);

export type GetAppQueryVariables = {};


export type GetAppQuery = (
  { __typename?: 'Query' }
  & { fieldwork: Maybe<Array<Maybe<{ __typename?: 'cta_GlobalSet' } | (
    { __typename?: 'fieldwork_GlobalSet' }
    & Pick<Fieldwork_GlobalSet, 'address' | 'email' | 'phone'>
    & { social: Maybe<Array<Maybe<(
      { __typename?: 'social_socialLink_BlockType' }
      & Pick<Social_SocialLink_BlockType, 'id' | 'enabled' | 'socialAccount' | 'socialUrl'>
    ) | { __typename?: 'social_emailAddress_BlockType' }>>> }
  ) | { __typename?: 'highlightColors_GlobalSet' }>>>, highlightColors: Maybe<Array<Maybe<{ __typename?: 'cta_GlobalSet' } | { __typename?: 'fieldwork_GlobalSet' } | (
    { __typename?: 'highlightColors_GlobalSet' }
    & Pick<HighlightColors_GlobalSet, 'colors'>
  )>>> }
);

export type GetContactQueryVariables = {};


export type GetContactQuery = (
  { __typename?: 'Query' }
  & { fieldwork: Maybe<Array<Maybe<{ __typename?: 'cta_GlobalSet' } | (
    { __typename?: 'fieldwork_GlobalSet' }
    & Pick<Fieldwork_GlobalSet, 'address'>
  ) | { __typename?: 'highlightColors_GlobalSet' }>>>, contact: Maybe<Array<Maybe<{ __typename?: 'about_about_Entry' } | (
    { __typename?: 'contact_contact_Entry' }
    & Pick<Contact_Contact_Entry, 'id' | 'title' | 'uri' | 'socialTitle' | 'socialDescription' | 'mapLatitude' | 'mapLongitude' | 'mapZoom'>
    & { socialImage: Maybe<Array<Maybe<(
      { __typename?: 's3_Asset' }
      & { social: S3_Asset['url'] }
    )>>>, links: Maybe<Array<Maybe<(
      { __typename?: 'links_link_BlockType' }
      & Pick<Links_Link_BlockType, 'id' | 'linkText' | 'linkUrl'>
    )>>>, contacts: Maybe<Array<Maybe<(
      { __typename?: 'contacts_contact_BlockType' }
      & Pick<Contacts_Contact_BlockType, 'id' | 'contactTitle' | 'description' | 'email'>
    )>>> }
  ) | { __typename?: 'error404_error404_Entry' } | { __typename?: 'homepage_homepage_Entry' } | { __typename?: 'projects_project_Entry' } | { __typename?: 'projectsPage_projectsPage_Entry' } | { __typename?: 'services_services_Entry' } | { __typename?: 'servicesPage_servicesPage_Entry' }>>> }
);

export type GetHomeQueryVariables = {};


export type GetHomeQuery = (
  { __typename?: 'Query' }
  & { home: Maybe<Array<Maybe<{ __typename?: 'about_about_Entry' } | { __typename?: 'contact_contact_Entry' } | { __typename?: 'error404_error404_Entry' } | (
    { __typename: 'homepage_homepage_Entry' }
    & Pick<Homepage_Homepage_Entry, 'title' | 'uri' | 'runner' | 'socialTitle' | 'socialDescription'>
    & { socialImage: Maybe<Array<Maybe<(
      { __typename?: 's3_Asset' }
      & { social: S3_Asset['url'] }
    )>>>, projects: Maybe<Array<Maybe<({ __typename?: 'about_about_Entry' } | { __typename?: 'contact_contact_Entry' } | { __typename?: 'error404_error404_Entry' } | { __typename?: 'homepage_homepage_Entry' } | { __typename?: 'projects_project_Entry' } | { __typename?: 'projectsPage_projectsPage_Entry' } | { __typename?: 'services_services_Entry' } | { __typename?: 'servicesPage_servicesPage_Entry' })
      & ProjectFragmentFragment
    >>> }
  ) | { __typename?: 'projects_project_Entry' } | { __typename?: 'projectsPage_projectsPage_Entry' } | { __typename?: 'services_services_Entry' } | { __typename?: 'servicesPage_servicesPage_Entry' }>>>, cta: Maybe<Array<Maybe<({ __typename?: 'cta_GlobalSet' } | { __typename?: 'fieldwork_GlobalSet' } | { __typename?: 'highlightColors_GlobalSet' })
    & CtaFragmentFragment
  >>> }
);

export type GetNotFoundQueryVariables = {};


export type GetNotFoundQuery = (
  { __typename?: 'Query' }
  & { notFound: Maybe<Array<Maybe<{ __typename?: 'about_about_Entry' } | { __typename?: 'contact_contact_Entry' } | (
    { __typename?: 'error404_error404_Entry' }
    & Pick<Error404_Error404_Entry, 'id' | 'title' | 'textBlock'>
  ) | { __typename?: 'homepage_homepage_Entry' } | { __typename?: 'projects_project_Entry' } | { __typename?: 'projectsPage_projectsPage_Entry' } | { __typename?: 'services_services_Entry' } | { __typename?: 'servicesPage_servicesPage_Entry' }>>> }
);

export type GetProjectQueryVariables = {
  projectSlug: Array<Maybe<Scalars['String']>>
};


export type GetProjectQuery = (
  { __typename?: 'Query' }
  & { project: Maybe<Array<Maybe<({ __typename?: 'about_about_Entry' } | { __typename?: 'contact_contact_Entry' } | { __typename?: 'error404_error404_Entry' } | { __typename?: 'homepage_homepage_Entry' } | (
    { __typename?: 'projects_project_Entry' }
    & Pick<Projects_Project_Entry, 'socialTitle' | 'socialDescription'>
    & { socialImage: Maybe<Array<Maybe<(
      { __typename?: 's3_Asset' }
      & { social: S3_Asset['url'] }
    )>>>, mainImage: Maybe<Array<Maybe<(
      { __typename?: 's3_Asset' }
      & { social: S3_Asset['url'] }
    )
      & ImageAttrsFragmentFragment
      & FullWidthImagesFragmentFragment
    >>>, projectContent: Maybe<Array<Maybe<(
      { __typename: 'projectContent_image_BlockType' }
      & Pick<ProjectContent_Image_BlockType, 'id' | 'portrait' | 'fullWidth'>
      & { image: Maybe<Array<Maybe<{ __typename?: 's3_Asset' }
        & ImageAttrsFragmentFragment
        & FullWidthImagesFragmentFragment
      >>> }
    ) | (
      { __typename: 'projectContent_text_BlockType' }
      & Pick<ProjectContent_Text_BlockType, 'id' | 'text'>
    ) | (
      { __typename: 'projectContent_sectionTitle_BlockType' }
      & Pick<ProjectContent_SectionTitle_BlockType, 'id' | 'sectionTitle'>
    )>>> }
  ) | { __typename?: 'projectsPage_projectsPage_Entry' } | { __typename?: 'services_services_Entry' } | { __typename?: 'servicesPage_servicesPage_Entry' })
    & ProjectFragmentFragment
  >>>, cta: Maybe<Array<Maybe<({ __typename?: 'cta_GlobalSet' } | { __typename?: 'fieldwork_GlobalSet' } | { __typename?: 'highlightColors_GlobalSet' })
    & CtaFragmentFragment
  >>> }
);

export type GetProjectsQueryVariables = {};


export type GetProjectsQuery = (
  { __typename?: 'Query' }
  & { projectsPage: Maybe<Array<Maybe<{ __typename?: 'about_about_Entry' } | { __typename?: 'contact_contact_Entry' } | { __typename?: 'error404_error404_Entry' } | { __typename?: 'homepage_homepage_Entry' } | { __typename?: 'projects_project_Entry' } | (
    { __typename: 'projectsPage_projectsPage_Entry' }
    & Pick<ProjectsPage_ProjectsPage_Entry, 'title' | 'uri' | 'socialTitle' | 'socialDescription'>
    & { socialImage: Maybe<Array<Maybe<(
      { __typename?: 's3_Asset' }
      & { social: S3_Asset['url'] }
    )>>> }
  ) | { __typename?: 'services_services_Entry' } | { __typename?: 'servicesPage_servicesPage_Entry' }>>>, projects: Maybe<Array<Maybe<({ __typename?: 'about_about_Entry' } | { __typename?: 'contact_contact_Entry' } | { __typename?: 'error404_error404_Entry' } | { __typename?: 'homepage_homepage_Entry' } | { __typename?: 'projects_project_Entry' } | { __typename?: 'projectsPage_projectsPage_Entry' } | { __typename?: 'services_services_Entry' } | { __typename?: 'servicesPage_servicesPage_Entry' })
    & ProjectFragmentFragment
  >>>, cta: Maybe<Array<Maybe<({ __typename?: 'cta_GlobalSet' } | { __typename?: 'fieldwork_GlobalSet' } | { __typename?: 'highlightColors_GlobalSet' })
    & CtaFragmentFragment
  >>> }
);

export type GetServiceQueryVariables = {
  serviceSlug: Array<Maybe<Scalars['String']>>
};


export type GetServiceQuery = (
  { __typename?: 'Query' }
  & { service: Maybe<Array<Maybe<({ __typename?: 'about_about_Entry' } | { __typename?: 'contact_contact_Entry' } | { __typename?: 'error404_error404_Entry' } | { __typename?: 'homepage_homepage_Entry' } | { __typename?: 'projects_project_Entry' } | { __typename?: 'projectsPage_projectsPage_Entry' } | (
    { __typename?: 'services_services_Entry' }
    & { textSections: Maybe<Array<Maybe<(
      { __typename?: 'textSections_textSection_BlockType' }
      & Pick<TextSections_TextSection_BlockType, 'id' | 'heading' | 'bodyText'>
    )>>> }
  ) | { __typename?: 'servicesPage_servicesPage_Entry' })
    & ServiceFragmentFragment
  >>>, cta: Maybe<Array<Maybe<({ __typename?: 'cta_GlobalSet' } | { __typename?: 'fieldwork_GlobalSet' } | { __typename?: 'highlightColors_GlobalSet' })
    & CtaFragmentFragment
  >>> }
);

export type GetServicesQueryVariables = {};


export type GetServicesQuery = (
  { __typename?: 'Query' }
  & { servicesPage: Maybe<Array<Maybe<{ __typename?: 'about_about_Entry' } | { __typename?: 'contact_contact_Entry' } | { __typename?: 'error404_error404_Entry' } | { __typename?: 'homepage_homepage_Entry' } | { __typename?: 'projects_project_Entry' } | { __typename?: 'projectsPage_projectsPage_Entry' } | { __typename?: 'services_services_Entry' } | (
    { __typename: 'servicesPage_servicesPage_Entry' }
    & Pick<ServicesPage_ServicesPage_Entry, 'title' | 'uri' | 'runner' | 'socialTitle' | 'socialDescription'>
    & { socialImage: Maybe<Array<Maybe<(
      { __typename?: 's3_Asset' }
      & Pick<S3_Asset, 'url'>
    )>>> }
  )>>>, services: Maybe<Array<Maybe<({ __typename?: 'about_about_Entry' } | { __typename?: 'contact_contact_Entry' } | { __typename?: 'error404_error404_Entry' } | { __typename?: 'homepage_homepage_Entry' } | { __typename?: 'projects_project_Entry' } | { __typename?: 'projectsPage_projectsPage_Entry' } | { __typename?: 'services_services_Entry' } | { __typename?: 'servicesPage_servicesPage_Entry' })
    & ServiceFragmentFragment
  >>>, cta: Maybe<Array<Maybe<({ __typename?: 'cta_GlobalSet' } | { __typename?: 'fieldwork_GlobalSet' } | { __typename?: 'highlightColors_GlobalSet' })
    & CtaFragmentFragment
  >>> }
);
export const CtaFragmentFragmentDoc = gql`
    fragment CtaFragment on cta_GlobalSet {
  ctaIntroText
  ctaButtonText
  ctaPage {
    ... on EntryInterface {
      uri
    }
  }
}
    `;
export const HeroCardImagesFragmentFragmentDoc = gql`
    fragment HeroCardImagesFragment on s3_Asset {
  crop_2880x1152: url @transform(mode: "crop", width: 2880, height: 1152, quality: 65)
  crop_1920x768: url @transform(mode: "crop", width: 1920, height: 768, quality: 65)
  crop_1600x640: url @transform(mode: "crop", width: 1600, height: 640, quality: 65)
  crop_1370x548: url @transform(mode: "crop", width: 1370, height: 548, quality: 65)
  crop_1025x410: url @transform(mode: "crop", width: 1025, height: 410, quality: 65)
  crop_770x308: url @transform(mode: "crop", width: 770, height: 308, quality: 65)
}
    `;
export const ProjectCardImagesFragmentFragmentDoc = gql`
    fragment ProjectCardImagesFragment on s3_Asset {
  crop_2880x1680: url @transform(mode: "crop", width: 2880, height: 1680, quality: 65)
  crop_1920x1152: url @transform(mode: "crop", width: 1920, height: 1152, quality: 65)
  crop_1600x960: url @transform(mode: "crop", width: 1600, height: 960, quality: 65)
  crop_1370x822: url @transform(mode: "crop", width: 1370, height: 822, quality: 65)
  crop_1025x615: url @transform(mode: "crop", width: 1025, height: 615, quality: 65)
  crop_770x462: url @transform(mode: "crop", width: 770, height: 462, quality: 65)
}
    `;
export const ImageAttrsFragmentFragmentDoc = gql`
    fragment ImageAttrsFragment on s3_Asset {
  ... on s3_Asset {
    width
    height
    focalPoint
    altText
  }
}
    `;
export const ProjectFragmentFragmentDoc = gql`
    fragment ProjectFragment on projects_project_Entry {
  __typename
  id
  title
  uri
  runner
  mainImage {
    ...HeroCardImagesFragment
    ...ProjectCardImagesFragment
    ...ImageAttrsFragment
  }
  tileImage {
    ...HeroCardImagesFragment
    ...ProjectCardImagesFragment
    ...ImageAttrsFragment
  }
  heroCardImage {
    ...HeroCardImagesFragment
    ...ProjectCardImagesFragment
    ...ImageAttrsFragment
  }
  tint
}
    ${HeroCardImagesFragmentFragmentDoc}
${ProjectCardImagesFragmentFragmentDoc}
${ImageAttrsFragmentFragmentDoc}`;
export const FullWidthImagesFragmentFragmentDoc = gql`
    fragment FullWidthImagesFragment on s3_Asset {
  crop_2880: url @transform(mode: "crop", width: 2880, quality: 65)
  crop_1920: url @transform(mode: "crop", width: 1920, quality: 65)
  crop_1600: url @transform(mode: "crop", width: 1600, quality: 65)
  crop_1370: url @transform(mode: "crop", width: 1370, quality: 65)
  crop_1025: url @transform(mode: "crop", width: 1025, quality: 65)
  crop_770: url @transform(mode: "crop", width: 770, quality: 65)
}
    `;
export const ServiceFragmentFragmentDoc = gql`
    fragment ServiceFragment on services_services_Entry {
  __typename
  id
  title
  uri
  runner
  introText
  color: colors @firstColor
  mainImage {
    ...HeroCardImagesFragment
    ...FullWidthImagesFragment
    ...ImageAttrsFragment
  }
  tileImage {
    ...FullWidthImagesFragment
    ...ImageAttrsFragment
  }
  colors
  linkTitle
  linkDescription
  linkDestination {
    uri
  }
}
    ${HeroCardImagesFragmentFragmentDoc}
${FullWidthImagesFragmentFragmentDoc}
${ImageAttrsFragmentFragmentDoc}`;
export const SocialFieldsFragmentFragmentDoc = gql`
    fragment SocialFieldsFragment on servicesPage_servicesPage_Entry {
  id
  uri
  socialTitle
  socialDescription
  socialImage {
    url @transform(handle: "social")
  }
}
    `;
export const GetAboutDocument = gql`
    query GetAbout {
  about: entries(section: "about", limit: 1) {
    ... on about_about_Entry {
      id
      title
      uri
      textBlock
      socialTitle
      socialDescription
      socialImage {
        social: url @transform(handle: "social")
      }
      people {
        ... on User {
          id
          fullName
          jobTitle
          photo {
            url
          }
          social {
            ... on social_socialLink_BlockType {
              id
              socialAccount
              socialUrl
            }
            ... on social_emailAddress_BlockType {
              id
              email
            }
          }
        }
      }
    }
  }
  cta: globalSets(handle: "cta", limit: 1) {
    ...CtaFragment
  }
}
    ${CtaFragmentFragmentDoc}`;

    export function useGetAboutQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetAboutQuery, GetAboutQueryVariables>) {
      return ApolloReactHooks.useQuery<GetAboutQuery, GetAboutQueryVariables>(GetAboutDocument, baseOptions);
    }
      export function useGetAboutLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetAboutQuery, GetAboutQueryVariables>) {
        return ApolloReactHooks.useLazyQuery<GetAboutQuery, GetAboutQueryVariables>(GetAboutDocument, baseOptions);
      }
      
export type GetAboutQueryHookResult = ReturnType<typeof useGetAboutQuery>;
export type GetAboutQueryResult = ApolloReactCommon.QueryResult<GetAboutQuery, GetAboutQueryVariables>;
export const GetAppDocument = gql`
    query getApp {
  fieldwork: globalSets(handle: "fieldwork") {
    ... on fieldwork_GlobalSet {
      address
      email
      phone
      social {
        ... on social_socialLink_BlockType {
          id
          enabled
          socialAccount
          socialUrl
        }
      }
    }
  }
  highlightColors: globalSets(handle: "highlightColors") {
    ... on highlightColors_GlobalSet {
      colors @colorsList
    }
  }
}
    `;

    export function useGetAppQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetAppQuery, GetAppQueryVariables>) {
      return ApolloReactHooks.useQuery<GetAppQuery, GetAppQueryVariables>(GetAppDocument, baseOptions);
    }
      export function useGetAppLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetAppQuery, GetAppQueryVariables>) {
        return ApolloReactHooks.useLazyQuery<GetAppQuery, GetAppQueryVariables>(GetAppDocument, baseOptions);
      }
      
export type GetAppQueryHookResult = ReturnType<typeof useGetAppQuery>;
export type GetAppQueryResult = ApolloReactCommon.QueryResult<GetAppQuery, GetAppQueryVariables>;
export const GetContactDocument = gql`
    query GetContact {
  fieldwork: globalSets(handle: "fieldwork", limit: 1) {
    ... on fieldwork_GlobalSet {
      address
    }
  }
  contact: entries(section: "contact", limit: 1) {
    ... on contact_contact_Entry {
      id
      title
      uri
      socialTitle
      socialDescription
      socialImage {
        social: url @transform(handle: "social")
      }
      mapLatitude
      mapLongitude
      mapZoom
      links {
        ... on links_link_BlockType {
          id
          linkText
          linkUrl
        }
      }
      contacts {
        ... on contacts_contact_BlockType {
          id
          contactTitle
          description
          email
        }
      }
    }
  }
}
    `;

    export function useGetContactQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetContactQuery, GetContactQueryVariables>) {
      return ApolloReactHooks.useQuery<GetContactQuery, GetContactQueryVariables>(GetContactDocument, baseOptions);
    }
      export function useGetContactLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetContactQuery, GetContactQueryVariables>) {
        return ApolloReactHooks.useLazyQuery<GetContactQuery, GetContactQueryVariables>(GetContactDocument, baseOptions);
      }
      
export type GetContactQueryHookResult = ReturnType<typeof useGetContactQuery>;
export type GetContactQueryResult = ApolloReactCommon.QueryResult<GetContactQuery, GetContactQueryVariables>;
export const GetHomeDocument = gql`
    query GetHome {
  home: entries(section: "homepage") {
    ... on homepage_homepage_Entry {
      __typename
      title
      uri
      runner
      socialTitle
      socialDescription
      socialImage {
        social: url @transform(handle: "social")
      }
      projects {
        ...ProjectFragment
      }
    }
  }
  cta: globalSets(handle: "cta", limit: 1) {
    ...CtaFragment
  }
}
    ${ProjectFragmentFragmentDoc}
${CtaFragmentFragmentDoc}`;

    export function useGetHomeQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetHomeQuery, GetHomeQueryVariables>) {
      return ApolloReactHooks.useQuery<GetHomeQuery, GetHomeQueryVariables>(GetHomeDocument, baseOptions);
    }
      export function useGetHomeLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetHomeQuery, GetHomeQueryVariables>) {
        return ApolloReactHooks.useLazyQuery<GetHomeQuery, GetHomeQueryVariables>(GetHomeDocument, baseOptions);
      }
      
export type GetHomeQueryHookResult = ReturnType<typeof useGetHomeQuery>;
export type GetHomeQueryResult = ApolloReactCommon.QueryResult<GetHomeQuery, GetHomeQueryVariables>;
export const GetNotFoundDocument = gql`
    query GetNotFound {
  notFound: entries(section: "error404") {
    ... on error404_error404_Entry {
      id
      title
      textBlock
    }
  }
}
    `;

    export function useGetNotFoundQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetNotFoundQuery, GetNotFoundQueryVariables>) {
      return ApolloReactHooks.useQuery<GetNotFoundQuery, GetNotFoundQueryVariables>(GetNotFoundDocument, baseOptions);
    }
      export function useGetNotFoundLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetNotFoundQuery, GetNotFoundQueryVariables>) {
        return ApolloReactHooks.useLazyQuery<GetNotFoundQuery, GetNotFoundQueryVariables>(GetNotFoundDocument, baseOptions);
      }
      
export type GetNotFoundQueryHookResult = ReturnType<typeof useGetNotFoundQuery>;
export type GetNotFoundQueryResult = ApolloReactCommon.QueryResult<GetNotFoundQuery, GetNotFoundQueryVariables>;
export const GetProjectDocument = gql`
    query GetProject($projectSlug: [String]!) {
  project: entries(section: "projects", slug: $projectSlug, limit: 1) {
    ...ProjectFragment
    ... on projects_project_Entry {
      socialTitle
      socialDescription
      socialImage {
        social: url @transform(handle: "social")
      }
      mainImage {
        ...ImageAttrsFragment
        ...FullWidthImagesFragment
        social: url @transform(handle: "social")
      }
      projectContent {
        __typename
        ... on projectContent_text_BlockType {
          id
          text
        }
        ... on projectContent_sectionTitle_BlockType {
          id
          sectionTitle
        }
        ... on projectContent_image_BlockType {
          id
          portrait
          fullWidth
          image {
            ...ImageAttrsFragment
            ...FullWidthImagesFragment
          }
        }
      }
    }
  }
  cta: globalSets(handle: "cta", limit: 1) {
    ...CtaFragment
  }
}
    ${ProjectFragmentFragmentDoc}
${ImageAttrsFragmentFragmentDoc}
${FullWidthImagesFragmentFragmentDoc}
${CtaFragmentFragmentDoc}`;

    export function useGetProjectQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetProjectQuery, GetProjectQueryVariables>) {
      return ApolloReactHooks.useQuery<GetProjectQuery, GetProjectQueryVariables>(GetProjectDocument, baseOptions);
    }
      export function useGetProjectLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetProjectQuery, GetProjectQueryVariables>) {
        return ApolloReactHooks.useLazyQuery<GetProjectQuery, GetProjectQueryVariables>(GetProjectDocument, baseOptions);
      }
      
export type GetProjectQueryHookResult = ReturnType<typeof useGetProjectQuery>;
export type GetProjectQueryResult = ApolloReactCommon.QueryResult<GetProjectQuery, GetProjectQueryVariables>;
export const GetProjectsDocument = gql`
    query GetProjects {
  projectsPage: entries(section: "projectsPage", limit: 1) {
    ... on projectsPage_projectsPage_Entry {
      __typename
      title
      uri
      socialTitle
      socialDescription
      socialImage {
        social: url @transform(handle: "social")
      }
    }
  }
  projects: entries(section: "projects") {
    ...ProjectFragment
  }
  cta: globalSets(handle: "cta", limit: 1) {
    ...CtaFragment
  }
}
    ${ProjectFragmentFragmentDoc}
${CtaFragmentFragmentDoc}`;

    export function useGetProjectsQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetProjectsQuery, GetProjectsQueryVariables>) {
      return ApolloReactHooks.useQuery<GetProjectsQuery, GetProjectsQueryVariables>(GetProjectsDocument, baseOptions);
    }
      export function useGetProjectsLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetProjectsQuery, GetProjectsQueryVariables>) {
        return ApolloReactHooks.useLazyQuery<GetProjectsQuery, GetProjectsQueryVariables>(GetProjectsDocument, baseOptions);
      }
      
export type GetProjectsQueryHookResult = ReturnType<typeof useGetProjectsQuery>;
export type GetProjectsQueryResult = ApolloReactCommon.QueryResult<GetProjectsQuery, GetProjectsQueryVariables>;
export const GetServiceDocument = gql`
    query GetService($serviceSlug: [String]!) {
  service: entries(section: "services", slug: $serviceSlug, limit: 1) {
    ...ServiceFragment
    ... on services_services_Entry {
      textSections {
        ... on textSections_textSection_BlockType {
          id
          heading
          bodyText
        }
      }
    }
  }
  cta: globalSets(handle: "cta", limit: 1) {
    ...CtaFragment
  }
}
    ${ServiceFragmentFragmentDoc}
${CtaFragmentFragmentDoc}`;

    export function useGetServiceQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetServiceQuery, GetServiceQueryVariables>) {
      return ApolloReactHooks.useQuery<GetServiceQuery, GetServiceQueryVariables>(GetServiceDocument, baseOptions);
    }
      export function useGetServiceLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetServiceQuery, GetServiceQueryVariables>) {
        return ApolloReactHooks.useLazyQuery<GetServiceQuery, GetServiceQueryVariables>(GetServiceDocument, baseOptions);
      }
      
export type GetServiceQueryHookResult = ReturnType<typeof useGetServiceQuery>;
export type GetServiceQueryResult = ApolloReactCommon.QueryResult<GetServiceQuery, GetServiceQueryVariables>;
export const GetServicesDocument = gql`
    query GetServices {
  servicesPage: entries(section: "servicesPage", limit: 1) {
    ... on servicesPage_servicesPage_Entry {
      __typename
      title
      uri
      runner
      socialTitle
      socialDescription
      socialImage {
        url @transform(handle: "social")
      }
    }
  }
  services: entries(section: "services") {
    ...ServiceFragment
  }
  cta: globalSets(handle: "cta", limit: 1) {
    ...CtaFragment
  }
}
    ${ServiceFragmentFragmentDoc}
${CtaFragmentFragmentDoc}`;

    export function useGetServicesQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetServicesQuery, GetServicesQueryVariables>) {
      return ApolloReactHooks.useQuery<GetServicesQuery, GetServicesQueryVariables>(GetServicesDocument, baseOptions);
    }
      export function useGetServicesLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetServicesQuery, GetServicesQueryVariables>) {
        return ApolloReactHooks.useLazyQuery<GetServicesQuery, GetServicesQueryVariables>(GetServicesDocument, baseOptions);
      }
      
export type GetServicesQueryHookResult = ReturnType<typeof useGetServicesQuery>;
export type GetServicesQueryResult = ApolloReactCommon.QueryResult<GetServicesQuery, GetServicesQueryVariables>;