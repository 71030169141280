const contactDefaults = {
  fieldwork: [{
    address: ','
  }],
  contact: [{
    title: '',
    uri: '',
    mapLatitude: '',
    mapLongitude: '',
    mapZoom: '',
    links: [{
      id: 0,
      linkText: '',
      linkUrl: '',
    }],
    contacts: [{
      id: 0,
      contactTitle: '',
      description: '',
      email: '',
    }],
  }],
}

export default contactDefaults
