import React from 'react'
import styled from 'styled-components'
import VerticalStack from './VerticalStack'

interface ContactItems {
  contactItems: Array<{
    id: number,
    contactTitle: string,
    description: string,
    email: string,
  }>,
}

const ContactItems = ( { contactItems }: ContactItems ) => (
  <div>
    { contactItems.map( contactItem => (
        <VerticalStack key={ contactItem.id } notLastChild>
            <StyledContactTitle>
              { contactItem.contactTitle }
            </StyledContactTitle>
            <StyledContactDescription>
              { contactItem.description }
            </StyledContactDescription>
            <StyledContactEmail href={ `mailto:${ contactItem.email }` }>
              { contactItem.email }
            </StyledContactEmail>
        </VerticalStack>
      )
    ) }
  </div>
)

const StyledContactTitle = styled.h3`
  font-size: 20px;
  line-height: 26px;
  font-weight: 600;
  color: var( --black );
`

const StyledContactDescription = styled.p`
  font-size: 20px;
  line-height: 26px;
  font-weight: 300;
  color: var( --black );
`

const StyledContactEmail = styled.a`
  font-family: 'Maison Neue';
  font-size: 14px;
  line-height: 30px;
  font-weight: 300;
  color: var( --lightTextGrey );
  text-decoration: none;
  border-bottom: 1px solid;
  border-color: var( --highlightColor );
  transition: color .3s ease-out;

  &:hover {
    color: var( --highlightColor );
  }
`

export default ContactItems