import React from 'react'
import styled, { css } from 'styled-components'
import { nl2br } from 'fieldwork/helpers/react-strings'
import Media from 'react-media'
import appDefaults from 'fieldwork/graphql/queries/appDefaults'
import LayoutSection from './LayoutSection'
import Social from './Social'

const Footer = ( { fieldwork = appDefaults.fieldwork[ 0 ] } ) => {
  return (
    <StyledFooter>
      <LayoutSection>
        <StyledFooterGrid>
          <StyledFooterPanel>
            <StyledFooterPanelHeading>
              Find us
            </StyledFooterPanelHeading>
            <StyledFooterPanelBody>
              { nl2br( fieldwork.address ) }
            </StyledFooterPanelBody>
          </StyledFooterPanel>

          <StyledFooterPanel>
            <StyledFooterPanelHeading>
              Get in touch
            </StyledFooterPanelHeading>
            <StyledFooterPanelBody>
              <a href={ `mailto:${ fieldwork.email }` }>{ fieldwork.email }</a><br />
              { fieldwork.phone ? <a href={ `tel:${ fieldwork.phone }` }>{ fieldwork.phone }</a> : null }
            </StyledFooterPanelBody>
          </StyledFooterPanel>

          <StyledFooterPanel>
            <Media query="(min-width: 600px)">
              { matches => (
                <Social
                  socialLinks={ fieldwork.social }
                  align={ matches ? 'right' : 'left' }
                />
              ) }
            </Media>
          </StyledFooterPanel>
        </StyledFooterGrid>
      </LayoutSection>
    </StyledFooter>
  )
}

const StyledFooter = styled.div`
  padding: 2rem 0;
  background: var( --footerBg );
`

const StyledFooterGrid = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  grid-gap: 18px;

  @media all and ( min-width: 400px ) {
    grid-template-columns: 1fr 1fr;
  }

  @media all and ( min-width: 600px ) {
    grid-template-columns: 1fr 2fr 1fr;
  }
`

interface StyledFooterPanel {
  gridColumn?: number | null,
}

const StyledFooterPanel = styled.div`
  padding-bottom: 2em;

  ${ ( p: StyledFooterPanel ) => p.gridColumn && css`
    grid-column: ${ p.gridColumn };
  ` }
`

const StyledFooterPanelHeading = styled.div`
  padding-bottom: 10px;
  font-size: 18px;
  font-weight: 600;
  color: var( --highlightColor );
`

const StyledFooterPanelBody = styled.div`
  font-family: 'Maison Neue';
  font-size: 14px;
  line-height: 1.42;
  color: var( --lightTextGrey );

  a {
    display: inline-block;
    margin-bottom: 0.3em;
    color: var( --lightTextGrey );
    border-bottom: 1px solid rgba( var( --lightTextGreyRgb ), 0.3);
    text-decoration: none;
    transition: border-color .3s ease-out;

    &:hover {
      border-color: var( --highlightColor );
    }
  }
`

export default Footer