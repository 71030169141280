import React from 'react'
import LayoutSection from './LayoutSection'
import VerticalStack from './VerticalStack'
import Loader from './Loader'

const PageLoader = () => (
  <LayoutSection fixedHeaderPush>
    <VerticalStack hero>
      <Loader />
    </VerticalStack>
  </LayoutSection>
)

export default PageLoader