import React from 'react'
import { Switch, Route } from 'react-router'
import styled from 'styled-components'
import withQuery from 'fieldwork/components/withQuery'
import { useGetAppQuery } from 'fieldwork/graphql/generated/graphql'
import appDefaults from 'fieldwork/graphql/queries/appDefaults'
import GlobalStyles from 'fieldwork/styles/GlobalStyles'
import Head from './Head'
import Header from './Header'
import Footer from './Footer'
import Home from './Home'
import About from './About'
import Services from './Services'
import Service from './Service'
import Projects from './Projects'
import Project from './Project'
import Contact from './Contact'
import NotFound from './NotFound'

const App = ( { data = appDefaults } ) => {
  const fieldwork = data.fieldwork[ 0 ]
  const highlightColorsList = data.highlightColors[ 0 ].colors

  return (
    <StyledApp>
      <Head
        title="Fieldwork — A Design and Technology Studio"
        url="https://madebyfieldwork.com"
      />
      <GlobalStyles highlightColors={ highlightColorsList.split('/') } />
      <Header />
      <StyledContentArea>
        <Switch>
          <Route exact path="/" component={ Home } />
          <Route exact path="/about" component={ About } />
          <Route exact path="/contact" component={ Contact } />
          <Route exact path="/services" component={ Services } />
          <Route exact path="/projects" component={ Projects } />
          <Route path="/projects/:projectSlug" component={ Project } />
          <Route path="/services/:serviceSlug" component={ Service } />
          <Route component={ NotFound } statusCode={ 404 } />
        </Switch>
      </StyledContentArea>
      <StyledFooterArea>
        <Footer fieldwork={ fieldwork } />
      </StyledFooterArea>
    </StyledApp>
  )
}

const StyledApp = styled.div`
  display: flex;
  flex-direction: column;
  min-height: 100vh;
`

const StyledContentArea = styled.div`
  flex: 1 0 auto;
`

const StyledFooterArea = styled.div`
  flex-shrink: 0;
`

export default withQuery( useGetAppQuery )( App )
