import React from 'react'
import styled, { css } from 'styled-components'
import { typo } from 'fieldwork/styles/fonts'
import toWebclientUrl from 'fieldwork/helpers/toWebclientUrl'

interface ProjectTextSection {
  title?: string,
  bodyText?: string,
  html?: string,
  large?: boolean,
  medium?: boolean,
}

const columnBreakpoint = 300;

const ProjectTextSection = ( {
  title,
  bodyText,
  html,
  large = false,
  medium = false,
}: ProjectTextSection ) => {
  let titleComponent = null

  if ( title ) {
    titleComponent = () => large
      ? <StyledH1>{ title }</StyledH1>
      : <StyledH2>{ title }</StyledH2>
  }

  const bodyTextComponent = () => {
    if ( html ) {
      const htmlWithReplacedURLs = html.replace(
        /https?:\/\/(www\.)?[-a-zA-Z0-9@:%._\+~#=]{2,256}\.[a-z]{2,24}\b([-a-zA-Z0-9@:%_\+.~#?&//=]*)/g,
        ( match ) => toWebclientUrl( match )
      )

      return (
        <StyledBodyText
          large={ large }
          medium={ medium }
          dangerouslySetInnerHTML={ { __html: htmlWithReplacedURLs } }
        />
      )
    }
    else if ( bodyText ) {
      return (
        <StyledBodyText
          large={ large }
          medium={ medium }
        >
          { bodyText }
        </StyledBodyText>
      )
    }

    return null
  }

  return (
    <>
      { titleComponent ? titleComponent() : null }
      { bodyTextComponent() }
    </>
  )
}

const HeadingStyles = css`
  font-weight: 600;
  color: var( --headlineGrey );
  margin-bottom: 1em;
`

const StyledH1 = styled.h1`
  ${ typo.large }
  ${ HeadingStyles }
  font-size: 40px;
`

const StyledH2 = styled.h2`
  ${ typo.medium }
  ${ HeadingStyles }
`

interface StyledBodyTextProps {
  large?: boolean,
  medium?: boolean,
}

const StyledBodyText = styled.div`
  font-size: 20px;
  line-height: 26px;
  font-weight: 300;
  color: var( --black );

  p {
    margin-bottom: 1em;

    &:last-child {
      margin-bottom: 0;
    }
  }

  a {
    text-decoration: none;
    color: var( --lightTextGrey );
    border-bottom: 1px solid var( --lightTextGrey );
    transition:
      color .3s ease-out,
      border-color .3s ease-out;

    &:hover {
      color: var( --highlightColor );
      border-color: var( --highlightColor );
    }
  }

  ${ ( p: StyledBodyTextProps ) => p.large && css`
    ${ typo.large }
    font-weight: 300;
  ` }

  ${ ( p: StyledBodyTextProps ) => p.medium && css`
    ${ typo.medium }
    font-weight: 300;
  ` }
`

export default ProjectTextSection