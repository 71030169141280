import React from 'react'
import { Helmet } from 'react-helmet'

interface Head {
  favicon?: {
    href: string,
    type: string,
  },
  title: string,
  description?: string | null,
  img?: {
    social: string,
    altText: string,
  },
  url?: string,
  siteTitle?: string,
  twitterCard?: string,
  twitterSite?: string,
}

const Head = ( {
  favicon = {
    href: '/favicon.ico',
    type: 'image/x-icon',
  },
  title,
  description,
  img,
  url = '',
  siteTitle = 'Fieldwork',
  twitterCard = 'summary_large_image',
  twitterSite = '@madebyfieldwork',
}: Head ) => {
  const titleText = title
    ? title.replace( /<(?:.|\n)*?>/gm, '' )
    : ''

  const descriptionText = description
    ? description.replace( /<(?:.|\n)*?>/gm, '' )
    : null

  interface JsonLD {
    "@context": "http://schema.org",
    "@type": "WebSite",
    "name": string,
    "url": string,
    description?: string,
    image?: string,
  }

  const jsonld: JsonLD = {
    "@context": "http://schema.org",
    "@type": "WebSite",
    "name": titleText,
    "url": url,
  }

  if ( descriptionText ) {
    jsonld.description = descriptionText
  }

  if ( img && img.social ) {
    jsonld.image = img.social
  }

  return (
    <>
      <Helmet
        defaultTitle={ titleText }
      >
        <html lang="en" />
        <meta charSet="utf-8" />
        <meta name="viewport" content="width=device-width, initial-scale=1" />
        <link rel="shortcut icon" type={ favicon.type } href={ favicon.href } />

        <meta name="twitter:title" content={ titleText } />
        <meta property="og:title" content={ titleText } />

        <meta name="twitter:site" content={ twitterSite } />

        <meta property="og:url" content={ url } />
        <meta property="og:site_name" content={ siteTitle } />
        <meta property="og:locale" content="en_GB" />
        <meta property="og:type" content="website" />

        <script type="application/ld+json">{ JSON.stringify( jsonld ) }</script>
      </Helmet>

      { descriptionText
        ? (
          <Helmet>
            <meta name="description" content={ descriptionText } />
            <meta name="twitter:description" content={ descriptionText } />
            <meta property="og:description" content={ descriptionText } />
          </Helmet>
        )
        : null
      }

      { img && img.social
        ? (
          <Helmet>
            <meta name="image" content={ img.social } />
            <meta property="og:image" content={ img.social } />
            <meta name="twitter:card" content={ twitterCard } />
            <meta name="twitter:image:src" content={ img.social } />
          </Helmet>
        )
        : null
      }

      { img && img.altText
        ? (
          <Helmet>
            <meta property="og:image:alt" content={ img.altText } />
            <meta name="twitter:image:alt" content={ img.altText } />
          </Helmet>
        )
        : null
      }
    </>
  )
}

export default Head