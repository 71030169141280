import React from 'react'

export const nl2br = ( str: string ): React.ReactNode => {
  const newlineRegex = /(\r\n|\r|\n)/g

  return str.split( newlineRegex ).map( ( line, i ) => {
    if ( line.match( newlineRegex ) ) {
      return <br key={ i } />
    }

    return line
  } )
}
