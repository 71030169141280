import React from 'react'
import styled from 'styled-components'
import LayoutSection from './LayoutSection'
import Nav from './Nav'
import Logo from './Logo'

const Header = () => {
  return (
    <StyledHeader>
      <LayoutSection>
        <StyledLogoLayout>
          <Logo />
        </StyledLogoLayout>
        <Nav />
      </LayoutSection>
    </StyledHeader>
  )
}

const StyledHeader = styled.div`
  z-index: 30;
  position: fixed;
  left: 0;
  right: 0;
  top: 0;
  padding: 20px 0;
  background: var( --bgGrey );
`

const StyledLogoLayout = styled.div`
  float: right;
  width: 14px;
  height: 40px;
  margin-top: 18px;
`

export default Header
