import React from 'react'
import { Link } from 'react-router-dom'
import styled from 'styled-components'
import Icon from './Icon/Icon'

const Logo = () => {
  return (
    <StyledLogoLink to="/">
      <Icon iconName="fieldworkLogo" />
    </StyledLogoLink>
  )
}

const StyledLogoLink = styled(Link)`
  display: block;
  width: 14px;

  svg rect {
    fill: var( --highlightColor );
    transition: fill 1s linear;
  }
`

export default Logo
