/**
 * Converts a URL from the CMS to the webclient equivalent
 */
const toWebclientUrl = ( url: string, relative = false ) => {
  let cmsBaseUrl = ''
  let webclientBaseUrl = ''

  if (
    typeof window !== 'undefined'
    && window.CMS_HOSTNAME
    && window.WEBCLIENT_HOSTNAME
  ) {
    cmsBaseUrl = `https://${ window.CMS_HOSTNAME }`
    webclientBaseUrl = `https://${ window.WEBCLIENT_HOSTNAME }`
  }
  else if (
    typeof process !== 'undefined'
    && process.env.CMS_HOSTNAME
    && process.env.WEBCLIENT_HOSTNAME
  ) {
    cmsBaseUrl = `https://${ process.env.CMS_HOSTNAME }`
    webclientBaseUrl = `https://${ process.env.WEBCLIENT_HOSTNAME }`
  }

  if ( relative ) {
    webclientBaseUrl = ''
  }

  if (
    url.startsWith( 'https://' )
    || url.startsWith( 'http://' )
  ) {
    return url.replace( cmsBaseUrl, webclientBaseUrl )
  }

  if ( url.startsWith( '/' ) ) {
    return `${ webclientBaseUrl }${ url }`
  }

  return `${ webclientBaseUrl }/${ url }`
}

export default toWebclientUrl