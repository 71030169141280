import React from 'react'
import { Route } from 'react-router'
import withQuery from 'fieldwork/components/withQuery'
import { useGetNotFoundQuery } from 'fieldwork/graphql/generated/graphql'
import notFoundDefaults from 'fieldwork/graphql/queries/notFoundDefaults'
import LayoutSection from './LayoutSection'
import VerticalStack from './VerticalStack'
import Head from './Head'
import LargeCopy from './LargeCopy'

const Status404 = ( { children }: { children: React.ReactNode } ) => {
  return (
    <Route
      render={ ( { staticContext } ) => {
        if ( staticContext ) {
          staticContext.statusCode = 404
        }

        return children;
      }}
    />
  );
}

const NotFound = ( { data = notFoundDefaults } ) => {
  const notFound = data.notFound[ 0 ]

  return (
    <Status404>
      <Head
        title="Page Not Found — Fieldwork: A design and Technology Studio"
      />
      <LayoutSection fixedHeaderPush narrow>
        <VerticalStack hero>
          <LargeCopy html={ notFound.textBlock } />
        </VerticalStack>
      </LayoutSection>
    </Status404>
  )
}

export default withQuery( useGetNotFoundQuery )( NotFound )