export interface CtaDefaults {
  ctaIntroText: string,
  ctaButtonText: string,
  ctaPage: Array<{
    uri: string,
  }>,
}

const ctaDefaults: CtaDefaults = {
  ctaIntroText: '',
  ctaButtonText: '',
  ctaPage: [{
    uri: '',
  }],
}

export default ctaDefaults
