import React from 'react'
import withQuery from 'fieldwork/components/withQuery'
import { useGetHomeQuery } from 'fieldwork/graphql/generated/graphql'
import homeDefaults from 'fieldwork/graphql/queries/homeDefaults'
import { setSocialForHome } from 'fieldwork/helpers/setSocial'
import Head from './Head'
import LayoutSection from './LayoutSection'
import VerticalStack from './VerticalStack'
import LargeCopy from './LargeCopy'
import ProjectHeroCard from './ProjectHeroCard'
import CTABanner from './CTABanner'

export const Home = ( { data = homeDefaults } ) => {
  const home = data.home[ 0 ]
  const cta = data.cta[ 0 ]

  const {
    socialTitle,
    socialDescription,
    socialImage,
    socialUrl
  } = setSocialForHome( home )

  return (
    <>
      <Head
        title={ socialTitle }
        description={ socialDescription }
        img={ socialImage }
        url={ socialUrl }
      />

      <LayoutSection fixedHeaderPush narrow>
        <VerticalStack hero>
          <LargeCopy>{ home.runner }</LargeCopy>
        </VerticalStack>
      </LayoutSection>

      { home.projects.map( ( projectPreview, i ) => (
        <ProjectHeroCard
          key={ projectPreview.id }
          projectPreview={ projectPreview }
        />
      ) ) }

      <CTABanner
        text={ cta.ctaIntroText }
        link={ cta.ctaPage[ 0 ].uri }
        cta={ cta.ctaButtonText }
      />
    </>
  )
}

export default withQuery( useGetHomeQuery )( Home )