import React from 'react'
import { hot } from 'react-hot-loader'
import { withRouter, RouteComponentProps } from 'react-router-dom'
import { ApolloProvider } from '@apollo/react-hooks'
import usePageTracker from 'fieldwork/hooks/usePageTracker'
import ScrollToTop from './ScrollToTop'
import App from './App'

interface AppWithApollo extends RouteComponentProps<any> {
  apolloClient: any,
}

const AppWithApollo = ( { apolloClient }: AppWithApollo ) => {
  usePageTracker()

  return (
    <ApolloProvider client={ apolloClient }>
      <ScrollToTop />
      <App />
    </ApolloProvider>
  )
}

export default hot( module )( withRouter( AppWithApollo ) )
